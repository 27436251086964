import { Functions } from "../Config/Fire";

export async function getAppuntamenti() {
  const appuntamenti = Functions.httpsCallable("admin-getAppuntamenti");
  return await appuntamenti();
}

export async function getAppuntamentiNegozio(id) {
  const appuntamenti = Functions.httpsCallable("admin-getAppuntamentiNegozio");
  return await appuntamenti(id);
}

export async function getAppuntamento(id) {
  const appuntamento = Functions.httpsCallable("admin-getAppuntamento");
  return await appuntamento(id);
}

export async function eliminaCategoriaAppuntamenti(uid_negozio, categoria) {
  const eliminaCategoriaAppuntamenti = Functions.httpsCallable(
    "admin-eliminaCategoriaAppuntamenti"
  );
  return await eliminaCategoriaAppuntamenti({ uid_negozio, categoria });
}

export async function getCategorieAppuntamenti(uid_negozio) {
  const getCategorieAppuntamenti = Functions.httpsCallable(
    "admin-getCategorieAppuntamenti"
  );
  return await getCategorieAppuntamenti(uid_negozio);
}
export async function postAppuntamento(data) {
  const postAppuntamento = Functions.httpsCallable("admin-postAppuntamento");
  return await postAppuntamento(data);
}
export async function updateAppuntamento(data) {
  const updateAppuntamento = Functions.httpsCallable(
    "admin-updateAppuntamento"
  );
  return await updateAppuntamento(data);
}
export async function eliminaAppuntamento(data) {
  const eliminaAppuntamento = Functions.httpsCallable(
    "admin-eliminaAppuntamento"
  );
  return await eliminaAppuntamento(data);
}
