import { Functions } from "../Config/Fire";

export async function getNegozi(){
    const negozi = Functions.httpsCallable('admin-getNegozi');
    return negozi();
}

export async function getNegozio(id){
  const negozio = Functions.httpsCallable('admin-getNegozio');
  return negozio(id);
}

export async function getNegozioFirebaseInfo(telefono_referente){
  const user = Functions.httpsCallable('admin-getNegozioFirebaseInfo');
  return user(telefono_referente);
}

export async function registraNegozio(values){
  const negozio = Functions.httpsCallable('admin-registraNegozio');
  return negozio(values);
}

export async function aggiornaNegozio(id_negozio, values){
  const negozio = Functions.httpsCallable('admin-aggiornaNegozio');
  return negozio({id: id_negozio, ...values});
}

export async function eliminaNegozio(id){
  return 'ok';
}

export async function getNegoziWithNomeCategorie(){
	const negozi = Functions.httpsCallable('admin-getNegoziWithCategorie');
	return negozi();
}
