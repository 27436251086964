import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  Divider,
  notification,
  Drawer,
  Typography,
  Table,
  Space,
  Select,
  InputNumber,
} from "antd";
import Highlighter from "react-highlight-words";
import * as Yup from "yup";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import {
  CloseOutlined,
  SearchOutlined,
  SendOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Settings from "../Config/Settings";
import CustomChip from "./CustomChip";
import { _chipResoStatusColorGetter } from "../Config/utilities";
import TextArea from "antd/lib/input/TextArea";
import { updateReso } from "../Controllers/Ordini";
const { Option } = Select;

const DrawerReso = ({ reso, callBackUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [prodotto, setprodotto] = useState(null);

  const [submitting, setsubmitting] = useState();

  const openModal = async () => {
    setLoading(true);
    try {
      setVisible(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };
  useEffect(() => {
    console.log(prodotto);
  }, [prodotto]);
  useEffect(() => {
    console.log(reso);
    reso.prodotto !== null
      ? setprodotto(JSON.parse(reso.prodotto))
      : setprodotto(null);
    //console.log("RESO", prodotto);
  }, [reso]);
  // //funzioni tabelle
  const _submitReso = async (values, { setSubmitting }) => {
    setsubmitting(true);
    const { richiesta, nomeProdotto, quantita, totale, status, note } = values;
    const prodottoRecord = {
      nome: nomeProdotto,
      tipo: "prodotto",
      totale: totale,
      quantita: quantita,
    };
    try {
      const { data } = await updateReso(
        reso.id,
        richiesta,
        prodottoRecord,
        status,
        note
      );
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: `Il reso è stato modificato correttamente.`,
      });
      callBackUpdate();
      setVisible(false);
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore!",
        description:
          "Non è stato possibile inserire la tua richiesta di reso, contatta l'assistenza",
      });
    } finally {
      setsubmitting(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={openModal}
        loading={loading}
      />

      <Drawer
        destroyOnClose
        placement="right"
        closable={true}
        onClose={closeModal}
        visible={visible}
        width={"100%"}
        closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Typography.Title level={3} style={styles.title}>
                Gestisci il reso
              </Typography.Title>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Formik
                  validationSchema={Yup.object().shape({
                    richiesta: Yup.string().required(
                      "Inserisci la tua richiesta"
                    ),
                    status: Yup.string()
                      .required("Status mancante")
                      .oneOf(
                        ["in sospeso", "accettato", "rifiutato"],
                        "Status diverso da 'in sospeso', 'accettato', 'rifiutato'"
                      ),
                    nomeProdotto: Yup.string().required(
                      "Inserisci un nome prodotto"
                    ),
                    quantita: Yup.number()
                      .min(1, "La quantità deve essere maggiore di 0")
                      .max(999, "La quantità deve essere inferiore di 999")
                      .required("Inserire una quantità"),
                    totale: Yup.number()
                      .min(0, "Il prezzo deve essere maggiore o uguale a 0")
                      .max(99999, "Il prezzo deve essere minore o uguale a 99999")
                      .nullable()
                      .required("Prezzo mancante"),
                  })}
                  initialValues={{
                    richiesta: reso.richiesta,
                    nomeProdotto: prodotto ? prodotto[0].nome : "",
                    totale: prodotto ? prodotto[0].totale : "",
                    quantita: prodotto ? prodotto[0].quantita : "",
                    status: reso.status,
                    note: reso.note ? reso.note : "",
                  }}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={_submitReso}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    isSubmitting,
                    dirty,
                  }) => (
                    <Form>
                      <Row gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={8}>
                          <span style={styles.subTitle}>Nome prodotto</span>
                          <Input
                            value={values.nomeProdotto}
                            onChange={handleChange("nomeProdotto")}
                            disabled
                            placeholder="Inserisci il nome prodotto"
                          />
                          <div className="input-error">
                            {touched.nomeProdotto && errors.nomeProdotto}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <span style={styles.subTitle}>
                            Quantità prodotto
                          </span>
                          <InputNumber
                            style={{
                              marginRight: "5px",
                              width: "100%",
                              marginBottom: "15px",
                              height: "35px"
                            }}
                            min={1}
                            max={100}
                            step={1}
                            disabled
                            decimalSeparator={","}
                            defaultValue={values.quantita}
                            value={values.quantita}
                            onChange={(value) =>
                              setFieldValue("quantita", value)
                            }
                          />
                          <div className="input-error">
                            {touched.quantita && errors.quantita}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <span style={styles.subTitle}>Prezzo totale</span>
                          <InputNumber
                            style={{
                              marginRight: "5px",
                              width: "100%",
                              marginBottom: "15px",
                              height: "35px"
                            }}
                            disabled
                            min={1}
                            max={100}
                            step={0.5}
                            decimalSeparator={","}
                            defaultValue={values.totale}
                            value={values.totale}
                            onChange={(value) =>
                              setFieldValue("totale", value)
                            }
                          />
                          <div className="input-error">
                            {touched.totale && errors.totale}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <span style={styles.subTitle}>Stato</span>
                          <Row align="middle">
                            <span
                              style={{
                                width: "14px",
                                height: "14px",
                                borderRadius: "14px",
                                marginRight: "10px",
                                marginTop: "5px",
                                backgroundColor: _chipResoStatusColorGetter(
                                  values.status
                                ),
                              }}
                            ></span>


                            <Col flex={1}>
                              <Select
                                onChange={(value) => {
                                  setFieldValue("status", value);
                                }}
                                size="default"
                                placeholder="Seleziona uno status"
                                style={{ width: "100%", marginTop: "10px" }}
                                onBlur={handleBlur("status")}
                                value={values.status}
                              >
                                <Option key={"in sospeso"}>
                                  {"in sospeso"}
                                </Option>
                                <Option key={"rifiutato"}>
                                  {"rifiutato"}
                                </Option>
                                <Option key={"accettato"}>
                                  {"accettato"}
                                </Option>
                              </Select>
                              <div className="input-error">
                                {touched.status && errors.status}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <span style={styles.subTitle}>Note</span>
                          <Col span={24}>
                            <TextArea
                              value={values.note}
                              onChange={handleChange("note")}
                              autoSize={true}
                              style={{ width: "100%", resize: "none" }}
                              placeholder="Inserisci le note"
                              autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                            <span style={styles.inputError}>
                              {touched.richiesta && errors.richiesta}
                            </span>
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <span style={styles.subTitle}>
                          Motivazione del reso
                        </span>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <TextArea
                            value={values.richiesta}
                            onChange={handleChange("richiesta")}
                            autoSize={true}
                            disabled
                            style={{ width: "100%", resize: "none" }}
                            placeholder="Inserisci la motivazione del reso"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                          <span style={styles.inputError}>
                            {touched.richiesta && errors.richiesta}
                          </span>
                        </Col>
                      </Row>

                      <Divider />
                      <Row>
                        <Button
                          onClick={handleSubmit}
                          disabled={!(isValid && dirty) || submitting}
                          style={{ width: "100%", marginTop: "15px" }}
                          type="primary"
                          size="large"
                          icon={<EditOutlined />}
                          loading={submitting}
                          block
                        >
                          {submitting ? "Attendi..." : "Aggiorna Reso"}
                        </Button>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer >
    </>
  );
};

const styles = {
  inputError: {
    marginTop: "5px",
    fontSize: "12px",
    color: Settings.colors.red,
  },
  title: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  subTitle: {
    marginTop: "25px",

    color: Settings.colors.darkGrey,
    fontWeight: 600,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

export default DrawerReso;
