import React from "react";

import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import {
  Divider,
  Row,
  Col,
  Button,
  Table,
  Tag,
  Input,
  Space,
  Modal,
  notification,
  Drawer,
  Select,
  List,
  Card,
  Typography,
  Statistic,
  InputNumber,
  Switch,
} from "antd";
import Highlighter from "react-highlight-words";

import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { getOrdini, getOrdine, aggiornaOrdine } from "../Controllers/Ordini";
import Settings from "../Config/Settings";
import Loading from "../Components/Loading";
import Label from "../Components/Label";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import { validationSchemaAggiornaOrdine } from "../Config/Validation";
import { getUtenteFirebase } from "../Controllers/Utenti";
import { getNegozio } from "../Controllers/Negozi";
import { getProdotto } from "../Controllers/Prodotti";

//Costanti
const { confirm } = Modal;

class Ordini extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer_visible: false,
      modal_utente_details_visible: false,
      modal_negozio_details_visible: false,
      modal_prodotto_details_visible: false,
      selected_utente: {},
      selected_negozio: {},
      selected_prodotto: {},

      ordini: [],

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,

      loaded: false
    };
  }

  _getOrdini = async () => {
    this.setState({ loaded: false });
    try {
      const ordini = await getOrdini();
      this.setState({ ordini: ordini.data });
    } catch (error) {
      console.log(error)
      notification.destroy();
      notification.error({
        message: 'Errore nella richiesta',
        description: 'Non è stato possibile completare la richiesta'
      })
    } finally {
      this.setState({ loaded: true });
    }

  };

  _openUtenteDetailsModal = async (uid) => {
    this.setState({ loaded: false });
    try {
      const { data } = await getUtenteFirebase(uid);
      this.setState({ selected_utente: data, modal_utente_details_visible: true })
    } catch (error) {
      console.log(error)
      notification.destroy();
      notification.error({
        message: 'Errore nella richiesta',
        description: 'Non è stato possibile completare la richiesta'
      })
    } finally {
      this.setState({ loaded: true });
    }
  }

  _openNegozioDetailsModal = async (id) => {
    this.setState({ loaded: false });
    try {
      const { data } = await getNegozio(id);
      this.setState({ selected_negozio: data, modal_negozio_details_visible: true })
    } catch (error) {
      console.log(error)
      notification.destroy();
      notification.error({
        message: 'Errore nella richiesta',
        description: 'Non è stato possibile completare la richiesta'
      })
    } finally {
      this.setState({ loaded: true });
    }
  }

  _openProdottoDetailsModal = async (id) => {
    this.setState({ loaded: false });
    try {
      const { data } = await getProdotto(id);
      this.setState({ selected_prodotto: data, modal_prodotto_details_visible: true })
    } catch (error) {
      console.log(error)
      notification.destroy();
      notification.error({
        message: 'Errore nella richiesta',
        description: 'Non è stato possibile completare la richiesta'
      })
    } finally {
      this.setState({ loaded: true });
    }
  }


  getColor = (orderStatus) => {
    if (orderStatus === "In attesa di conferma") {
      return Settings.colors.yellow;
    } else if (orderStatus === "in lavorazione") {
      return Settings.colors.lightBlue;
    } else if (orderStatus === "pronto per il ritiro") {
      return Settings.colors.lightBlue;
    } else if (orderStatus === "in consegna") {
      return Settings.colors.lightBlue;
    } else if (orderStatus === "consegnato") {
      return Settings.colors.blue;
    } else {
      return Settings.colors.statusBlue;
    }
  };

  _submitForm = async (values, { setSubmitting }) => {
    this.setState({ loaded: false });
    let ordini = [...this.state.ordini];
    try {
      const {
        firebase_db_order_id,
        from,
        to,
        tipo,
        status,
        dettagli,
        indirizzo,
        note,
        motivo_annullamento,
        costo_consegna
      } = values;

      const ordineRecord = {
        firebase_db_order_id: firebase_db_order_id,
        from: from,
        to: to,
        tipo: tipo,
        status: status,
        dettagli: JSON.stringify(dettagli),
        indirizzo: indirizzo,
        note: note,
        motivo_annullamento: motivo_annullamento,
        costo_consegna: costo_consegna
      };

      await aggiornaOrdine(this.state.selected_ordine.id, ordineRecord);

      const foundIndex = ordini.findIndex(x => x.id == this.state.selected_ordine.id);
      ordini[foundIndex] = {
        id: this.state.selected_ordine.id,
        ...ordineRecord
      };

      this.setState({
        ordini: ordini
      });

      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: "Ordine aggiornato correttamente",
      });
    } catch (error) {
      console.log(error);
      setSubmitting(false);

      let description = "Errore in fase di aggiornamento, contattare l'assistenza";

      notification.destroy();
      notification.error({
        message: "Errore",
        description: description,
      });
    } finally {
      this.setState({
        loaded: true,
        drawer_visible: false
      });
    }
  };

  componentDidMount() {
    this._getOrdini();
  }

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
            text
          ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      searchText: ""
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion
  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("id", "id"),
        filteredValue: filteredInfo.id || null,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        width: 100,
      },
      {
        title: "ID Firebase",
        dataIndex: "firebase_db_order_id",
        key: "firebase_db_order_id",
        sorter: (a, b) => a.firebase_db_order_id.localeCompare(b.firebase_db_order_id),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("firebase_db_order_id", "firebase_db_order_id"),
        filteredValue: filteredInfo.firebase_db_order_id || null,
        sortOrder: sortedInfo.columnKey === "firebase_db_order_id" && sortedInfo.order,
        width: 300,
      },
      {
        title: "Utente",
        dataIndex: "from",
        key: "from",
        sorter: (a, b) => a.from.localeCompare(b.from),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("from", "from"),
        filteredValue: filteredInfo.from || null,
        sortOrder: sortedInfo.columnKey === "from" && sortedInfo.order,
        width: 300,
      },
      {
        title: "Indirizzo",
        dataIndex: "indirizzo",
        key: "indirizzo",
        // sorter: (a, b) => a.indirizzo.localeCompare(b.indirizzo),
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("indirizzo", "indirizzo"),
        // filteredValue: filteredInfo.indirizzo || null,
        // sortOrder: sortedInfo.columnKey === "indirizzo" && sortedInfo.order,
        width: 300,
      },
      {
        title: "Negozio",
        dataIndex: "to",
        key: "to",
        sorter: (a, b) =>
          a.to.localeCompare(b.to),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps(
          "to",
          "to"
        ),
        filteredValue: filteredInfo.to || null,
        sortOrder:
          sortedInfo.columnKey === "to" && sortedInfo.order,
        width: 200,
      },
      {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
        sorter: (a, b) =>
          a.tipo.localeCompare(b.tipo),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps(
          "tipo",
          "tipo"
        ),
        filteredValue: filteredInfo.tipo || null,
        sortOrder:
          sortedInfo.columnKey === "tipo" && sortedInfo.order,
        width: 200,
        render: (text, record, index) => record.tipo.charAt(0).toUpperCase() + record.tipo.slice(1)
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) =>
          a.status.localeCompare(b.status),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps(
          "status",
          "status"
        ),
        filteredValue: filteredInfo.status || null,
        sortOrder:
          sortedInfo.columnKey === "status" && sortedInfo.order,
        width: 200,
        align: 'center',
        render: (text, record, index) => <Tag color={this.getColor(record.status)}>{record.status.charAt(0).toUpperCase() + record.status.slice(1)}</Tag>,
      },
      {
        title: "Azioni",
        dataIndex: "settings",
        key: "settings",
        align: "center",
        width: 100,
        fixed: "right",
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <EditOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false });
                let { data } = await getOrdine(record.id);
                data.dettagli = JSON.parse(data.dettagli)
                this.setState({
                  selected_ordine: data,
                  selected_index: index,
                  loaded: true,
                  drawer_visible: true
                })
              }}
            />
          </Row>
        ),
      },
    ];
  };

  render() {
    const { loaded, selected_ordine } = this.state;
    const {
      firebase_db_order_id,
      from,
      to,
      tipo,
      status,
      dettagli,
      indirizzo,
      note,
      motivo_annullamento,
      costo_consegna
    } = selected_ordine || {}

    const initialValues = {
      firebase_db_order_id: firebase_db_order_id,
      from: from,
      to: to,
      tipo: tipo,
      status: status,
      dettagli: dettagli,
      indirizzo: indirizzo,
      note: note,
      motivo_annullamento: motivo_annullamento,
      costo_consegna: costo_consegna
    }

    return (
      <>
        <Wrapper>
          <PageHeader
            title="Ordini"
            description="In questa sezione ci sono tutti gli ordini"
          />
          <Divider />
          <Row
            align="middle"
            justify="end"
            gutter={[15, 15]}
            style={{ marginBottom: "15px" }}
          >
            <Col>
              <Button onClick={this._clearFiltri} size="large">
                Reset Filtri
            </Button>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={this.state.ordini}
            columns={this._getColumns()}
            onChange={this._handleChange}
            size="small"
            scroll={{ x: 576, y: 400 }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100", "200"],
            }}
          />
        </Wrapper>

        {/*Drawer creazione/modifica comune*/}
        {selected_ordine
          ? <Drawer
            destroyOnClose
            placement="right"
            closable={true}
            onClose={() => this.setState({
              drawer_visible: false,
              selected_ordine: null,
              selected_index: null
            })}
            visible={this.state.drawer_visible}
            width="100%"
            closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
          >
            <Row justify='start' align='middle' gutter={[15, 15]}>
              <Col xs={24}>
                <PageHeader
                  title="Modifica ordine"
                  description={`Aggiorna i dettagli dell'ordine: ${selected_ordine.firebase_db_order_id}`}
                />
              </Col>
            </Row>
            <Divider />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemaAggiornaOrdine}
              onSubmit={this._submitForm}
            >
              {({ touched, errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => (
                <Form>
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Dettagli Ordine" />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Firebase UID</span>
                      <Input
                        disabled
                        value={values.firebase_db_order_id}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Row justify="space-between" align="middle" gutter={15}>
                        <Col><span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Utente</span></Col>
                        <Col><EyeOutlined onClick={() => this._openUtenteDetailsModal(values.from)} /></Col>
                      </Row>
                      <Input
                        disabled
                        value={values.from}
                      />



                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Row justify="space-between" align="middle" gutter={15}>
                        <Col><span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Negozio</span></Col>
                        <Col><EyeOutlined onClick={() => this._openNegozioDetailsModal(values.to)} /></Col>
                      </Row>
                      <Input
                        disabled
                        value={values.to}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Tipologia Ordine</span>
                      <Select
                        onChange={(value) => { setFieldValue("tipo", value) }}
                        style={{ width: "100%" }}
                        onBlur={handleBlur("tipo")}
                        value={values.tipo}>
                        <Select.Option key="ritiro" value="ritiro">Ritiro</Select.Option>
                        <Select.Option key="consegna" value="consegna">Consegna</Select.Option>
                      </Select>
                      <div className="input-error">{touched.tipo && errors.tipo}</div>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Status</span>
                      <Select
                        onChange={(value) => { setFieldValue("status", value) }}
                        style={{ width: "100%" }}
                        onBlur={handleBlur("status")}
                        value={values.status}>
                        <Select.Option
                          key="In attesa di conferma"
                          value="In attesa di conferma"
                        >
                          In attesa di conferma
                                </Select.Option>
                        <Select.Option
                          key="in lavorazione"
                          value="in lavorazione"
                        >
                          In lavorazione
                                </Select.Option>
                        {values.tipo === "ritiro" ?
                          <Select.Option
                            key="pronto per il ritiro"
                            value="pronto per il ritiro"
                          >
                            Pronto per il ritiro
                                </Select.Option>
                          : null}
                        {values.tipo === "consegna" ?
                          <Select.Option
                            key="in consegna"
                            value="in consegna"
                          >
                            In consegna
                          </Select.Option>
                          : null}
                        <Select.Option
                          key="consegnato"
                          value="consegnato"
                        >
                          Consegnato
                        </Select.Option>
                        <Select.Option key="annullato" value="annullato">
                          Annullato
                        </Select.Option>
                      </Select>
                      <div className="input-error">{touched.status && errors.status}</div>
                    </Col>
                    {values.tipo === "consegna" &&
                      <>
                        <Col xs={24} sm={24} md={8}>
                          <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Indirizzo di consegna</span>
                          <Input
                            onChange={handleChange("indirizzo")}
                            onBlur={handleBlur("indirizzo")}
                            value={values.indirizzo}
                          />
                          <div className="input-error">{touched.indirizzo && errors.indirizzo}</div>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <span style={{ marginRight: 10, color: Settings.colors.darkGrey }}>Costo di consegna</span>
                          <InputNumber
                            style={{ marginRight: '5px' }}
                            min={0}
                            max={100}
                            step={0.01}
                            decimalSeparator={','}
                            defaultValue={0}
                            value={values.costo_consegna}
                            onChange={(value) => setFieldValue("costo_consegna", value)}
                          />
                        €
                        </Col>
                      </>
                    }
                  </Row>
                  <Divider />
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Prodotti" />
                    </Col>
                    <Col span={24}>
                      <List
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2,
                          xl: 4,
                          xxl: 3,
                        }}
                        dataSource={values.dettagli}
                        renderItem={item => (
                          <List.Item>
                            <Card title={
                              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                                <Col>{`ID:  ${item.id}`}</Col>
                                <Col><EyeOutlined onClick={() => this._openProdottoDetailsModal(item.id)} /></Col>
                              </Row>
                            }>
                              <Row>
                                <Col span={12}>
                                  Totale:
                                </Col>
                                <Col span={12}>
                                  <Statistic value={item.totale} suffix={'€'} precision={2}></Statistic>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  Quantità:
                                </Col>
                                <Col span={12}>
                                  <Statistic value={item.quantita}></Statistic>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  Tipo:
                                </Col>
                                <Col span={12}>
                                  {item.tipo.toUpperCase()}
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Informazioni aggiuntive" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Note</span>
                      <Input.TextArea
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        maxLength={1500}
                        onChange={handleChange("note")}
                        onBlur={handleBlur("note")}
                        value={values.note}
                      />
                      <div className="input-error">{touched.note && errors.note}</div>
                    </Col>
                    {values.status === "annullato" &&
                      <Col xs={24} sm={24} md={12}>
                        <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Motivo Annullamento</span>
                        <Input.TextArea
                          autoSize={{ minRows: 7, maxRows: 7 }}
                          maxLength={1500}
                          onChange={handleChange("motivo_annullamento")}
                          onBlur={handleBlur("motivo_annullamento")}
                          value={values.motivo_annullamento}
                        />
                        <div className="input-error">{touched.motivo_annullamento && errors.motivo_annullamento}</div>
                      </Col>}
                  </Row>
                  <Divider />
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Button
                        loading={isSubmitting}
                        size='large'
                        disabled={!(isValid && dirty) || isSubmitting}
                        icon={<EditOutlined />}
                        block={true}
                        size="large"
                        onClick={() => {
                          if (!isValid)
                            notification.error({
                              message: 'Errore',
                              description: 'Ricontrolla i dati inseriti'
                            });
                          handleSubmit();
                        }}
                        type="primary"
                      >
                        {<span>Aggiorna</span>}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {/* MODAL DETTAGLI UTENTE */}
            <Modal
              destroyOnClose
              onCancel={() => this.setState({ modal_utente_details_visible: false })}
              footer={null}
              title={this.state.selected_utente.displayName}
              visible={this.state.modal_utente_details_visible}
            >
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Email:</Col>
                <Col><a href={`mailto:${this.state.selected_utente.email}`}>{this.state.selected_utente.email}</a></Col>
              </Row>
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Numero di telefono:</Col>
                <Col><a href={`tel:${this.state.selected_utente.phoneNumber}`}>{this.state.selected_utente.phoneNumber}</a></Col>
              </Row>
            </Modal>

            {/* MODAL DETTAGLI NEGOZIO */}
            <Modal
              destroyOnClose
              onCancel={() => this.setState({ modal_negozio_details_visible: false })}
              footer={null}
              title={`${this.state.selected_negozio.id} - ${this.state.selected_negozio.nome_negozio}`}
              visible={this.state.modal_negozio_details_visible}
            >
              {this.state.selected_negozio.logo &&
                <>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col><img src={`${this.state.selected_negozio.logo}`} /></Col>
                  </Row>
                  <Divider />
                </>
              }
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Referente:</Col>
                <Col>{`${this.state.selected_negozio.nome_referente} ${this.state.selected_negozio.cognome_referente}`}</Col>
              </Row>
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Numero di telefono:</Col>
                <Col><a href={`tel:${this.state.selected_negozio.telefono_referente}`}>{this.state.selected_negozio.telefono_referente}</a></Col>
              </Row>
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>ID Comune:</Col>
                <Col>{this.state.selected_negozio.id_comune}</Col>
              </Row>
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Indirizzo:</Col>
                <Col><a target="_blank" href={`http://maps.google.com/?q=${this.state.selected_negozio.indirizzo} ${this.state.selected_negozio.civico}, ${this.state.selected_negozio.citta} ${this.state.selected_negozio.cap}`}>{`${this.state.selected_negozio.indirizzo} ${this.state.selected_negozio.civico}, ${this.state.selected_negozio.citta} ${this.state.selected_negozio.cap}`}</a>  </Col>
              </Row>
            </Modal>

            {/* MODAL DETTAGLI PRODOTTO */}
            <Modal
              destroyOnClose
              onCancel={() => this.setState({ modal_prodotto_details_visible: false })}
              footer={null}
              title={`${this.state.selected_prodotto.id} - ${this.state.selected_prodotto.nome}`}
              visible={this.state.modal_prodotto_details_visible}
            >
              {this.state.selected_prodotto.immagine &&
                <>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col><img src={`${this.state.selected_prodotto.immagine}`} /></Col>
                  </Row>
                  <Divider />
                </>
              }
              {this.state.selected_prodotto.categoria &&
                <Row justify="space-between" align="middle" gutter={[15, 15]}>
                  <Col>Categoria:</Col>
                  <Col>{this.state.selected_prodotto.categoria}</Col>
                </Row>
              }
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Prezzo Unitario:</Col>
                <Col>{this.state.selected_prodotto.prezzo} €</Col>
              </Row>
              {this.state.selected_prodotto.prezzo_scontato &&
                <Row justify="space-between" align="middle" gutter={[15, 15]}>
                  <Col>Prezzo Unitario scontato:</Col>
                  <Col>{this.state.selected_prodotto.prezzo_scontato} €</Col>
                </Row>
              }
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>Ordinabile:</Col>
                <Col><Switch checked={this.state.selected_prodotto.ordinabile} disabled /></Col>
              </Row>
            </Modal>
          </Drawer>
          : null}
        <Loading loading={!loaded} />


      </>
    );
  }
}

export default Ordini;
