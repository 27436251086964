// import from redux
import { applyMiddleware, combineReducers, createStore } from 'redux';

// import from redux persist
import { persistStore, persistReducer } from 'redux-persist'

// import storage
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// Logger with default options
import Logger from 'redux-logger'

// import reducers
import AuthReducer from '../Reducers/AuthReducer'

// create root reducer
const rootReducer = combineReducers({
  auth: AuthReducer,
  //other: OtherReducer
})

// set persist config
const persistConfig = {
  key: 'root',
  storage,
}

// create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// create store
const store = createStore(persistedReducer,applyMiddleware(Logger))

// create persistor
const persistor = persistStore(store)

// exporting
export { store, persistor }
