import React, { Component } from "react";
import { Col, Divider, Image, Input, List, Row, Typography } from "antd";
import placeholder from "../Assets/placeholder.jpg";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      filtered_chats: [],
    };
  }

  componentDidMount() {
    this.setState({
      chats: this.props.chats,
      filtered_chats: this.props.chats,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({
        chats: this.props.chats,
        filtered_chats: this.props.chats,
      });
    }
  }

  _TimeDifference = (current, previous) => {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.abs(Math.round(elapsed / 1000)) + " secondi fa";
    } else if (elapsed < msPerHour) {
      let min = Math.round(elapsed / msPerMinute);
      return min + ` minut${min === 1 ? "o" : "i"} fa`;
    } else if (elapsed < msPerDay) {
      let ore = Math.round(elapsed / msPerHour);
      return ore + ` or${ore === 1 ? "a" : "e"} fa`;
    } else if (elapsed < msPerMonth) {
      let giorni = Math.round(elapsed / msPerDay);
      return giorni + ` giorn${giorni === 1 ? "o" : "i"} fa`;
    } else if (elapsed < msPerYear) {
      let mese = Math.round(elapsed / msPerMonth);
      return mese + ` mes${mese === 1 ? "e" : "i"} fa`;
    } else {
      let anno = Math.round(elapsed / msPerYear);
      return anno + ` ann${anno === 1 ? "o" : "i"} fa`;
    }
  };

  _filterChats = (keyword) => {
    const filtered_chats = this.state.chats.filter((chat) =>
      chat.chat_title.toLowerCase().includes(keyword.toLowerCase())
    );
    this.setState({ filtered_chats: filtered_chats });
  };

  render() {
    const { filtered_chats } = this.state;
    return (
      <>
        <Col span={24}>
          <Typography.Title level={3} style={styles.title}>
            Chat
          </Typography.Title>
          <Input
            enterButton={false}
            placeholder="Cerca tra le chat..."
            size="large"
            allowClear
            onChange={(e) => this._filterChats(e.target.value)}
          />
          <Divider />
          <List
            locale={{ emptyText: "Nessuna Chat" }}
            itemLayout="horizontal"
            dataSource={filtered_chats}
            renderItem={({
              chat_uid,
              chat_title,
              last_message,
              last_update,
              to_read,
            }) => (
              <List.Item
                key={chat_uid}
                style={{
                  cursor: "pointer",
                  paddingLeft: "15px",
                  borderRadius: "4px",
                  borderBottom: "none",
                  backgroundColor: Settings.colors.lightCyan,
                }}
                onClick={() => {
                  this.props.callBackGetMessage(chat_uid, chat_title);
                }}
                extra={
                  to_read ? (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        borderRadius: "14px",
                        margin: "0 14px 14px 14px",
                        backgroundColor: Settings.colors.cyan,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        margin: "0 14px 14px 14px",
                        backgroundColor: "transparent",
                      }}
                    />
                  )
                }
              >
                <List.Item.Meta
                  avatar={
                    <img
                      width={66}
                      height={50}
                      style={{ borderRadius: "4px", overflow: "hidden" }}
                      src={placeholder}
                    />
                  }
                  title={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <span
                        style={{
                          color: Settings.colors.darkGrey,
                          fontWeight: to_read ? 700 : 500,
                          fontSize: 17,
                        }}
                      >
                        {chat_title.length < 26
                          ? chat_title
                          : `${chat_title.substr(0, 23)}...`}
                      </span>
                    </Row>
                  }
                  description={
                    <Row
                      justify="space-between"
                      gutter={[15, 15]}
                      style={{ margin: 0 }}
                    >
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: to_read
                              ? Settings.colors.cyan
                              : Settings.colors.grey,
                            fontWeight: to_read ? 700 : 500,
                            fontSize: 14,
                          }}
                        >
                          {last_message.length < 15
                            ? last_message
                            : `${last_message.substr(0, 12)}...`}
                        </span>
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <span
                          style={{
                            color: Settings.colors.grey,
                            fontSize: 14,
                          }}
                        >
                          {last_update
                            ? this._TimeDifference(
                                Math.floor(Date.now()),
                                last_update
                              )
                            : null}
                        </span>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          ></List>
        </Col>
      </>
    );
  }
}

const styles = {
  title: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
};

export default ChatList;
