import { Functions } from "../Config/Fire";

export async function getComunicazioni() {
    const comunicazioni = Functions.httpsCallable("admin-getComunicazioni");
    return await comunicazioni();
}

export async function getComunicazione(id) {
    const comunicazione = Functions.httpsCallable("admin-getComunicazione");
    return await comunicazione(id);
}

export async function postComunicazione(data) {
    const comunicazione = Functions.httpsCallable("admin-postComunicazione");
    return await comunicazione(data);
}

export async function updateComunicazione(data) {
    const comunicazione = Functions.httpsCallable("admin-updateComunicazione");
    return await comunicazione(data);
}

export async function eliminaComunicazione(data) {
    const comunicazione = Functions.httpsCallable("admin-eliminaComunicazione");
    return await comunicazione(data);
}