import { Functions } from '../Config/Fire';

//Ottieni il prodotto dato l'id
export async function getProdotto(id) {
	const prodotto = Functions.httpsCallable('admin-getProdotto');
	return prodotto(id);
}

//Ottieni tutti i prodotti di un negozio
export async function getProdottiWithNegozio() {
	//Chiamo cloud function per fare la get
	const prodotti = Functions.httpsCallable('admin-getProdottiWithNegozio');
	return prodotti();
}

//Aggiorna i dati del referente del negozio
export async function eliminaProdotto(prodotto) {
	//Chiamo cloud function per registrare utenza e negozio
	const prodotto_eliminato = Functions.httpsCallable('admin-eliminaProdotto');
	return prodotto_eliminato(prodotto);
}

export async function getCategorieProdottiNegozio(id_negozio) {
	//Chiamo cloud function per registrare utenza e negozio
	const categorie = Functions.httpsCallable('admin-getNegozioCategorieProdotti');
	return categorie(id_negozio);
}

//Aggiorna i dati del referente del negozio
export async function setCategorieProdottiNegozio(id_negozio, values) {
	//Chiamo cloud function per registrare utenza e negozio
	const categorie = Functions.httpsCallable('admin-setNegozioCategorieProdotti');
	return categorie({ id_negozio: id_negozio, categorie: values });
}

export function setCategoriaProdotti(id, old_categoria, new_categoria) {
	const setCategoria = Functions.httpsCallable("admin-setCategoriaProdotti");
	return setCategoria({ id_negozio: id, old_categoria: old_categoria, new_categoria: new_categoria });
  }
  

//Aggiorna i dati del referente del negozio
export async function creaProdotto(values) {
	//Chiamo cloud function per registrare utenza e negozio
	const prodotto = Functions.httpsCallable('admin-creaProdotto');
	return prodotto(values);
}

//Aggiorna i dati del referente del negozio
export async function aggiornaProdotto(id_prodotto, values) {
	//Chiamo cloud function per registrare utenza e negozio
	const prodotto = Functions.httpsCallable('admin-aggiornaProdotto');
	return prodotto({ id: id_prodotto, ...values });
}