import { Functions } from '../Config/Fire';

//Ottieni il servizio dato l'id
export async function getServizio(id) {
	const servizio = Functions.httpsCallable('admin-getServizio');
	return servizio(id);
}

//Ottieni tutti i servizi di un negozio
export async function getServiziWithNegozio() {
	//Chiamo cloud function per fare la get
	const servizi = Functions.httpsCallable('admin-getServiziWithNegozio');
	return servizi();
}

//Aggiorna i dati del referente del negozio
export async function eliminaServizio(id) {
	//Chiamo cloud function per registrare utenza e negozio
	const servizio_eliminato = Functions.httpsCallable('admin-eliminaServizio');
	return servizio_eliminato(id);
}

export async function getCategorieServiziNegozio(id_negozio) {
	//Chiamo cloud function per registrare utenza e negozio
	const categorie = Functions.httpsCallable('admin-getNegozioCategorieServizi');
	console.log(id_negozio);
	return categorie(id_negozio);
}

//Aggiorna i dati del referente del negozio
export async function setCategorieServiziNegozio(id_negozio, values) {
	//Chiamo cloud function per registrare utenza e negozio
	const categorie = Functions.httpsCallable('admin-setNegozioCategorieServizi');
	return categorie({ id_negozio: id_negozio, categorie: values });
}

export function setCategoriaServizi(id, old_categoria, new_categoria) {
	//Chiamo cloud function per registrare utenza e negozio
	const setCategoria = Functions.httpsCallable("admin-setCategoriaServizi");
	return setCategoria({ id_negozio: id, old_categoria: old_categoria, new_categoria: new_categoria });
  }
  

//Aggiorna i dati del referente del negozio
export async function creaServizio(values) {
	//Chiamo cloud function per registrare utenza e negozio
	const servizio = Functions.httpsCallable('admin-creaServizio');
	return servizio(values);
}

//Aggiorna i dati del referente del negozio
export async function aggiornaServizio(id_servizio, values) {
	//Chiamo cloud function per registrare utenza e negozio
	const servizio = Functions.httpsCallable('admin-aggiornaServizio');
	return servizio({ id: id_servizio, ...values });
}