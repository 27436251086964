import React from "react";

// import react-router-dom components
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import funzioni del controller di autenticazione
import { checkAuthState, getClaims } from "./Controllers/Auth";

// Components
import PrivateRoute from "./Components/PrivateRoute";

// Screens
import Homepage from "./Screens/Homepage";
import Login from "./Screens/Login";
import Account from "./Screens/Account";
import Comuni from "./Screens/Comuni";
import Negozi from "./Screens/Negozi";
import Prodotti from "./Screens/Prodotti";
import Servizi from "./Screens/Servizi";
import Promozioni from "./Screens/Promozioni";
import Chat from "./Screens/Chat";
import PaginaNonTrovata from "./Screens/PaginaNonTrovata";
import Dashboard from "./Screens/Dashboard";

// import redux components
import { connect } from "react-redux";

// import actions
import { successLogin, successLogout } from "./Actions/AuthActions";
import ConfermaMail from "./Screens/ConfermaMail";

// import ant design css
import "./App.less";
import "./App.css";

import Categorie from "./Screens/Categorie";
import Loading from "./Components/Loading";
import Ordini from "./Screens/Ordini";
import Resi from "./Screens/Resi";
import Comunicazioni from "./Screens/Comunicazioni";

// Declare component
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      loading: true,
      not_verified: false,
    };

    if (process.env.REACT_APP_STAGE == "development") {
      var noIndex = document.createElement("meta");
      noIndex.setAttribute("name", "robots");
      noIndex.content = "noindex";
      document.getElementsByTagName("head")[0].appendChild(noIndex);
    }
  }

  _getClaims = async () => {
    try {
      let { claims } = await getClaims();
      console.log("user claims", claims);
      if (claims.admin) {
        const logged_user = {
          is_logged_in: true,
          email: claims.email,
          email_verified: claims.email_verified,
        };

        //Check if user's email is verified
        if (!logged_user.email_verified) {
          console.log("Not verified");
          this.setState({ authed: true, loading: false, not_verified: true });
        } else {
          this.setState({ authed: true, loading: false, not_verified: false });
        }

        //Save user in global state
        this.props.successLogin(logged_user);
      } else {
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    } catch (error) {
      console.log("errore", error);
    }
  };

  componentDidMount() {
    this.authListener = checkAuthState((user) => {
      if (user) {
        this._getClaims();
      } else {
        console.log("Non loggato");
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    });
  }

  componentWillUnmount() {
    this.authListener();
  }

  render() {
    return this.state.loading === true ? (
      <Loading loading={true} />
    ) : (
      <Router>
        <div>
          {process.env.REACT_APP_STAGE == "development" ? (
            <div
              style={{
                width: "150px",
                display: "inline-block",
                transform: "rotate(45deg)",
                background: "yellow",
                top: 30,
                right: -30,
                zIndex: 5000000,
                position: "fixed",
                textAlign: "center",
                fontSize: "10pt",
              }}
            >
              {process.env.REACT_APP_STAGE.toUpperCase().substring(0, 3)}
            </div>
          ) : null}
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/accedi" component={Login} />

            <PrivateRoute
              path="/dashboard"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Dashboard}
            />
            <PrivateRoute
              path="/account"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Account}
            />
            <PrivateRoute
              path="/comuni"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Comuni}
            />
            <PrivateRoute
              path="/negozi"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Negozi}
            />
            <PrivateRoute
              path="/prodotti"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Prodotti}
            />
            <PrivateRoute
              path="/servizi"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Servizi}
            />
            <PrivateRoute
              path="/categorie"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Categorie}
            />
            <PrivateRoute
              path="/promozioni"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Promozioni}
            />
            <PrivateRoute
              path="/ordini"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Ordini}
            />
            <PrivateRoute
              path="/comunicazioni"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Comunicazioni}
            />
            <PrivateRoute
              path="/chat/:chat_id"
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Chat}
            />
            <PrivateRoute
              path="/conferma-mail"
              authed={this.state.authed}
              component={ConfermaMail}
            />
            <PrivateRoute
              path="/resi"
              authed={this.state.authed}
              component={Resi}
            />

            {/* 404 */}
            <Route component={PaginaNonTrovata} />
            <Route path="/404" component={PaginaNonTrovata} />
          </Switch>
        </div>
      </Router>
    );
  }
}

// This function provides a means of sending actions so that data in the Redux store
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
  };
}

export default connect(null, mapDispatchToProps)(App);
