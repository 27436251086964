import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import redux components and store
import { Provider } from 'react-redux';

// import redux persist component
import { PersistGate } from 'redux-persist/integration/react'

// import global store
import { store, persistor } from './Store'
import { browserName, browserVersion } from "react-device-detect";

import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';

if (browserName === "IE" && browserVersion < 10) {
  ReactDOM.render(<h1>Aggiorna il tuo browser!</h1>, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}
        <ConfigProvider locale={itIT}>
          <App />
        </ConfigProvider>
        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
