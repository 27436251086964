import { Functions } from "../Config/Fire";

export async function getOrdini() {
  const ordini = Functions.httpsCallable("admin-getOrdini");
  return await ordini();
}

export async function getOrdine(id) {
  const ordine = Functions.httpsCallable("admin-getOrdine");
  return await ordine(id);
}

export async function aggiornaOrdine(id, values) {
  const ordine = Functions.httpsCallable("admin-aggiornaOrdine");
  return await ordine({ id: id, ...values });
}

export async function eliminaUtente(id) {
  return "ok";
}
export async function getAllResi() {
  const getAllResi = Functions.httpsCallable("admin-getAllResi");
  return await getAllResi();
}
export async function updateReso(id_reso, richiesta, prodotto, status, note) {
  const updateReso = Functions.httpsCallable("admin-updateReso");
  return await updateReso({
    id_reso: id_reso,
    richiesta: richiesta,
    prodotto: prodotto,
    status: status,
    note: note,
  });
}
