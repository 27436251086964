import React from 'react';

// Third-party components
import { Button, notification } from 'antd';

// Custom components
import CustomResult from '../Components/CustomResult'
import Timer from '../Components/Timer'

// Libraries
import { resendEmailVerification, logout } from '../Controllers/Auth';

class ConfermaMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      run_timer_resend_email: true
    };
  }

  render() {
    return (
      <div className='login-background'>
        <div style={{ display: 'flex', justifyContent: 'center', margin: "15px 0" }}>
          <img src={require('../Assets/logo-white.png')} style={{ height: 100, marginTop: 35, marginBottom: 39 }} alt="logo" />
        </div>
        <CustomResult
          icon='info'
          title='Conferma il tuo indirizzo'
          subtitle="Devi confermare il tuo indirizzo E-Mail per accedere a quest'area. Controlla la tua casella di posta."
        />
        <div style={{ textAlign: 'center' }}>
          {
            this.state.run_timer_resend_email === true
              ?
              <p style={{ fontSize: '14px' }}>
                Attendi
							<Timer
                  seconds={60}
                  isRunning={this.state.run_timer_resend_email}
                  onTimerEnds={() => { this.setState({ run_timer_resend_email: false }) }}
                />
							secondi prima di richiedere una nuova email
						</p>
              :
              <>
                <Button
                  type="primary"
                  size='large'
                  onClick={async () => {
                    this.setState({ run_timer_resend_email: true })
                    try {
                      let email = await resendEmailVerification();
                      let user = await logout();
                      // mostro il risultato
                      notification.destroy();
                      notification.success({
                        message: "PERFETTO!",
                        description: "Email inviata al tuo indirizzo di posta. Controlla anche la casella spam e conferma il tuo account cliccando sul link che trovi all'interno della email",
                      });
                      this.setState({ sent: true });
                    } catch (error) {
                      notification.error({
                        message: 'Errore',
                        description: "Si è verificato un errore durante l'invio dell'email, prova di nuovo"
                      });
                    }
                  }}
                >
                  Invia un'altra email di conferma account
						</Button>
                <Button
                  type="link"
                  size='large'
                  block
                  style={{ marginTop: '30px' }}
                  onClick={() => this.props.history.push("/accedi")}
                >
                  Accedi
						</Button>
              </>
          }
        </div>
      </div>
    );
  }
}

export default ConfermaMail;
