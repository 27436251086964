import * as Yup from "yup";

const validationAggiungiComune = {
  userMail: Yup
    .string()
    .email("Inserisci un'email valida")
    .required('Inserisci un indirizzo email'),
  password: Yup
    .string()
    .required('Inserisci la password del comune/distretto/ecc...'),
  conferma_password: Yup
    .string()
    .required('Inserisci la conferma password')
    .oneOf([Yup.ref('password'), null], 'Le password devono corrispondere'),
  slug: Yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Inserisci uno slug valido')
    .required('Inserisci lo slug del comune/distretto/ecc...')
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un nome per il comune/distretto/ecc...')
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  partita_iva: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/^[0-9]{11}$/, "Inserisci una partita IVA valida")
    .required('Inserisci la partita IVA del comune/distretto/ecc...'),
  logo: Yup
    .string()
    .nullable() //Se inserisco il logo e poi lo cancello il cropper lo setta a null
    .required('Seleziona un logo per il comune/distretto/ecc...'),
  citta: Yup
    .string()
    .nullable()
    .required('Seleziona una città'),
  cap: Yup
    .string()
    .nullable()
    .required('Seleziona un CAP'),
  indirizzo: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un indirizzo')
    .max(255, 'Il campo può contenere massimo 255 caratteri'),
  civico: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un numero civico')
    .max(15, 'Il campo può contenere massimo 15 caratteri'),
  telefono: Yup
    .string()
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di telefono valido")
    .required('Inserisci un numero di telefono')
    .max(25, 'Il campo può contenere massimo 25 caratteri'),
  email: Yup
    .string()
    .email("Inserisci un'email valida")
    .max(50, 'Indirizzo email di contatto > 50 caratteri'),
  note: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  abilitato: Yup
    .bool()
}

const validationAggiornaComune = {
  slug: Yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Inserisci uno slug valido')
    .required('Inserisci lo slug del comune/distretto/ecc...')
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un nome per il comune/distretto/ecc...')
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  partita_iva: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/^[0-9]{11}$/, "Inserisci una partita IVA valida")
    .required('Inserisci la partita IVA del comune/distretto/ecc...'),
  logo: Yup
    .string()
    .nullable() //Se inserisco il logo e poi lo cancello il cropper lo setta a null
    .required('Seleziona un logo per il comune/distretto/ecc...'),
  citta: Yup
    .string()
    .nullable()
    .required('Seleziona una città'),
  cap: Yup
    .string()
    .nullable()
    .required('Seleziona un CAP'),
  indirizzo: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un indirizzo')
    .max(255, 'Il campo può contenere massimo 255 caratteri'),
  civico: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci un numero civico')
    .max(15, 'Il campo può contenere massimo 15 caratteri'),
  telefono: Yup
    .string()
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di telefono valido")
    .required('Inserisci un numero di telefono')
    .max(25, 'Il campo può contenere massimo 25 caratteri'),
  email: Yup
    .string()
    .email("Inserisci un'email valida")
    .max(50, 'Indirizzo email di contatto > 50 caratteri'),
  note: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  abilitato: Yup
    .bool()
}

const validationAggiungiNegozio = {
  userMail: Yup
    .string()
    .email("Inserisci un'email valida")
    .required('Inserisci un indirizzo e-mail'),
  password: Yup
    .string()
    .required('Inserisci la password del negozio')
    .test('len', 'La password deve contenere almeno 8 caratteri', (val) => val && val.toString().length >= 8),
  conferma_password: Yup
    .string()
    .required("Inserisci la password")
    .oneOf([Yup.ref('password'), null], 'Le password devono corrispondere'),
  id_comune: Yup
    .string()
    .nullable()
    .required("Seleziona il comune/distretto/ecc... del negozio"),
  nome_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci il nome del referente del negozio")
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  cognome_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci il cognome del referente del negozio")
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  telefono_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di telefono valido")
    .required("Inserisci il telefono del referente del negozio"),
  nome_negozio: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci un nome per il negozio")
    .max(100, 'Il campo può contenere massimo 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  logo: Yup
    .string()
    .nullable(),
  telefono: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  email: Yup
    .string()
    .email("Inserisci un'email valida"),
  sito_web: Yup
    .string()
    .matches(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/, 'Inserisci un link corretto'),
  facebook: Yup
    .string()
    .matches(/^(https:\/\/)(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)$/, 'Inserisci un link corretto'),
  instagram: Yup
    .string()
    .matches(/^(https:\/\/)(?:www\.)?instagram\.com\/.+$/, 'Inserisci un link corretto'),
  whatsapp: Yup.string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  bancomat: Yup
    .bool(),
  carta: Yup
    .bool(),
  contante: Yup
    .bool(),
  altri_metodi: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(100, 'Il campo può contenere massimo 100 caratteri'),
  /*
  modulo_ordini: Yup
    .bool(),
  modalita_consegna: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(100, 'Il campo può contenere massimo 100 caratteri'),
  */
  pick_pay: Yup
    .bool(),
  domicilio: Yup
    .bool(),
  appuntamento: Yup
    .bool(),
  /*
   modulo_prenotazioni: Yup
     .bool()
  */
  categoria: Yup
    .string()
    .nullable()
    .required('Seleziona una categoria'),
  partita_iva: Yup
    .string()
    .matches(/(^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$)|(^[0-9]{11}$)/i, "Inserisci una partita IVA valida (senza prefisso IT) o un Codice Fiscale valido")
    .required('Inserisci la partita IVA o il Codice Fiscale'),
  citta: Yup
    .string()
    .nullable()
    .required('Seleziona la città in cui è ubicato il negozio'),
  cap: Yup
    .string()
    .nullable()
    .required('Seleziona il CAP in cui è ubicato il negozio'),
  indirizzo: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci l\'indirizzo in cui è ubicato il negozio')
    .max(255, 'Il campo può contenere massimo 255 caratteri'),
  civico: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci il numero civico in cui è ubicato il negozio')
    .max(15, 'Il campo può contenere massimo 15 caratteri'),
  orari_apertura: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  abilitato: Yup
    .bool(),
}

const validationAggiornaNegozio = {
  id_comune: Yup
    .string()
    .nullable()
    .required("Seleziona il comune/distretto/ecc... del negozio"),
  nome_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci il nome del referente del negozio")
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  cognome_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci il cognome del referente del negozio")
    .max(50, 'Il campo può contenere massimo 50 caratteri'),
  telefono_referente: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido")
    .required("Inserisci il telefono del referente del negozio"),
  nome_negozio: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required("Inserisci un nome per il negozio")
    .max(100, 'Il campo può contenere massimo 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  logo: Yup
    .string()
    .nullable(),
  telefono: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  email: Yup
    .string()
    .email("Inserisci un'email valida"),
  sito_web: Yup
    .string()
    .matches(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/, 'Inserisci un link corretto'),
  facebook: Yup
    .string()
    .matches(/^(https:\/\/)(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)$/, 'Inserisci un link corretto'),
  instagram: Yup
    .string()
    .matches(/^(https:\/\/)(?:www\.)?instagram\.com\/.+$/, 'Inserisci un link corretto'),
  whatsapp: Yup.string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  bancomat: Yup
    .bool(),
  carta: Yup
    .bool(),
  contante: Yup
    .bool(),
  altri_metodi: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(100, 'Il campo può contenere massimo 100 caratteri'),
  modulo_ordini: Yup
    .bool(),
  modulo_prenotazioni: Yup
    .bool(),
  modulo_appuntamenti: Yup
    .bool(),
  categoria: Yup
    .string()
    .nullable()
    .required('Seleziona una categoria'),
  partita_iva: Yup
    .string()
    .matches(/(^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$)|(^[0-9]{11}$)/i, "Inserisci una partita IVA valida (senza prefisso IT) o un Codice Fiscale valido")
    .required('Inserisci la partita IVA o il Codice Fiscale'),
  citta: Yup
    .string()
    .nullable()
    .required('Seleziona la città in cui è ubicato il negozio'),
  cap: Yup
    .string()
    .nullable()
    .required('Seleziona il CAP in cui è ubicato il negozio'),
  indirizzo: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci l\'indirizzo in cui è ubicato il negozio')
    .max(255, 'Il campo può contenere massimo 255 caratteri'),
  civico: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .required('Inserisci il numero civico in cui è ubicato il negozio')
    .max(15, 'Il campo può contenere massimo 15 caratteri'),
  orari_apertura: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
  abilitato: Yup
    .bool(),
  chat: Yup
    .bool(),
  dettagli_consegna: Yup
    .string()
    .strict(true)
    .trim('Il campo non può avere spazi vuoti iniziali e/o finali')
    .max(1500, 'Il campo può contenere massimo 1500 caratteri'),
}


//TODO Sistemare Validazione Prodotto e Servizio
const validationAggiungiProdotto = {
  id_negozio: Yup
    .number()
    .nullable()
    .required('Id negozio mancante'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(100, 'Nome > 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Descrizione con spazi vuoti iniziali/finali')
    .max(1500, 'Descrizione > 1500 caratteri'),
  categoria: Yup
    .string()
    .nullable()
    .strict(true)
    .trim('Categoria con spazi vuoti iniziali/finali')
    .max(50, 'Categoria > 50 caratteri'),
  immagine: Yup
    .string()
    .nullable(),
  prezzo: Yup
    .number()
    .min(0, 'Il prezzo deve essere maggiore o uguale a 0')
    .max(99999, 'Il prezzo deve essere minore o uguale a 99999')
    .nullable()
    .required('Prezzo mancante'),
  prezzo_scontato: Yup
    .number()
    .nullable()
    .min(0, 'Il Prezzo scontato deve essere maggiore o uguale a 0')
    .lessThan(Yup.ref('prezzo'), 'Il Prezzo scontato deve essere minore del Prezzo')
}

const validationAggiornaProdotto = {
  id_negozio: Yup
    .number()
    .required('Id negozio mancante'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(100, 'Nome > 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Descrizione con spazi vuoti iniziali/finali')
    .max(1500, 'Descrizione > 1500 caratteri'),
  categoria: Yup
    .string()
    .nullable()
    .strict(true)
    .trim('Categoria con spazi vuoti iniziali/finali')
    .max(50, 'Categoria > 50 caratteri'),
  immagine: Yup
    .string()
    .nullable(),
  prezzo: Yup
    .number()
    .min(0, 'Il prezzo deve essere maggiore o uguale a 0')
    .max(99999, 'Il prezzo deve essere minore o uguale a 99999')
    .nullable()
    .required('Prezzo mancante'),
  prezzo_scontato: Yup
    .number()
    .nullable()
    .min(0, 'Il Prezzo scontato deve essere maggiore o uguale a 0')
    .lessThan(Yup.ref('prezzo'), 'Il Prezzo scontato deve essere minore del Prezzo')
}

const validationAggiungiServizio = {
  id_negozio: Yup
    .number()
    .nullable()
    .required('Id negozio mancante'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(100, 'Nome > 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Descrizione con spazi vuoti iniziali/finali')
    .max(1500, 'Descrizione > 1500 caratteri'),
  categoria: Yup
    .string()
    .nullable()
    .strict(true)
    .trim('Categoria con spazi vuoti iniziali/finali')
    .max(50, 'Categoria > 50 caratteri'),
  prezzo: Yup
    .number()
    .min(0, 'Il prezzo deve essere maggiore o uguale a 0')
    .max(99999, 'Il prezzo deve essere minore o uguale a 99999')
    .nullable()
    .required('Prezzo mancante'),
  prezzo_scontato: Yup
    .number()
    .nullable()
    .min(0, 'Il Prezzo scontato deve essere maggiore o uguale a 0')
    .lessThan(Yup.ref('prezzo'), 'Il Prezzo scontato deve essere minore del Prezzo')
};

const validationAggiornaServizio = {
  id_negozio: Yup
    .number()
    .required('Id negozio mancante'),
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(100, 'Nome > 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Descrizione con spazi vuoti iniziali/finali')
    .max(1500, 'Descrizione > 1500 caratteri'),
  categoria: Yup
    .string()
    .nullable()
    .strict(true)
    .trim('Categoria con spazi vuoti iniziali/finali')
    .max(50, 'Categoria > 50 caratteri'),
  prezzo: Yup
    .number()
    .min(0, 'Il prezzo deve essere maggiore o uguale a 0')
    .max(99999, 'Il prezzo deve essere minore o uguale a 99999')
    .nullable()
    .required('Prezzo mancante'),
  prezzo_scontato: Yup
    .number()
    .nullable()
    .min(0, 'Il Prezzo scontato deve essere maggiore o uguale a 0')
    .lessThan(Yup.ref('prezzo'), 'Il Prezzo scontato deve essere minore del Prezzo')
}

const validationAggiungiCategoria = {
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(45, 'Nome > 45 caratteri'),
  immagine: Yup
    .string()
    .nullable()
    .required('Immagine mancante'),
};

const validationAggiornaCategoria = {
  nome: Yup
    .string()
    .strict(true)
    .trim('Nome con spazi vuoti iniziali/finali')
    .required('Nome mancante')
    .max(45, 'Nome > 45 caratteri'),
  immagine: Yup
    .string()
    .nullable()
    .required('Immagine mancante'),
};


const validationPromozione = {
  id_negozio: Yup
    .number()
    .nullable()
    .required('Id negozio mancante'),
  titolo: Yup
    .string()
    .strict(true)
    .trim('Titolo con spazi vuoti iniziali/finali')
    .required('Titolo mancante')
    .max(100, 'Titolo > 100 caratteri'),
  descrizione: Yup
    .string()
    .strict(true)
    .trim('Descrizione con spazi vuoti iniziali/finali')
    .max(1500, 'Descrizione > 1500 caratteri'),
  immagine: Yup
    .string()
    .nullable()
    .required('Immagine mancante'),
  data_scadenza: Yup
    .string()
    .required('Data scadenza mancante')
};

const validationOrdine = {
  tipo: Yup
    .string()
    .matches(/^(ritiro|consegna)$/)
    .required('Tipologia ordine mancante'),
  status: Yup
    .string()
    .required('Tipologia ordine mancante'),
  indirizzo: Yup
    .string()
    .nullable()
    .when("tipo", {
      is: "consegna",
      then: Yup.string().required("Indirizzo di consegna mancante")
    }),
  note: Yup
    .string()
    .nullable()
    .max(1500, 'Note > 1500 caratteri'),
  motivo_annullamento: Yup
    .string()
    .nullable()
    .max(1500, 'Motivo annullamento > 1500 caratteri')
    .when("status", {
      is: "annullato",
      then: Yup.string().required("Motivo di annullamento mancante")
    }),
  costo_consegna: Yup
    .number()
    .min(0, 'Costo consegna > 0')
    .max(100, 'Costo consegna < 100')
};

export const validationSchemaAggiungiComune = Yup.object(validationAggiungiComune);

export const validationSchemaAggiornaComune = Yup.object(validationAggiornaComune);

export const validationSchemaAggiungiNegozio = Yup.object(validationAggiungiNegozio);

export const validationSchemaAggiornaNegozio = Yup.object(validationAggiornaNegozio);

export const validationSchemaPromozione = Yup.object(validationPromozione);

export const validationSchemaAggiungiProdotto = Yup.object(validationAggiungiProdotto);

export const validationSchemaAggiornaProdotto = Yup.object(validationAggiornaProdotto);

export const validationSchemaAggiungiServizio = Yup.object(validationAggiungiServizio);

export const validationSchemaAggiornaServizio = Yup.object(validationAggiornaServizio);

export const validationSchemaAggiungiCategoria = Yup.object(validationAggiungiCategoria);

export const validationSchemaAggiornaCategoria = Yup.object(validationAggiornaCategoria);

export const validationSchemaAggiornaOrdine = Yup.object(validationOrdine);