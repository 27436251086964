import React from "react";

//import antd
import {
  Row,
  Col,
  Button,
  Input,
  Drawer,
  Select,
  Divider,
  Modal,
  Table,
  Space,
  notification,
  Typography,
  message,
  Tag,
  Checkbox,
  Popover,
  List,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  MessageOutlined,
  CalendarOutlined
} from "@ant-design/icons";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//import components and styles
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import Label from "../Components/Label";
import moment from "moment";
import "moment/locale/it";
//import settings from config
import Settings from "../Config/Settings";
import {
  validationSchemaAggiungiNegozio,
  validationSchemaAggiornaNegozio,
} from "../Config/Validation";

// import Registrazione controller functions
import {
  getNegozi,
  registraNegozio,
  aggiornaNegozio,
  eliminaNegozio,
  getNegozio,
  getNegozioFirebaseInfo,
} from "../Controllers/Negozi";
import {
  getUtenteFirebase,
  getUserChats,
  getMessaggi,
} from "../Controllers/Utenti";
import { getComuni } from "../Controllers/Comuni";
import { getCategorie } from "../Controllers/Categorie";
import ImageCropper from "../Components/ImageCropper";
import Loading from "../Components/Loading";
import {
  inviaConfermaMailNegozio,
  resetPasswordNegozio,
  updateEmailNegozio,
} from "../Controllers/Auth";
import ChatList from "../Components/ChatList";
import LoadingBlock from "../Components/LoadingBlock";
import placeholder from "../Assets/placeholder.jpg";
import { getAppuntamentiNegozio } from "../Controllers/Appuntamenti";
import DrawerAppuntamenti from "../Components/DrawerAppuntamenti";

//Costanti
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const comuni_json = require("../Assets/comuni.json");

class Negozi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,
      drawerChatVisible: false,
      currentUtente: null,
      currentUtenteFirebase: null,
      userChats: [],
      userMessages: [],
      drawer_visible: false,
      cambia_mail_visible: false,
      new_mail: "",
      drawerAppuntamentiVisible: false,

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      selected_negozio: null,
      selected_index: null,

      selected_citta_caps: [],

      comuni: [],
      negozi: [],
      categorie: [],
      appuntamenti_negozio: [],

      img_to_delete: null,
    };
  }
  _getMessages = async (chat_uid, chat_title) => {
    this.setState({ loadingMessages: true, currentChatTitle: chat_title });
    const res = await getMessaggi(chat_uid);
    console.log("MESSAGGI", res);
    this.setState({ userMessages: res.data, loadingMessages: false });
  };
  _setChats = (chats) => {
    let chatsArray = [];
    for (const key in chats) {
      if (Object.hasOwnProperty.call(chats, key)) {
        chatsArray.push({ chat_uid: key, ...chats[key] });
      }
    }
    chatsArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return chatsArray;
  };
  //#region Helpers Gestione Negozi
  _eliminaNegozio = (negozio, indice) => {
    confirm({
      title: "Elimina negozio",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare il negozio: ${negozio.nome}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo negozio dal db
          await eliminaNegozio(negozio.id);

          //Rimuovo negozio nello stato
          let negozi = [...this.state.negozi];
          negozi.splice(indice, 1);
          this.setState({ negozi: negozi });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato il negozio ${negozio.nome} con successo`,
          });
        } catch (error) {
          console.log("errore");
          notification.error({
            title: "Errore",
            description:
              "Si è verificato un errore durante l'eliminazione del negozio",
          });
        }
      },
    });
  };

  _closeAppuntamentiDrawer = () => {
    this.setState({ drawerAppuntamentiVisible: false, selected_telefono_referente: null })
  }

  _submitForm = async (values, { setSubmitting }) => {
    const { selected_negozio } = this.state;
    let negozi = [...this.state.negozi];

    let message, description;

    const {
      userMail,
      password,
      id_comune,
      nome_referente,
      cognome_referente,
      telefono_referente,
      nome_negozio,
      partita_iva,
      categoria,
      indirizzo,
      civico,
      provincia,
      cap,
      citta,
      logo,
      descrizione,
      orari_apertura,
      bancomat,
      carta,
      contante,
      altri_metodi,
      telefono,
      whatsapp,
      facebook,
      instagram,
      sito_web,
      email,
      //modalita_consegna,
      privacy_condizioni,
      abilitato,
      chat,
      modulo_ordini,
      ritiro,
      consegna,
      dettagli_consegna,
      modulo_prenotazioni,
      modulo_appuntamenti
    } = values;
    const { img_to_delete } = this.state;

    let negozioRecord = {
      id_comune: id_comune,
      nome_referente: nome_referente,
      cognome_referente: cognome_referente,
      telefono_referente:
        telefono_referente.substring(0, 1) != "+"
          ? "+" + telefono_referente
          : telefono_referente,
      nome_negozio: nome_negozio,
      partita_iva: partita_iva,
      categoria: categoria,
      indirizzo: indirizzo,
      civico: civico,
      provincia: provincia,
      cap: cap,
      citta: citta,
      logo: logo ?? null,
      descrizione: descrizione ?? "",
      orari_apertura: orari_apertura ?? "",
      bancomat: bancomat,
      carta: carta,
      contante: contante,
      altri_metodi: altri_metodi ?? "",
      telefono: telefono
        ? telefono.substring(0, 1) != "+"
          ? "+" + telefono
          : telefono
        : "+39",
      whatsapp: whatsapp
        ? whatsapp.substring(0, 1) != "+"
          ? "+" + whatsapp
          : whatsapp
        : "+39",
      facebook: facebook ?? "",
      instagram: instagram ?? "",
      sito_web: sito_web ?? "",
      email: email ?? "",
      // modalita_consegna: modalita_consegna ?? '',
      privacy_condizioni: privacy_condizioni,
      abilitato: abilitato,
      chat: chat,
      modulo_ordini: modulo_ordini,
      ritiro: ritiro,
      consegna: consegna,
      dettagli_consegna: dettagli_consegna,
      modulo_appuntamenti: modulo_appuntamenti,
      modulo_prenotazioni: modulo_prenotazioni
    };
    try {
      this.setState({ loaded: false });
      let negozio;
      const comune = this._getComuneById(id_comune);
      if (selected_negozio) {
        const { id } = selected_negozio;
        negozio = await aggiornaNegozio(id, {
          ...negozioRecord,
          img_to_delete: img_to_delete,
        });

        message = "Negozio aggiornato!";
        description = `Il negozio ${nome_negozio} è stato correttamente aggiornato`;

        const foundIndex = negozi.findIndex((x) => x.id == id);
        negozi[foundIndex] = {
          id: id,
          ...negozioRecord,
          logo: negozio.data.img_url,
          nome_comune: comune.nome,
        };

        this.setState({
          negozi: negozi,
        });
      } else {
        negozio = await registraNegozio({
          userMail: userMail,
          password: password,
          ...negozioRecord,
        });

        message = "Negozio creato";
        description = `Il negozio ${nome_negozio} è stato correttamente creato`;

        negozi.push({
          id: negozio.data.id,
          ...negozioRecord,
          logo: negozio.data.img_url,
          nome_comune: comune.nome,
        });
        this.setState({
          negozi: negozi,
        });
      }

      this.setState({
        drawer_visible: false,
        selected_index: null,
        selected_negozio: null,
      });

      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      if (e.code.includes("aborted")) {
        notification.warn({
          message: "Si è verificato un errore interno",
          description: e.details.dettaglio,
        });
        this.setState({ drawer_visible: false });
      }
      if (e.message.includes("phone")) {
        notification.error({
          message:
            "Si è verificato un errore sul numero di telefono specificato",
          description: e.message.includes("exists")
            ? "Il telefono inserito è già presente"
            : "Il telefono inserito non è valido",
        });
      }
      if (e.message.includes("mail")) {
        notification.error({
          message: "Si è verificato un errore sull'indirizzo mail specificato",
          description: e.message.includes("in use")
            ? "La mail inserita è già presente"
            : "La mail inserita non è valida",
        });
      }
      if (e.message.includes("Duplicate entry")) {
        notification.error({
          message: "Si è verificato un errore sullo slug specificato",
          description: "Lo slug inserito è già presente, usane un altro",
        });
      }
    } finally {
      this.setState({ loaded: true });
    }
  };
  //#endregion

  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("id", "id"),
        filteredValue: filteredInfo.id || null,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        width: 100,
      },
      {
        title: "Nome",
        dataIndex: "nome_negozio",
        key: "nome_negozio",
        sorter: (a, b) => a.nome_negozio.localeCompare(b.nome_negozio),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nome negozio", "nome_negozio"),
        filteredValue: filteredInfo.nome_negozio || null,
        sortOrder: sortedInfo.columnKey === "nome_negozio" && sortedInfo.order,
        width: 200,
      },
      {
        title: "Comune",
        children: [
          {
            title: "ID",
            dataIndex: "id_comune",
            key: "id_comune",
            sorter: (a, b) => a.id_comune - b.id_comune,
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("id comune negozio", "id_comune"),
            filteredValue: filteredInfo.id_comune || null,
            sortOrder: sortedInfo.columnKey === "id_comune" && sortedInfo.order,
            width: 70,
            align: "center",
          },
          {
            title: "Nome",
            dataIndex: "nome_comune",
            key: "nome_comune",
            sorter: (a, b) => a.nome_comune.localeCompare(b.nome_comune),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("comune negozio", "nome_comune"),
            filteredValue: filteredInfo.nome_comune || null,
            sortOrder:
              sortedInfo.columnKey === "nome_comune" && sortedInfo.order,
            width: 150,
            align: "center",
          },
        ],
      },
      {
        title: "Referente",
        children: [
          {
            title: "Nome",
            dataIndex: "nome_referente",
            key: "nome_referente",
            sorter: (a, b) => a.nome_referente.localeCompare(b.nome_referente),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nome referente", "nome_referente"),
            filteredValue: filteredInfo.nome_referente || null,
            sortOrder:
              sortedInfo.columnKey === "nome_referente" && sortedInfo.order,
            width: 200,
          },
          {
            title: "Cognome",
            dataIndex: "cognome_referente",
            key: "cognome_referente",
            sorter: (a, b) =>
              a.cognome_referente.localeCompare(b.cognome_referente),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps(
              "cognome referente",
              "cognome_referente"
            ),
            filteredValue: filteredInfo.cognome_referente || null,
            sortOrder:
              sortedInfo.columnKey === "cognome_referente" && sortedInfo.order,
            width: 200,
          },
          {
            title: "Telefono",
            dataIndex: "telefono_referente",
            key: "telefono_referente",
            sorter: (a, b) =>
              a.telefono_referente.localeCompare(b.telefono_referente),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps(
              "telefono referente",
              "telefono_referente"
            ),
            filteredValue: filteredInfo.telefono_referente || null,
            sortOrder:
              sortedInfo.columnKey === "telefono_referente" && sortedInfo.order,
            width: 200,
          },
        ],
      },
      {
        title: "Moduli attivi",
        children: [
          {
            title: "Ordini",
            dataIndex: "modulo_ordini",
            key: "modulo_ordini",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.modulo_ordini || null,
            onFilter: (value, record) => record.modulo_ordini === value,
            ellipsis: true,
            width: 100,
          },
          {
            title: "Chat",
            dataIndex: "chat",
            key: "chat",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.chat || null,
            onFilter: (value, record) => record.chat === value,
            ellipsis: true,
            width: 100,
          },
          {
            title: "Appuntamenti",
            children: [
              {
                title: "Appuntamenti",
                dataIndex: "modulo_appuntamenti",
                key: "modulo_appuntamenti",
                render: (value) =>
                  value ? (
                    <Row justify="center" align="middle">
                      <Col>
                        <Tag color="green">Sì</Tag>
                      </Col>
                    </Row>
                  ) : (
                    <Row justify="center" align="middle">
                      <Col>
                        <Tag color="red">No</Tag>
                      </Col>
                    </Row>
                  ),
                filters: [
                  { text: "Sì", value: 1 },
                  { text: "No", value: 0 },
                ],
                filteredValue: filteredInfo.modulo_appuntamenti || null,
                onFilter: (value, record) => record.modulo_appuntamenti === value,
                ellipsis: true,
                width: 200,
              },
              {
                title: "Accetta Prenotazioni",
                dataIndex: "modulo_prenotazioni",
                key: "modulo_prenotazioni",
                render: (value) =>
                  value ? (
                    <Row justify="center" align="middle">
                      <Col>
                        <Tag color="green">Sì</Tag>
                      </Col>
                    </Row>
                  ) : (
                    <Row justify="center" align="middle">
                      <Col>
                        <Tag color="red">No</Tag>
                      </Col>
                    </Row>
                  ),
                filters: [
                  { text: "Sì", value: 1 },
                  { text: "No", value: 0 },
                ],
                filteredValue: filteredInfo.modulo_prenotazioni || null,
                onFilter: (value, record) => record.modulo_prenotazioni === value,
                ellipsis: true,
                width: 200,
              },

            ],
          }
        ],
      },
      {
        title: "Modalità Consegna",
        children: [
          {
            title: "Ritiro",
            dataIndex: "ritiro",
            key: "ritiro",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.ritiro || null,
            onFilter: (value, record) => record.ritiro === value,
            ellipsis: true,
            width: 120,
          },
          {
            title: "Consegna",
            dataIndex: "consegna",
            key: "consegna",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.consegna || null,
            onFilter: (value, record) => record.consegna === value,
            ellipsis: true,
            width: 120,
          },
        ],
      },
      {
        title: "Categoria",
        dataIndex: "categoria",
        key: "categoria",
        sorter: (a, b) => a.categoria.localeCompare(b.categoria),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("categoria", "categoria"),
        filteredValue: filteredInfo.categoria || null,
        sortOrder: sortedInfo.columnKey === "categoria" && sortedInfo.order,
        width: 250,
      },
      {
        title: "Partita IVA / Codice Fiscale",
        dataIndex: "partita_iva",
        key: "partita_iva",
        sorter: (a, b) => a.partita_iva.localeCompare(b.partita_iva),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("partita iva", "partita_iva"),
        filteredValue: filteredInfo.partita_iva || null,
        sortOrder: sortedInfo.columnKey === "partita_iva" && sortedInfo.order,
        width: 200,
      },
      {
        title: "Ubicazione",
        children: [
          {
            title: "Città",
            dataIndex: "citta",
            key: "citta",
            sorter: (a, b) => a.citta.localeCompare(b.citta),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("città", "citta"),
            filteredValue: filteredInfo.citta || null,
            sortOrder: sortedInfo.columnKey === "citta" && sortedInfo.order,
            width: 200,
          },
          {
            title: "Indirizzo",
            dataIndex: "indirizzo",
            key: "indirizzo",
            sorter: (a, b) => a.indirizzo.localeCompare(b.indirizzo),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("indirizzo", "indirizzo"),
            filteredValue: filteredInfo.indirizzo || null,
            sortOrder: sortedInfo.columnKey === "indirizzo" && sortedInfo.order,
            width: 300,
          },
          {
            title: "Civico",
            dataIndex: "civico",
            key: "civico",
            sorter: (a, b) => a.civico.localeCompare(b.civico),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("civico", "civico"),
            filteredValue: filteredInfo.civico || null,
            sortOrder: sortedInfo.columnKey === "civico" && sortedInfo.order,
            width: 120,
          },
          {
            title: "Provincia",
            dataIndex: "provincia",
            key: "provincia",
            sorter: (a, b) => a.provincia.localeCompare(b.provincia),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("provincia", "provincia"),
            filteredValue: filteredInfo.provincia || null,
            sortOrder: sortedInfo.columnKey === "provincia" && sortedInfo.order,
            width: 200,
          },
          {
            title: "CAP",
            dataIndex: "cap",
            key: "cap",
            sorter: (a, b) => a.cap.localeCompare(b.cap),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("cap", "cap"),
            filteredValue: filteredInfo.cap || null,
            sortOrder: sortedInfo.columnKey === "cap" && sortedInfo.order,
            width: 100,
          },
        ],
      },
      {
        title: "Metodi di pagamento",
        children: [
          {
            title: "Bancomat",
            dataIndex: "bancomat",
            key: "bancomat",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.bancomat || null,
            onFilter: (value, record) => record.bancomat === value,
            ellipsis: true,
            width: 150,
          },
          {
            title: "Carta",
            dataIndex: "carta",
            key: "carta",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.carta || null,
            onFilter: (value, record) => record.carta === value,
            ellipsis: true,
            width: 150,
          },
          {
            title: "Contante",
            dataIndex: "contante",
            key: "contante",
            render: (value) =>
              value ? (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="green">Sì</Tag>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" align="middle">
                  <Col>
                    <Tag color="red">No</Tag>
                  </Col>
                </Row>
              ),
            filters: [
              { text: "Sì", value: 1 },
              { text: "No", value: 0 },
            ],
            filteredValue: filteredInfo.contante || null,
            onFilter: (value, record) => record.contante === value,
            ellipsis: true,
            width: 150,
          },
          {
            title: "Altri Metodi",
            dataIndex: "altri_metodi",
            key: "altri_metodi",
            ...this.getColumnSearchProps("altri metodi", "altri_metodi"),
            width: 200,
          },
        ],
      },
      {
        title: "Contatti",
        children: [
          {
            title: "E-Mail",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("e-mail", "email"),
            filteredValue: filteredInfo.email || null,
            sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
            width: 300,
          },
          {
            title: "Sito web",
            dataIndex: "sito_web",
            key: "sito_web",
            sorter: (a, b) => a.sito_web.localeCompare(b.sito_web),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("sito web", "sito_web"),
            filteredValue: filteredInfo.sito_web || null,
            sortOrder: sortedInfo.columnKey === "sito_web" && sortedInfo.order,
            width: 500,
          },
          {
            title: "Telefono",
            dataIndex: "telefono",
            key: "telefono",
            sorter: (a, b) => a.telefono.localeCompare(b.telefono),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("telefono", "telefono"),
            filteredValue: filteredInfo.telefono || null,
            sortOrder: sortedInfo.columnKey === "telefono" && sortedInfo.order,
            width: 200,
          },
          {
            title: "Whatsapp",
            dataIndex: "whatsapp",
            key: "whatsapp",
            sorter: (a, b) => a.whatsapp.localeCompare(b.whatsapp),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("whatsapp", "whatsapp"),
            filteredValue: filteredInfo.whatsapp || null,
            sortOrder: sortedInfo.columnKey === "whatsapp" && sortedInfo.order,
            width: 200,
          },
          {
            title: "Instagram",
            dataIndex: "instagram",
            key: "instagram",
            sorter: (a, b) => a.instagram.localeCompare(b.instagram),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("instagram", "instagram"),
            filteredValue: filteredInfo.instagram || null,
            sortOrder: sortedInfo.columnKey === "instagram" && sortedInfo.order,
            width: 500,
          },
          {
            title: "Facebook",
            dataIndex: "facebook",
            key: "facebook",
            sorter: (a, b) => a.facebook.localeCompare(b.facebook),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("facebook", "facebook"),
            filteredValue: filteredInfo.facebook || null,
            sortOrder: sortedInfo.columnKey === "facebook" && sortedInfo.order,
            width: 500,
          },
        ],
      },
      {
        title: "Abilitato",
        dataIndex: "abilitato",
        key: "abilitato",
        fixed: "right",
        render: (value) =>
          value ? (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="green">Sì</Tag>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="red">No</Tag>
              </Col>
            </Row>
          ),
        filters: [
          { text: "Sì", value: 1 },
          { text: "No", value: 0 },
        ],
        filteredValue: filteredInfo.abilitato || null,
        onFilter: (value, record) => record.abilitato === value,
        ellipsis: true,
        width: 100,
      },
      {
        title: "Azioni",
        dataIndex: "settings",
        key: "settings",
        align: "center",
        width: 100,
        fixed: "right",
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <DeleteOutlined
              onClick={async () => {
                this._eliminaNegozio(record, index);
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
            <EditOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false });
                let comune = comuni_json.find((i) => i.nome == record.citta);
                const { data } = await getNegozio(record.id);
                const user = await getNegozioFirebaseInfo(
                  record.telefono_referente
                );
                this.setState({
                  drawer_visible: true,
                  loaded: true,
                  selected_negozio: data,
                  selected_index: data.id,
                  selected_user: user.data,
                  selected_citta_caps: comune
                    ? Array.isArray(comune.cap)
                      ? comune.cap
                      : [comune.cap]
                    : [],
                  img_to_delete: record.logo,
                });
              }}
            />
            <MessageOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false })
                try {
                  const utenteFirebase = await getNegozioFirebaseInfo(
                    record.telefono_referente
                  );
                  const res = await getUserChats(utenteFirebase.data.uid);
                  const chats = this._setChats(res.data);
                  this.setState({
                    drawerChatVisible: true,
                    currentUtente: record,
                    currentUtenteFirebase: utenteFirebase.data,
                    userChats: chats,
                  });
                } catch (error) {
                  console.error(error);
                  notification.destroy();
                  notification.error({
                    message: 'Errore',
                    description: error
                  })
                }
                finally {
                  this.setState({ loaded: true })
                }

              }}
            />
            <CalendarOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={() => {
                this.setState({
                  selected_telefono_referente: record.telefono_referente,
                  drawerAppuntamentiVisible: true
                })
              }}
            />
          </Row>
        ),
      },
    ];
  };



  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null ? (
        text
      ) : this.state.searchedColumn === dataIndex &&
        this.state.filteredInfo[dataIndex] != null ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    console.log(filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      searchText: "",
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  //#region Helpers select comune
  _getComuneById = (id_comune) => {
    let res = this.state.comuni.find((c) => c.id === id_comune);
    return res;
  };
  //#endregion

  _getCategoriaByCategoriaName = (name) => {
    let res = this.state.categorie.find((c) => c.nome === name);
    return res;
  };

  //#region Lifecylcle Componente
  _loader = async () => {
    this.setState({ loaded: false });
    try {
      let comuni = await getComuni();
      let negozi = await getNegozi();
      console.log("negozi", negozi);
      let categorie = await getCategorie();
      this.setState({
        negozi: negozi.data,
        comuni: comuni.data,
        categorie: categorie.data,
        loaded: true,
      });
    } catch (error) {
      console.log(error);
      message.error(
        "Si è verificato un errore durante il caricamento della pagina, si prega di riprovare."
      );
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const {
      selected_negozio,
      loaded,
      selected_user,
      cambia_mail_visible,
      new_mail,
    } = this.state;
    const {
      id_comune,
      nome_referente,
      cognome_referente,
      telefono_referente,
      nome_negozio,
      partita_iva,
      categoria,
      indirizzo,
      civico,
      provincia,
      cap,
      citta,
      latitudine,
      longitudine,
      logo,
      descrizione,
      orari_apertura,
      bancomat,
      carta,
      contante,
      altri_metodi,
      telefono,
      whatsapp,
      facebook,
      instagram,
      sito_web,
      email,
      //modalita_consegna,
      privacy_condizioni,
      abilitato,
      chat,
      modulo_ordini,
      ritiro,
      consegna,
      dettagli_consegna,
      modulo_prenotazioni,
      modulo_appuntamenti
    } = selected_negozio || {};

    const initialValues = {
      id_comune: id_comune ?? null,
      nome_referente: nome_referente ?? "",
      cognome_referente: cognome_referente ?? "",
      telefono_referente: telefono_referente
        ? telefono_referente.substring(0, 1) != "+"
          ? "+" + telefono_referente
          : telefono_referente
        : "+39",
      nome_negozio: nome_negozio ?? "",
      partita_iva: partita_iva ?? "",
      categoria: categoria ?? null,
      latitudine: String(latitudine) ?? "",
      longitudine: String(longitudine) ?? "",
      indirizzo: indirizzo ?? "",
      civico: civico ?? "",
      provincia: provincia ?? "",
      cap: cap ?? null,
      citta: citta ?? null,
      logo: logo ?? null,
      descrizione: descrizione ?? "",
      orari_apertura: orari_apertura ?? "",
      bancomat: bancomat ?? false,
      carta: carta ?? false,
      contante: contante ?? false,
      altri_metodi: altri_metodi ?? "",
      telefono: telefono
        ? telefono.substring(0, 1) != "+"
          ? "+" + telefono
          : telefono
        : "+39",
      whatsapp: whatsapp
        ? whatsapp.substring(0, 1) != "+"
          ? "+" + whatsapp
          : whatsapp
        : "+39",
      facebook: facebook ?? "",
      instagram: instagram ?? "",
      sito_web: sito_web ?? "",
      email: email ?? "",
      // modalita_consegna: modalita_consegna ?? '',
      privacy_condizioni: privacy_condizioni ?? false,
      abilitato: abilitato ?? false,
      chat: chat,
      modulo_ordini: modulo_ordini,
      ritiro: ritiro,
      consegna: consegna,
      dettagli_consegna: dettagli_consegna ?? "",
      modulo_prenotazioni: modulo_prenotazioni,
      modulo_appuntamenti: modulo_appuntamenti
    };

    return (
      <>
        <Wrapper>
          <PageHeader
            title="Negozi"
            description="In questa sezione ci sono tutti i negozi"
          />
          <Divider />
          <Row
            align="middle"
            justify="end"
            gutter={[15, 15]}
            style={{ marginBottom: "15px" }}
          >
            <Col>
              <Button onClick={this._clearFiltri} size="large">
                Reset Filtri
              </Button>
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => this.setState({ drawer_visible: true })}
                type="primary"
                size="large"
              >
                Aggiungi negozio
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={this.state.negozi}
            columns={this._getColumns()}
            onChange={this._handleChange}
            size="small"
            scroll={{ x: 576, y: 400 }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100", "200"],
            }}
          />

          {/*Drawer creazione/modifica negozio*/}
          <Drawer
            destroyOnClose
            placement="right"
            closable={true}
            onClose={() =>
              this.setState({
                drawer_visible: false,
                selected_negozio: null,
                selected_index: null,
                selected_user: null,
              })
            }
            visible={this.state.drawer_visible}
            width="100%"
            closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
          >
            <Row justify="start" align="middle" gutter={[15, 15]}>
              <Col xs={24}>
                <PageHeader
                  title={
                    selected_negozio ? "Modifica negozio" : "Aggiungi negozio"
                  }
                  description={
                    selected_negozio
                      ? `Aggiorna i dettagli del negozio: ${selected_negozio.nome_negozio}`
                      : "Aggiungi un nuovo negozio."
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Formik
              initialValues={initialValues}
              validationSchema={
                selected_negozio
                  ? validationSchemaAggiornaNegozio
                  : validationSchemaAggiungiNegozio
              }
              onSubmit={this._submitForm}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
                isSubmitting,
              }) => (
                <Form>
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Abilitazione" />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Checkbox
                        className="input"
                        checked={values.abilitato}
                        onBlur={handleBlur("abilitato")}
                        onChange={handleChange("abilitato")}
                      >
                        Abilitato
                      </Checkbox>
                    </Col>

                  </Row>
                  <Divider />
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Moduli" />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Checkbox
                        className="input"
                        checked={values.modulo_ordini}
                        onBlur={handleBlur("modulo_ordini")}
                        onChange={handleChange("modulo_ordini")}
                      >
                        Modulo Ordini
                      </Checkbox>
                      {values.modulo_ordini && (
                        <>
                          <Divider />
                          <Row
                            justify="start"
                            align="top"
                            gutter={[15, 15]}
                            style={{ marginBottom: 15 }}
                          >
                            <Col span={12}>
                              <Checkbox
                                className="input"
                                checked={values.ritiro}
                                onBlur={handleBlur("ritiro")}
                                onChange={handleChange("ritiro")}
                              >
                                Ritiro
                              </Checkbox>
                            </Col>
                            <Col span={12}>
                              <Checkbox
                                className="input"
                                checked={values.consegna}
                                onBlur={handleBlur("consegna")}
                                onChange={handleChange("consegna")}
                              >
                                Consegna
                              </Checkbox>
                            </Col>
                          </Row>
                          {values.consegna ? (
                            <Row justify="start" align="top" gutter={[15, 15]}>
                              <Col span={24}>
                                <span
                                  style={{
                                    marginBottom: 10,
                                    color: Settings.colors.darkGrey,
                                  }}
                                >
                                  Dettagli consegna (opzionale)
                                </span>
                                <Input.TextArea
                                  autoSize={{ minRows: 7, maxRows: 7 }}
                                  placeholder="Inserisci dettagli sulla consegna"
                                  maxLength={1500}
                                  onChange={handleChange("dettagli_consegna")}
                                  onBlur={handleBlur("dettagli_consegna")}
                                  value={values.dettagli_consegna}
                                />
                                <div className="input-error">
                                  {touched.dettagli_consegna &&
                                    errors.dettagli_consegna}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Checkbox
                        className="input"
                        checked={values.chat}
                        onBlur={handleBlur("chat")}
                        onChange={handleChange("chat")}
                      >
                        Modulo Chat
                      </Checkbox>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Checkbox
                        className="input"
                        checked={values.modulo_appuntamenti}
                        onBlur={handleBlur("modulo_appuntamenti")}
                        onChange={(e) => {
                          setFieldValue("modulo_appuntamenti", e.target.checked)
                          if (e.target.checked === false) setFieldValue("modulo_prenotazioni", false)
                        }}
                      >
                        Modulo Appuntamenti
                      </Checkbox>
                      {values.modulo_appuntamenti ? (
                        <>
                          <Divider />
                          <Checkbox
                            className="input"
                            checked={values.modulo_prenotazioni}
                            onBlur={handleBlur("modulo_prenotazioni")}
                            onChange={handleChange("modulo_prenotazioni")}
                          >
                            Accettazione Prenotazioni
                          </Checkbox>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  <Divider />
                  {selected_negozio ? (
                    <>
                      <Row justify="start" align="top" gutter={[15, 15]}>
                        <Col span={24}>
                          <Label titolo="Azioni account" />
                        </Col>
                      </Row>
                      <Row
                        justify="start"
                        align="top"
                        gutter={[15, 15]}
                        style={{ marginBottom: 15 }}
                      >
                        <Col xs={24} sm={24} md={12}>
                          <Row align="middle" justify="space-between">
                            <Col>
                              <span
                                style={{
                                  marginBottom: 10,
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                E-Mail
                              </span>
                            </Col>
                            <Col>
                              <Tag
                                style={{ margin: 0, cursor: "pointer" }}
                                disabled={!loaded}
                                color={
                                  selected_user.emailVerified
                                    ? "green"
                                    : "orange"
                                }
                                onClick={async () => {
                                  this.setState({ loaded: false });
                                  try {
                                    await inviaConfermaMailNegozio(
                                      selected_user.phoneNumber
                                    );
                                    notification.success({
                                      message:
                                        "Mail di conferma inviata correttamente",
                                      description:
                                        "Ora aspetta che il negozio confermi la mail",
                                    });
                                  } catch (error) {
                                    notification.error({
                                      message: "Errore nell'invio della mail",
                                      description: "Riprova più tardi",
                                    });
                                  } finally {
                                    this.setState({ loaded: true });
                                  }

                                }}
                              >
                                {selected_user.emailVerified
                                  ? "Verificata"
                                  : "Verifica Mail"}
                              </Tag>
                            </Col>
                          </Row>

                          <Input disabled value={selected_user.email} />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Telefono
                          </span>
                          <PhoneInput
                            disabled
                            value={selected_user.phoneNumber}
                            country="it"
                            inputStyle={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                      <Row justify="start" align="top" gutter={[15, 15]}>
                        <Col xs={24} sm={12} md={12}>
                          <Button
                            size="large"
                            block
                            onClick={() =>
                              this.setState({
                                new_mail: selected_user.email,
                                cambia_mail_visible: true,
                              })
                            }
                            type="primary"
                            icon={<EditOutlined />}
                          >
                            Cambia E-Mail
                          </Button>
                          <Modal
                            title="Cambia E-Mail"
                            okText="Conferma"
                            cancelText="Annulla"
                            visible={cambia_mail_visible}
                            onOk={async () => {
                              try {
                                this.setState({ loaded: false });
                                await updateEmailNegozio(
                                  telefono_referente,
                                  new_mail
                                );
                                await inviaConfermaMailNegozio(
                                  telefono_referente
                                );

                                notification.success({
                                  message: "Mail modificata correttamente",
                                  description: `Ora il negozio può accedere al suo pannello con la nuova mail`,
                                });
                                this.setState({
                                  cambia_mail_visible: false,
                                  selected_user: {
                                    ...selected_user,
                                    email: new_mail,
                                    emailVerified: false,
                                  },
                                });
                              } catch (e) {
                                notification.error({
                                  message: "Errore",
                                  description:
                                    "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
                                });
                              }
                              this.setState({ loaded: true });
                            }}
                            onCancel={() =>
                              this.setState({ cambia_mail_visible: false })
                            }
                          >
                            <Input
                              placeholder="Nuova Mail"
                              type="email"
                              defaultValue={new_mail}
                              onChange={(e) =>
                                this.setState({ new_mail: e.target.value })
                              }
                            />
                          </Modal>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                          <Button
                            size="large"
                            block
                            onClick={async () => {
                              this.setState({ loaded: false });
                              try {
                                await resetPasswordNegozio(
                                  selected_user.phoneNumber
                                );
                                notification.success({
                                  message:
                                    "Mail di reset password inviata correttamente",
                                  description:
                                    "Ora aspetta che il negozio resetti la password",
                                });
                              } catch (error) {
                                notification.error({
                                  message: "Errore nell'invio della mail",
                                  description: "Riprova più tardi",
                                });
                              }
                              this.setState({ loaded: true });
                            }}
                            type="primary"
                            icon={<EditOutlined />}
                          >
                            Cambia Password
                          </Button>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={24}>
                          <Row>
                            <span
                              style={{
                                marginBottom: 10,
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              Custom Claims
                            </span>
                          </Row>
                          <Row>
                            {selected_user.customClaims && (
                              <Popover content="Ruolo">
                                <Tag
                                  color={
                                    selected_user.customClaims.negozio
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {selected_user.customClaims.negozio
                                    ? "Negozio"
                                    : "Non è un negozio"}
                                </Tag>
                              </Popover>
                            )}
                            {selected_user.customClaims && (
                              <Popover content="ID negozio associato">
                                <Tag color="blue">
                                  {selected_user.customClaims.id_negozio ??
                                    "Id non presente"}
                                </Tag>
                              </Popover>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <Row justify="start" align="top" gutter={[15, 15]}>
                        <Col xs={24}>
                          <Label titolo="Dati account" />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            E-Mail
                          </span>
                          <Input
                            value={values.userMail ?? ""}
                            onChange={handleChange("userMail")}
                            placeholder="E-Mail"
                            onBlur={handleBlur("userMail")}
                          />
                          <div className="input-error">
                            {touched.userMail && errors.userMail}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Password
                          </span>
                          <Input.Password
                            onChange={handleChange("password")}
                            placeholder="Password"
                            onBlur={handleBlur("password")}
                          />
                          <div className="input-error">
                            {touched.password && errors.password}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Conferma Password
                          </span>
                          <Input.Password
                            onChange={handleChange("conferma_password")}
                            placeholder="Conferma Password"
                            onBlur={handleBlur("conferma_password")}
                          />
                          <div className="input-error">
                            {touched.conferma_password &&
                              errors.conferma_password}
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Dati principali" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Comune
                      </span>
                      <Select
                        onChange={(value) => {
                          setFieldValue("id_comune", value);
                        }}
                        size="default"
                        placeholder="Scegli un comune"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("id_comune")}
                        value={values.id_comune}
                      >
                        {this.state.comuni.map(({ id, nome }) => (
                          <Option value={id}>{nome}</Option>
                        ))}
                      </Select>
                      <div className="input-error">
                        {touched.id_comune && errors.id_comune}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Nome negozio
                      </span>
                      <Input
                        value={values.nome_negozio}
                        onChange={handleChange("nome_negozio")}
                        placeholder="Nome negozio"
                        onBlur={handleBlur("nome_negozio")}
                      />
                      <div className="input-error">
                        {touched.nome_negozio && errors.nome_negozio}
                      </div>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        <Space>
                          Partita IVA/Codice Fiscale
                          <Popover
                            content={
                              <div className="black-popover">
                                Se non hai la Partita IVA, inserisci il tuo
                                Codice Fiscale. La Partita IVA è composta da 11
                                cifre.
                              </div>
                            }
                          >
                            <QuestionCircleFilled />
                          </Popover>
                        </Space>
                      </span>
                      <Input
                        value={values.partita_iva}
                        onChange={handleChange("partita_iva")}
                        placeholder="Partita IVA / Codice Fiscale"
                        onBlur={handleBlur("partita_iva")}
                      />
                      <div className="input-error">
                        {touched.partita_iva && errors.partita_iva}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Categoria
                      </span>
                      <Select
                        onChange={(value) => setFieldValue("categoria", value)}
                        size="default"
                        placeholder="Scegli una categoria"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("categoria")}
                        value={values.categoria}
                      >
                        {this.state.categorie.map((c) => (
                          <Option key={c.nome}>{c.nome}</Option>
                        ))}
                      </Select>
                      <div className="input-error">
                        {touched.categoria && errors.categoria}
                      </div>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={18} md={20}>
                      <span
                        className="reduce-hashtags"
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Indirizzo negozio
                      </span>
                      <Input
                        value={values.indirizzo}
                        onChange={handleChange("indirizzo")}
                        placeholder="Indirizzo negozio"
                        onBlur={handleBlur("indirizzo")}
                      />
                      <div className="input-error">
                        {touched.indirizzo && errors.indirizzo}
                      </div>
                    </Col>
                    <Col xs={24} sm={6} md={4}>
                      <span
                        className="reduce-hashtags"
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Civico
                      </span>
                      <Input
                        value={values.civico}
                        onChange={handleChange("civico")}
                        placeholder="N° civico"
                        onBlur={handleBlur("civico")}
                      />
                      <div className="input-error">
                        {touched.civico && errors.civico}
                      </div>
                    </Col>
                    <Col xs={24} md={16}>
                      <span
                        className="reduce-hashtags"
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Città
                      </span>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={(value) => {
                          setFieldValue("citta", value);
                          let comune = comuni_json.find((i) => i.nome == value);
                          setFieldValue(
                            "cap",
                            comune && Array.isArray(comune.cap)
                              ? comune.cap[0]
                              : comune.cap
                          );
                          this.setState({
                            selected_citta_caps:
                              comune && Array.isArray(comune.cap)
                                ? comune.cap
                                : [comune.cap],
                          });
                          setFieldValue("provincia", comune.provincia.nome);
                        }}
                        size="default"
                        placeholder="Inserisci e seleziona la città"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("citta")}
                        value={values.citta}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {comuni_json.map((c) => (
                          <Option key={c.nome}>{c.nome}</Option>
                        ))}
                      </Select>
                      <div className="input-error">
                        {touched.citta && errors.citta}
                      </div>
                    </Col>
                    <Col xs={24} md={8}>
                      <span
                        className="reduce-hashtags"
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        CAP
                      </span>
                      <Select
                        onChange={(value) => setFieldValue("cap", value)}
                        size="default"
                        placeholder="Seleziona il cap"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("cap")}
                        value={values.cap}
                      >
                        {values.citta
                          ? this.state.selected_citta_caps.map((c) => (
                            <Option key={c}>{c}</Option>
                          ))
                          : null}
                      </Select>
                      <div className="input-error">
                        {touched.cap && errors.cap}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Dati referente" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Nome referente
                      </span>
                      <Input
                        value={values.nome_referente}
                        onChange={handleChange("nome_referente")}
                        placeholder="Nome referente"
                        onBlur={handleBlur("nome_referente")}
                      />
                      <div className="input-error">
                        {touched.nome_referente && errors.nome_referente}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Cognome referente
                      </span>
                      <Input
                        value={values.cognome_referente}
                        onChange={handleChange("cognome_referente")}
                        placeholder="Cognome referente"
                        onBlur={handleBlur("cognome_referente")}
                      />
                      <div className="input-error">
                        {touched.cognome_referente && errors.cognome_referente}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Telefono referente
                      </span>
                      <PhoneInput
                        inputClass="ant-input"
                        value={values.telefono_referente}
                        placeholder="Telefono referente"
                        country="it"
                        enableSearch
                        countryCodeEditable={false}
                        searchPlaceholder="Cerca"
                        onChange={handleChange("telefono_referente")}
                        onBlur={handleBlur("telefono_referente")}
                        inputStyle={{ width: "100%" }}
                      />
                      <div className="input-error">
                        {touched.telefono_referente &&
                          errors.telefono_referente}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Componi la vetrina" />
                    </Col>
                    <Col xs={24} md={8}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Immagine (opzionale)
                      </span>
                      <div style={{ width: "100%" }}>
                        <ImageCropper
                          cropSize={{ width: 400, height: 300 }}
                          aspect={4 / 3}
                          image={values.logo ? values.logo : null}
                          onCropSuccess={(blobUrl) =>
                            setFieldValue("logo", blobUrl)
                          }
                          onChange={handleChange("logo")}
                        />
                      </div>
                      <div className="input-error">
                        {touched.logo && errors.logo}
                      </div>
                    </Col>
                    <Col xs={24} md={16}>
                      <span style={{ color: Settings.colors.darkGrey }}>
                        Descrizione (opzionale)
                      </span>
                      <TextArea
                        value={values.descrizione}
                        onChange={handleChange("descrizione")}
                        placeholder="Inserisci informazioni rilevanti sulla tua attività come: descrizione prodotti, orari di apertura ecc... "
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        onBlur={handleBlur("descrizione")}
                        style={{ height: 180 }}
                      />
                      <div className="input-error">
                        {touched.descrizione && errors.descrizione}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col xs={24}>
                      <Label titolo="Giorni e orari di apertura" />
                    </Col>
                    <Col span={24}>
                      <span style={{ color: Settings.colors.darkGrey }}>
                        Orari di apertura (opzionale)
                      </span>
                      <TextArea
                        value={values.orari_apertura}
                        onChange={handleChange("orari_apertura")}
                        placeholder="Aperto dal lunedì al venerdì dalle ore…"
                        autoSize={{ minRows: 10, maxRows: 7 }}
                        onBlur={handleBlur("orari_apertura")}
                      />
                      <div className="input-error">
                        {touched.orari_apertura && errors.orari_apertura}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Metodi di pagamento accettati" />
                    </Col>
                    <Col xs={24} md={8}>
                      <Checkbox
                        checked={values.contante}
                        onBlur={handleBlur("contante")}
                        onChange={handleChange("contante")}
                      >
                        Contante
                      </Checkbox>
                    </Col>
                    <Col xs={24} md={8}>
                      <Checkbox
                        checked={values.carta}
                        onBlur={handleBlur("carta")}
                        onChange={handleChange("carta")}
                      >
                        Carta di credito
                      </Checkbox>
                    </Col>
                    <Col xs={24} md={8}>
                      <Checkbox
                        checked={values.bancomat}
                        onBlur={handleBlur("bancomat")}
                        onChange={handleChange("bancomat")}
                      >
                        Bancomat
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col span={24}>
                      <span style={{ color: Settings.colors.darkGrey }}>
                        Altri metodi di pagamento (opzionale)
                      </span>
                      <TextArea
                        value={values.altri_metodi}
                        onChange={handleChange("altri_metodi")}
                        placeholder="Altri metodi di pagamento"
                        autoSize={{ minRows: 10, maxRows: 7 }}
                        onBlur={handleBlur("altri_metodi")}
                      />
                      <div className="input-error">
                        {touched.altri_metodi && errors.altri_metodi}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="start" align="top" gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Contatti" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Telefono (opzionale)
                      </span>
                      <PhoneInput
                        inputClass="ant-input"
                        value={values.telefono}
                        placeholder="Telefono"
                        country="it"
                        enableSearch
                        countryCodeEditable={false}
                        searchPlaceholder="Cerca"
                        onChange={handleChange("telefono")}
                        onBlur={handleBlur("telefono")}
                        inputStyle={{ width: "100%" }}
                      />
                      <div className="input-error">
                        {touched.telefono && errors.telefono}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        E-Mail (opzionale)
                      </span>
                      <Input
                        value={values.email ?? ""}
                        onChange={handleChange("email")}
                        placeholder="E-Mail"
                        onBlur={handleBlur("email")}
                      />
                      <div className="input-error">
                        {touched.email && errors.email}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Whatsapp (opzionale)
                      </span>
                      <PhoneInput
                        inputClass="ant-input"
                        value={values.whatsapp}
                        placeholder="Whatsapp"
                        country="it"
                        enableSearch
                        searchPlaceholder="Cerca"
                        countryCodeEditable={false}
                        onChange={handleChange("whatsapp")}
                        onBlur={handleBlur("whatsapp")}
                        inputStyle={{ width: "100%" }}
                      />
                      <div className="input-error">
                        {touched.whatsapp && errors.whatsapp}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Facebook (opzionale)
                      </span>
                      <Input
                        value={values.facebook}
                        onChange={handleChange("facebook")}
                        placeholder="Facebook"
                        onBlur={handleBlur("facebook")}
                      />
                      <div className="input-error">
                        {touched.facebook && errors.facebook}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Instagram (opzionale)
                      </span>
                      <Input
                        value={values.instagram}
                        onChange={handleChange("instagram")}
                        placeholder="Instagram"
                        onBlur={handleBlur("instagram")}
                      />
                      <div className="input-error">
                        {touched.instagram && errors.instagram}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span
                        style={{
                          marginBottom: 10,
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Sito internet (opzionale)
                      </span>
                      <Input
                        value={values.sito_web}
                        onChange={handleChange("sito_web")}
                        placeholder="Sito internet"
                        onBlur={handleBlur("sito_web")}
                      />
                      <div className="input-error">
                        {touched.sito_web && errors.sito_web}
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="center" align="middle">
                    <Col span={24}>
                      <Button
                        loading={isSubmitting}
                        disabled={!dirty || isSubmitting}
                        icon={
                          selected_negozio ? <EditOutlined /> : <PlusOutlined />
                        }
                        block={true}
                        size="large"
                        onClick={() => {
                          console.log(errors)
                          if (!isValid)
                            notification.error({
                              message: "Errore",
                              description: "Ricontrolla i dati inseriti",
                            });
                          handleSubmit();
                        }}
                        type="primary"
                      >
                        {this.state.selected_negozio ? (
                          <span>Aggiorna</span>
                        ) : (
                          <span>Aggiungi</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Drawer>
          <Drawer
            visible={this.state.drawerChatVisible}
            title={"Dati utente"}
            onClose={() => {
              this.setState({
                drawerChatVisible: false,
                currentUtente: null,
                currentUtenteFirebase: null,
                userChats: [],
                userMessages: [],
                currentChatTitle: ""
              });
            }}
            width={"100%"}
          >
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "400px",
                  height: "100%",
                  overflowY: "scroll",
                  top: 0,
                  left: 0,
                  padding: "15px",
                  paddingTop: "0px",
                  zIndex: 9999,
                  backgroundColor: Settings.colors.white,
                  borderRight: "1px solid",
                  borderColor: Settings.colors.lightGrey,
                }}
                className="chatPanel"
              >
                <ChatList
                  chats={this.state.userChats}
                  callBackGetMessage={this._getMessages}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  left: "400px",
                  top: 0,
                  width: "calc(100% - 400px)",
                  height: "100%",
                  zIndex: 9999,
                }}
                className="chatList"
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "70px",
                    top: "64px",
                    left: 0,
                    borderBottom: "1px solid",
                    zIndex: 9999,
                    backgroundColor: Settings.colors.white,
                    borderColor: Settings.colors.lightGrey,
                  }}
                >
                  {this.state.loadingMessages ? null : (
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{
                        height: "100%",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      <Col>
                        <Row justify="start" align="middle" gutter={15}>
                          <Col>
                            <img
                              height="40px"
                              width="53px"
                              style={styles.chat_image}
                              src={placeholder}
                            />
                          </Col>
                          <Col>
                            <Row>
                              <Typography.Title
                                level={3}
                                style={styles.chat_title}
                              >
                                {this.state.currentChatTitle}
                              </Typography.Title>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "calc(100% - 203px)",
                    padding: "15px",
                    top: "133px",
                    bottom: "70px",
                    left: 0,
                    borderBottom: "1px solid",
                    overflowY: "scroll",
                    borderColor: Settings.colors.lightGrey,
                  }}
                  id="chat"
                  className="chatList"
                >
                  {this.state.loadingMessages ? (
                    <LoadingBlock loading={true} />
                  ) : (
                    <List
                      locale={{ emptyText: "Nessun Messaggio" }}
                      dataSource={this.state.userMessages}
                      renderItem={({
                        firebase_db_chat_id,
                        from,
                        type,
                        message,
                        timestamp,
                      }) => (
                        <List.Item
                          style={{
                            textAlign:
                              this.state.currentUtente.id_utenza_firebase ===
                                from
                                ? "right"
                                : "left",
                            border: "none",
                            padding: "4px 0px",
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span
                                style={{
                                  color:
                                    this.state.currentUtente
                                      .id_utenza_firebase === from
                                      ? Settings.colors.white
                                      : Settings.colors.darkGrey,
                                  padding: "5px 10px",
                                  background:
                                    this.state.currentUtente
                                      .id_utenza_firebase === from
                                      ? Settings.colors.cyan
                                      : Settings.colors.lightGrey,
                                  borderRadius: "4px",
                                  maxWidth: "80%",
                                  display: "inline-block",
                                  textAlign: "left",
                                }}
                              >
                                {message}
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </Drawer>

          {/*DRAWER APPUNTAMENTI NEGOZIO*/}
          {
            this.state.selected_telefono_referente &&
            <DrawerAppuntamenti
              closeDrawer={this._closeAppuntamentiDrawer}
              drawerVisible={this.state.drawerAppuntamentiVisible}
              telefono_referente={this.state.selected_telefono_referente}
            />
          }

        </Wrapper>
        <Loading loading={!loaded} />
      </>
    );
  }
  //#endregion
}
const styles = {
  wrapper: {
    padding: "25px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  title: {
    color: Settings.colors.darkGrey,
  },
  label: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};
export default Negozi;
