import { Functions } from '../Config/Fire';

//Ottieni tutte le categorie dei negozi
export function getCategorie() {
    const categorie = Functions.httpsCallable('admin-getCategorie');
    return categorie();
}

//Aggiungi una categoria negozio
export function aggiungiCategoria(values) {
    const categoria = Functions.httpsCallable('admin-aggiungiCategoria');
    return categoria(values);
}

//Aggiorna una categoria negozio
export function aggiornaCategoria(id, values) {
    const categoria = Functions.httpsCallable('admin-aggiornaCategoria');
    return categoria({id: id, ...values});
}

//Elimina una categoria negozio
export function eliminaCategoria(id_categoria) {
    const categoria = Functions.httpsCallable('admin-eliminaCategoria');
    return categoria(id_categoria);
}