import { Button, Col, Divider, Form, Input, Modal, notification, Row, Select, Space, Table } from 'antd';
import React, { Component } from 'react';
import Loading from '../Components/Loading';
import PageHeader from '../Components/PageHeader';
import Wrapper from '../Components/Wrapper';

import { PlusOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Settings from '../Config/Settings';
import { eliminaComunicazione, getComunicazione, getComunicazioni, postComunicazione, updateComunicazione } from '../Controllers/Comunicazioni';
import { getComuni } from '../Controllers/Comuni';
import { Formik } from 'formik';
import * as Yup from "yup";
import Highlighter from 'react-highlight-words';

class Comunicazioni extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      comunicazioni: [],
      comuni: []
    }
  }

  _getComuni = async () => {
    try {
      const { data } = await getComuni();
      this.setState({ comuni: data })
    } catch (error) {
      notification.destroy();
      notification.error({
        message: 'Errore',
        description: error
      })
      console.log(error)
    }
  }

  _getComunicazioni = async () => {
    try {
      const { data } = await getComunicazioni();
      this.setState({ comunicazioni: data })
    } catch (error) {
      notification.destroy();
      notification.error({
        message: 'Errore',
        description: error
      })
      console.log(error)
    }
  }

  _loader = async () => {
    this.setState({ loading: true });
    await this._getComuni();
    await this._getComunicazioni();
    this.setState({ loading: false })
  }


  _eliminaComunicazione = (record, index) => {
    Modal.confirm({
      title: `Stai eliminando la comunicazione ${record.titolo}`,
      icon: <InfoCircleOutlined />,
      content: (
        <Row justify="start" align="middle" gutter={[0, 15]}>
          <Col span={24}>
            Sei sicuro di voler eliminare la comunicazione {record.titolo}?
            </Col>
        </Row>
      ),
      onOk: async () => {
        await eliminaComunicazione(record.id);
        notification.destroy();
        notification.success({
          title: "Congratulazioni!",
          message: "Hai eliminato correttamente la comunicazione",
        });
        this._loader();
      },

      okText: "Elimina",
      cancelText: "Annulla",
    });
  }

  _submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { selected_comunicazione } = this.state;
    const comunicazioni = this.state.comunicazioni;

    const { titolo, descrizione, id_comune } = values;

    const comunicazioneRecord = {
      titolo: titolo,
      descrizione: descrizione,
      id_comune: id_comune
    };

    if (selected_comunicazione) {
      try {
        const { id } = selected_comunicazione;
        const { data } = await updateComunicazione({ id: id, ...comunicazioneRecord });

        notification.destroy();
        notification.success({
          title: "Congratulazioni!",
          message: "Hai modificato correttamente la comunicazione",
        });
      } catch (error) {
        notification.destroy();
        notification.error({
          title: "Errore!",
          message:
            "Errore nell'aggiornamento della comunicazione, contattare l'assistenza",
        });
      }
    } else {
      try {
        const { data } = await postComunicazione(comunicazioneRecord);

        notification.destroy();
        notification.success({
          title: "Congratulazioni!",
          message: "Hai aggiunto correttamente la comunicazione",
        });
      } catch (error) {
        notification.destroy();
        notification.error({
          title: "Errore!",
          message:
            "Errore nell'aggiunta della comunicazione, contattare l'assistenza",
        });
      }
    }
    this._loader();
    setSubmitting(false);
    this._closeModal();
  };

  _openModal = () => {
    this.setState({ modal_visible: true })
  }

  _closeModal = () => {
    this.setState({ modal_visible: false })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Comunicazione",
        dataIndex: "titolo",
        key: "titolo",
        width: 150,
        sorter: (a, b) => a.titolo.localeCompare(b.titolo),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("titolo", "titolo"),
        filteredValue: filteredInfo.titolo || null,
        sortOrder: sortedInfo.columnKey === 'titolo' && sortedInfo.order,
      },
      {
        title: 'Comune',
        children: [
          {
            title: "ID",
            width: 70,
            dataIndex: "id_comune",
            key: "id_comune",
            sorter: (a, b) => a.id_comune - b.id_comune,
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("id_comune", "id_comune"),
            filteredValue: filteredInfo.id_comune || null,
            sortOrder: sortedInfo.columnKey === 'id_comune' && sortedInfo.order,
          },
          {
            title: "Nome",
            width: 150,
            dataIndex: "nome_comune",
            key: "nome_comune",
            sorter: (a, b) => a.nome_comune.localeCompare(b.nome_comune),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nome_comune", "nome_comune"),
            filteredValue: filteredInfo.nome_comune || null,
            sortOrder: sortedInfo.columnKey === 'nome_comune' && sortedInfo.order,
          },
        ]
      },
      {
        title: "Descrizione",
        width: 300,
        dataIndex: "descrizione",
        key: "descrizione",
        sorter: (a, b) => a.descrizione.localeCompare(b.descrizione),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("descrizione", "descrizione"),
        filteredValue: filteredInfo.descrizione || null,
        sortOrder: sortedInfo.columnKey === 'descrizione' && sortedInfo.order,
      },
      {
        title: "Azioni",
        dataIndex: "settings",
        key: "settings",
        fixed: "right",
        align: "center",
        width: 100,
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <Col>
              <DeleteOutlined
                onClick={async () => { this._eliminaComunicazione(record, index); }}
                style={{ color: Settings.colors.grey, fontSize: 18 }}
              />
            </Col>
            <Col>
              <EditOutlined
                style={{ color: Settings.colors.grey, fontSize: 18 }}
                onClick={async () => {
                  this.setState({ loading: true });
                  try {
                    const { data } = await getComunicazione(record.id);
                    this.setState({
                      selected_comunicazione: data,
                      modal_visible: true
                    });
                  } catch (error) {
                    console.log(error);
                    notification.destroy();
                    notification.error({
                      message: 'Errore',
                      description: error.message
                    })
                  } finally {
                    this.setState({ loading: false })
                  }


                }}
              />
            </Col>
          </Row>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
        </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
        </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  componentDidMount() {
    this._loader();
  }


  render() {
    const {
      loading,
      comunicazioni,
      selected_comunicazione,
      modal_visible
    } = this.state;

    const {
      titolo,
      descrizione,
      id_comune
    } = selected_comunicazione || {};

    const initialValues = {
      titolo: titolo ?? "",
      descrizione: descrizione ?? "",
      id_comune: id_comune ?? null
    };

    return (
      <Wrapper>
        <PageHeader
          title="Comunicazioni"
          description="In questa sezione ci sono tutti le comunicazioni" />
        <Divider />
        <Row align='middle' justify='end' gutter={[15, 15]} style={{ marginBottom: 15 }}>
          <Col>
            <Button
              onClick={this._clearFiltri}
              size='large'
            >
              Reset Filtri
            </Button>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              onClick={() => this.setState({ modal_visible: true })}
              type="primary"
              size='large'
            >
              Aggiungi comunicazione
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          dataSource={comunicazioni}
          columns={this._getColumns()}
          onChange={this._handleChange}
          size="small"
          scroll={{ x: 576, y: 400 }}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100", "200"] }}
        />
        {/* MODAL MODIFICA/AGGIUNTA COMUNICAZIONI */}
        <Modal
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({
              modal_visible: false,
              selected_comunicazione: null,
            });
          }}
          transitionName="zoom"
          title={selected_comunicazione ? "Modifica Comunicazione" : "Aggiungi Comunicazione"}
          visible={modal_visible}
        >
          <Row justify="space-between" align="middle" gutter={[15, 15]}>
            <Col span={24}>
              <Formik
                validationSchema={Yup.object().shape({
                  titolo: Yup.string()
                    .strict(true)
                    .trim("Titolo con spazi vuoti iniziali/finali")
                    .nullable()
                    .required("Titolo mancante")
                    .max(100, "Titolo > 100 caratteri"),
                  descrizione: Yup.string()
                    .strict(true)
                    .trim("Descrizione con spazi vuoti iniziali/finali")
                    .nullable()
                    .required("Descrizione mancante")
                    .max(1500, "Descrizione > 1500 caratteri"),
                  id_comune: Yup.number()
                    .required('Comune non selezionato')
                })}
                initialValues={initialValues}
                validateOnMount={false}
                validateOnChange={true}
                onSubmit={this._submitForm}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isValid,
                  isSubmitting,
                  dirty,
                }) => (
                  <Form>
                    <Row
                      justify="center"
                      align="middle"
                      gutter={[15, 15]}
                    >
                      <Col span={24}>
                        <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Titolo</span>
                        <Input
                          value={values.titolo}
                          onChange={handleChange("titolo")}
                          placeholder="Inserisci titolo comunicazione..."
                          onBlur={handleBlur("titolo")}
                        />
                        <div style={styles.inputError}>
                          {touched.titolo && errors.titolo}
                        </div>
                      </Col>
                      <Col span={24}>
                        <span style={styles.inputLabel}>Descrizione</span>
                        <span
                          style={{
                            color: Settings.colors.grey,
                            fontSize: 13,
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                          ({1500 - values.descrizione.length} caratteri rimanenti)
                        </span>
                        <Input.TextArea
                          value={values.descrizione}
                          onChange={handleChange("descrizione")}
                          placeholder="Inserisci la descrizione della tua comunicazione..."
                          autoSize={{ minRows: 7, maxRows: 7 }}
                          maxLength={1500}
                          onBlur={handleBlur("descrizione")}
                          style={{ height: 180 }}
                        />
                        <div style={styles.inputError}>
                          {touched.descrizione && errors.descrizione}
                        </div>
                      </Col>
                      <Col span={24}>
                        <span style={styles.inputLabel}>Comune</span>
                        <Select
                          onChange={async (value) => { setFieldValue("id_comune", value) }}
                          size="default"
                          style={{ width: "100%" }}
                          onBlur={handleBlur("id_negozio")}
                          defaultValue={values.id_comune}
                          placeholder="Seleziona un comune..."
                        >
                          {this.state.comuni.map(({ id, nome }) => (
                            <Select.Option key={id} value={id}>{id} - {nome}</Select.Option>
                          ))}
                        </Select>
                        <div style={styles.inputError}>
                          {touched.id_comune && errors.id_comune}
                        </div>
                      </Col>
                      <Col span={24}>
                        <Button
                          icon={selected_comunicazione ? (<EditOutlined />) : (<PlusOutlined />)}
                          loading={isSubmitting}
                          disabled={!(isValid && dirty) || isSubmitting}
                          block={true}
                          size="large"
                          onClick={handleSubmit}
                          type="primary"
                        >
                          {selected_comunicazione ? "Aggiorna" : "Aggiungi"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Modal>
        <Loading loading={loading} />
      </Wrapper>
    );
  }
}

const styles = {
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
  inputLabel: {
    marginBottom: 10,
    color: Settings.colors.darkGrey,
  },
}

export default Comunicazioni;