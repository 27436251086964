import React from 'react';
import Wrapper from '../Components/Wrapper';
import PageHeader from '../Components/PageHeader';
import { Divider } from 'antd';

class Dashboard extends React.Component{
    render(){ 
        return (
            <>
                <Wrapper>
                    <PageHeader title='Dashboard' description='Qui trovi i dati del profilo del comune. Contatta Garzone per eventuali modifiche.' />
                    <Divider />
                </Wrapper> 
            </>
        );
    }
}

export default Dashboard;