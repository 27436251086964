import { Functions } from "../Config/Fire";

export async function getComuni(){
    const comuni = Functions.httpsCallable('admin-getComuni');
    return comuni();
}

export async function getComune(id){
  const comune = Functions.httpsCallable('admin-getComune');
  return comune(id);
}

export async function registraComune(values){
   const comune = Functions.httpsCallable('admin-registraComune');
   return comune(values);
}

export async function aggiornaComune(id_comune, values){
    const comune = Functions.httpsCallable('admin-aggiornaComune');
    return comune({id: id_comune, ...values});
}

export async function eliminaComune(id){
  return 'ok';
}

export async function getComuneFirebaseInfo(telefono_comune){
  const utente = Functions.httpsCallable('admin-getComuneFirebaseInfo');
  return utente(telefono_comune);
}