import React from "react";

//import antd
import { Row, Col, Button, Input, Modal, Drawer, Select, Divider, Upload, Table, Space, notification, message, InputNumber, Statistic } from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

//import components and styles
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaAggiungiCategoria, validationSchemaAggiornaCategoria } from "../Config/Validation";

// import Registrazione controller functions
import {
  getCategorie,
  aggiungiCategoria,
  aggiornaCategoria,
  eliminaCategoria
} from "../Controllers/Categorie";

import ImageCropper from "../Components/ImageCropper";
import Label from "../Components/Label";
import Loading from "../Components/Loading";

//Costanti
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

class Categorie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,

      drawer_visible: false,
      modal_visible: false,

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      selected_category: null,
      selected_index: null,

      categorie: [],
    };
  }

  //#region Helpers Gestione Categorie
  _eliminaCategoria = (categoria, indice) => {
    confirm({
      title: "Elimina categoria",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare la categoria: ${categoria.nome}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo categoria dal db
          const categoria_eliminata = await eliminaCategoria(categoria.id);

          //Rimuovo categoria nello stato
          let categorie = [...this.state.categorie];
          categorie.splice(indice, 1);

          this.setState({ categorie: categorie });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato ${categoria.nome} con successo`
          });
        } catch (error) {
          console.log("errore");
          notification.error({ title: "Errore", description: "Si è verificato un errore durante l'eliminazione della categoria" });
        }
      },
    });
  };

  _submitForm = async (values, { setSubmitting }) => {
    const { selected_category, selected_index } = this.state;
    let categorie = [...this.state.categorie];
    const {
      id,
      nome,
      immagine
    } = values;
    const categoriaRecord = {
      nome: nome,
      immagine: immagine
    }
    try {
      let categoria = null;
      let message = "Categoria aggiornata";
      let description = "La categoria è stata correttamente aggiornata";
      if (selected_category) {
        categorie
          .filter(n => n.id === selected_index)
          .map(i => i = {
            id: selected_category.id,
            ...categoriaRecord
          });

        if (selected_category.immagine.substring(0, 5) == 'https') {
          delete selected_category.immagine;
        }

        categoria = await aggiornaCategoria(parseInt(selected_category.id), categoriaRecord);
        this.setState({ categorie: categorie });
      } else {
        categoria = await aggiungiCategoria(categoriaRecord);
        categorie.push({ id: categoria.data.id, ...values });
        this.setState({ categorie: categorie });
        message = "Categoria creata";
        description = "La categoria è stata correttamente creata";
      }

      this.setState({ drawer_visible: false, selected_category: null, selected_index: null })
      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
      console.log("Success", categoria);
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      notification.warn({
        message: "Errore",
        description: "Si è verificato un errore",
      });
    }
  };
  //#endregion

  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null
    })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Categoria",
        dataIndex: "nome",
        key: "nome",
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nome", "nome"),
        filteredValue: filteredInfo.nome || null,
        sortOrder: sortedInfo.columnKey === 'nome' && sortedInfo.order,
      },
      {
        title: "Immagine",
        dataIndex: "immagine",
        key: "immagine",
        align: 'center',
        render: (text, record) => <div><img style={{ width: '80px' }} src={`${record.immagine ?? ''}`} /></div>
      },
      {
        title: "",
        dataIndex: "settings",
        key: "settings",
        fixed: 'right',
        width: 100,
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <DeleteOutlined
              onClick={async () => {
                this._eliminaCategoria(record, index);
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
            <EditOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={() => this.setState({
                drawer_visible: true,
                selected_category: record,
                selected_index: record.id
              })}
            />
          </Row>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
            text
          ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  //#region Lifecycle componente
  _loader = async () => {
    try {
      let categorie = await getCategorie();
      this.setState({
        categorie: categorie.data,
        loaded: true
      });
    } catch (error) {
      message.error("Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.");
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_category, loaded } = this.state;
    const {
      nome,
      immagine
    } = selected_category || {};

    const initialValues = {
      nome: nome ?? '',
      immagine: immagine ?? null
    };

    return loaded ? (
      <Wrapper>
        <PageHeader
          title="Categorie"
          description="In questa sezione ci sono tutte le categorie" />
        <Divider />
        <Row align='middle' justify='end' gutter={[15, 15]} style={{ marginBottom: '15px' }}>
          <Col>
            <Button
              onClick={this._clearFiltri}
              size='large'
            >
              Reset Filtri
                            </Button>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              onClick={() => this.setState({ drawer_visible: true })}
              type="primary"
              size='large'
            >
              Aggiungi categoria
                            </Button>
          </Col>
        </Row>
        <Table
          bordered
          dataSource={this.state.categorie}
          columns={this._getColumns()}
          onChange={this._handleChange}
          size="small"
          scroll={{ x: 576, y: 400 }}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100", "200"] }}
        />

        {/*Drawer creazione/modifica categoria*/}
        <Drawer
          destroyOnClose
          placement="right"
          closable={true}
          onClose={() => this.setState({ drawer_visible: false, selected_category: null, selected_index: null })}
          visible={this.state.drawer_visible}
          width="100%"
          closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
        >
          <Row justify='start' align='middle' gutter={[15, 15]}>
            <Col xs={24}>
              <PageHeader
                title={selected_category
                  ? "Modifica categoria"
                  : "Aggiungi categoria"}
                description={selected_category
                  ? `Aggiorna i dettagli della categoria: ${selected_category.nome}`
                  : "Aggiungi una nuova categoria."}
              />
            </Col>
          </Row>
          <Divider />
          <Formik
            initialValues={initialValues}
            validationSchema={selected_category ? validationSchemaAggiornaCategoria : validationSchemaAggiungiCategoria}
            onSubmit={this._submitForm}
          >
            {({ touched, errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => (
              <Form>
                <Row justify='start' align='top' gutter={[15, 15]}>
                  <Col xs={24}>
                    <Label titolo="Dati principali" />
                  </Col>
                  <Col xs={24} md={12}>
                    <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Immagine</span>
                    <div style={{ width: "100%" }}>
                      <ImageCropper
                        cropSize={{ width: 400, height: 300 }}
                        aspect={4 / 3}
                        image={
                          values.immagine ? values.immagine : null
                        }
                        onCropSuccess={blobUrl => setFieldValue("immagine", blobUrl)}
                        onChange={handleChange("immagine")}
                      />
                    </div>
                    <div className="input-error">{touched.immagine && errors.immagine}</div>
                  </Col>
                  <Col xs={24} md={12}>
                    <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Nome categoria</span>
                    <Input
                      value={values.nome}
                      onChange={handleChange("nome")}
                      placeholder="Inserisci nome categoria..."
                      onBlur={handleBlur("nome")}
                    />
                    <div className="input-error">{touched.nome && errors.nome}</div>
                  </Col>
                </Row>
                <Divider />
                <Row justify='center' align='middle'>
                  <Col span={24}>
                    <Button
                      loading={isSubmitting}
                      size='large'
                      disabled={!dirty || isSubmitting}
                      icon={selected_category ? <EditOutlined /> : <PlusOutlined />}
                      block={true}
                      size="large"
                      onClick={() => {
                        if (!isValid)
                          notification.error({
                            message: 'Errore',
                            description: 'Ricontrolla i dati inseriti'
                          });
                        handleSubmit();
                      }}
                      type="primary"
                    >
                      {this.state.selected_category ? <span>Aggiorna</span> : <span>Aggiungi</span>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Drawer>
        {/*End Drawer creazione/modifica categoria*/}
      </ Wrapper>
    ) : <Wrapper>
        <Loading loading={true} />
      </Wrapper>
  }
  //#endregion
}

export default Categorie;
