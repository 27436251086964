import React from "react";

//import antd
import { Row, Col, Button, Input, Modal, Drawer, Select, Divider, Upload, Table, Space, notification, message, InputNumber, Statistic, Tag, Checkbox, Popover } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  InfoCircleOutlined
} from "@ant-design/icons";

//import components and styles
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import Loading from "../Components/Loading";

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaAggiungiServizio, validationSchemaAggiornaServizio } from "../Config/Validation";

// import Registrazione controller functions
import {
  eliminaServizio,
  aggiornaServizio,
  creaServizio,
  getServiziWithNegozio,
  setCategorieServiziNegozio,
  getCategorieServiziNegozio, getServizio, setCategoriaServizi
} from "../Controllers/Servizi";
import {
  getNegoziWithNomeCategorie
} from '../Controllers/Negozi';
import Label from "../Components/Label";
import ImageCropper from "../Components/ImageCropper";

//Costanti
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

class Servizi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,
      isAdding: false,

      drawer_visible: false,
      modal_visible: false,
      scontato_visible: false,

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      selected_service: null,
      selected_index: null,
      nuova_categoria: "",
      categoria_edit: '',

      img_to_delete: null,

      servizi: [],
      negozi: [],
      categorie: [],

      categorieDisabled: this.categorie ? false : true
    };
  }

  //#region Helpers Gestione Servizi
  _eliminaServizio = (servizio, indice) => {
    confirm({
      title: "Elimina servizio",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare il servizio: ${servizio.nome}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo servizio dal db
          const servizio_eliminato = await eliminaServizio(servizio.id);

          //Rimuovo servizio nello stato
          let servizi = [...this.state.servizi];
          servizi.splice(indice, 1);
          console.log("servizi", servizi);
          this.setState({ servizi: servizi });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato ${servizio.nome} con successo`
          });
        } catch (error) {
          console.log("errore");
          notification.error({ title: "Errore", description: "Si è verificato un errore durante l'eliminazione del servizio" });
        }
      },
    });
  };

  _getNegozioById = (id_negozio) => {
    let res = this.state.negozi.find(n => n.id === id_negozio);
    return res;
  }

  _submitForm = async (values, { setSubmitting }) => {
    const { selected_service } = this.state;
    let servizi = [...this.state.servizi];

    let message, description;
    const {
      id_negozio,
      nome,
      descrizione,
      categoria,
      immagine,
      prezzo,
      prezzo_scontato
    } = values;

    const { img_to_delete } = this.state;

    const servizioRecord = {
      id_negozio: id_negozio,
      nome: nome,
      descrizione: descrizione ?? '',
      categoria: categoria,
      immagine: immagine ?? null,
      prezzo: prezzo,
      prezzo_scontato: prezzo_scontato ?? null,
    }

    try {
      this.setState({ loaded: false });
      let servizio;
      const negozio = this._getNegozioById(id_negozio);

      if (selected_service) {
        const { id } = selected_service;
        servizio = await aggiornaServizio(id, { ...servizioRecord, img_to_delete: img_to_delete });

        message = "Servizio aggiornato!";
        description = `Il servizio ${nome} è stato correttamente aggiornato`;

        const foundIndex = servizi.findIndex(x => x.id == id);
        servizi[foundIndex] = {
          id: id,
          ...servizioRecord,
          nome_negozio: negozio.nome_negozio,
          immagine: servizio.data.img_url ?? immagine
        };

        this.setState({
          servizi: servizi
        });
      } else {
        servizio = await creaServizio(servizioRecord);
        servizi.push({
          id: servizio.data.id_servizio,
          ...servizioRecord,
          immagine: servizio.data.img_url,
          nome_negozio: negozio.nome_negozio
        });

        this.setState({
          servizi: servizi
        });

        message = "Servizio creato";
        description = `Il servizio ${nome} è stato correttamente creato`;
      }

      this.setState({
        drawer_visible: false,
        selected_service: null,
        selected_index: null,
        categorieDisabled: true
      });

      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
      console.log("Success", servizio);
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      if (e.code.includes("aborted")) {
        notification.warn({
          message: 'Si è verificato un errore interno',
          description: e.details.dettaglio
        });
        this.setState({ drawer_visible: false });
      } else {
        notification.warn({
          message: "Errore",
          description: "Si è verificato un errore",
        });
      }
    } finally {
      this.setState({ loaded: true });
    }
  };
  //#endregion

  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null
    })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Servizio",
        dataIndex: "nome",
        key: "nome",
        width: 150,
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nome", "nome"),
        filteredValue: filteredInfo.nome || null,
        sortOrder: sortedInfo.columnKey === 'nome' && sortedInfo.order,
      },
      {
        title: 'Negozio',
        children: [
          {
            title: "ID",
            width: 70,
            dataIndex: "id_negozio",
            key: "id_negozio",
            sorter: (a, b) => a.id_negozio - b.id_negozio,
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("id_negozio", "id_negozio"),
            filteredValue: filteredInfo.nome || null,
            sortOrder: sortedInfo.columnKey === 'id_negozio' && sortedInfo.order,
          },
          {
            title: "Nome",
            width: 150,
            dataIndex: "nome_negozio",
            key: "nome_negozio",
            sorter: (a, b) => a.nome_negozio.localeCompare(b.nome_negozio),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nome_negozio", "nome_negozio"),
            filteredValue: filteredInfo.nome_negozio || null,
            sortOrder: sortedInfo.columnKey === 'nome_negozio' && sortedInfo.order,
          },
        ]
      },
      {
        title: "Categoria",
        width: 150,
        dataIndex: "categoria",
        key: "categoria",
        sorter: (a, b) => a.categoria.localeCompare(b.categoria),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("categoria", "categoria"),
        filteredValue: filteredInfo.categoria || null,
        sortOrder: sortedInfo.columnKey === 'categoria' && sortedInfo.order,
      },
      {
        title: "Prezzo",
        dataIndex: "prezzo",
        key: "prezzo",
        align: 'center',
        width: 80,
        sorter: (a, b) => a.prezzo - b.prezzo,
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          record.prezzo !== null
            ? record.prezzo === 0
              ? <Tag color='orange'>Nessuno</Tag>
              : <Statistic prefix={<span style={{ color: 'var(--darkGrey)' }}>€</span>} value={record.prezzo} precision={2} decimalSeparator=',' />
            : <Tag color='orange'>Nessuno</Tag>,
        filteredValue: filteredInfo.prezzo || null,
        sortOrder: sortedInfo.columnKey === 'prezzo' && sortedInfo.order,
      },
      {
        title: "Prezzo scontato",
        dataIndex: "prezzo_scontato",
        key: "prezzo_scontato",
        align: 'center',
        width: 80,
        sorter: (a, b) => a.prezzo_scontato - b.prezzo_scontato,
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          record.prezzo_scontato !== null
            ? record.prezzo_scontato === 0
              ? <Tag color='orange'>Nessuno</Tag>
              : <Statistic prefix={<span style={{ color: 'var(--darkGrey)' }}>€</span>} value={record.prezzo_scontato} precision={2} decimalSeparator=',' />
            : <Tag color='orange'>Nessuno</Tag>,
        filteredValue: filteredInfo.prezzo_scontato || null,
        sortOrder: sortedInfo.columnKey === 'prezzo_scontato' && sortedInfo.order,
      },
      {
        title: "Azioni",
        dataIndex: "settings",
        key: "settings",
        fixed: "right",
        align: "center",
        width: 100,
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <Col>
              <DeleteOutlined
                onClick={async () => {
                  this._eliminaServizio(record, index);
                }}
                style={{ color: Settings.colors.grey, fontSize: 18 }}
              />
            </Col>
            <Col>
              <EditOutlined
                style={{ color: Settings.colors.grey, fontSize: 18 }}
                onClick={async () => {
                  this.setState({
                    loaded: false
                  });
                  let categorieDb = await getCategorieServiziNegozio(record.id_negozio);
                  const { data } = await getServizio(record.id);
                  console.log(record.id);
                  console.log(data);
                  this.setState({
                    drawer_visible: true,
                    loaded: true,
                    categorieDisabled: false,
                    selected_service: data,
                    categorie: categorieDb.data[0].categorie_servizi
                      ? categorieDb.data[0].categorie_servizi.split(',')
                      : [],
                    selected_index: data.id,
                    scontato_visible: record.prezzo_scontato !== null ? true : false,
                  })
                }}
              />
            </Col>
          </Row>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  //#region Helpers Select
  onNameChange = (event) => {
    this.setState({
      nuova_categoria: event.target.value,
    });
  };

  addItem = async (id_negozio) => {
    try {
      let { categorie, nuova_categoria } = this.state;
      nuova_categoria = nuova_categoria.toLowerCase();
      const test = /^[A-zÀ-ú0-9]+(\s+[-a-zA-Z0-9-()]+)*$/gi;
      if (!test.test(nuova_categoria)) {
        notification.destroy();
        notification.warn({
          message: "Attenzione!",
          description: "Puoi solo inserire lettere e numeri, senza spazi vuori iniziali/finali"
        });
        this.setState({
          isAdding: false
        });
      }
      else if (categorie.includes(nuova_categoria)) {
        notification.destroy();
        notification.warn({
          message: "Attenzione!",
          description: "Hai inserito una categoria già esistente"
        });
        this.setState({
          nuova_categoria: '',
          isAdding: false
        });
      } else {
        //Creo nuovo array categorie
        let new_categorie = [...categorie, nuova_categoria];
        //Aggiorno le categorie del negozio nel db
        new_categorie = new_categorie.join(",");
        let categorieDb = await setCategorieServiziNegozio(id_negozio, new_categorie);

        this.setState({
          categorie: new_categorie.split(',') || `Nuova categoria`,
          nuova_categoria: "",
          isAdding: false
        });
      }
    } catch (error) {
      message.error("Errore durante l'aggiunta della categoria");
    }
  };
  //#endregion

  //#region Lifecycle componente
  _loader = async () => {

    try {
      let servizi = await getServiziWithNegozio();
      let negozi = await getNegoziWithNomeCategorie();

      this.setState({
        ...this.state,
        servizi: servizi.data,
        negozi: negozi.data,
        loaded: true
      });
    } catch (error) {
      message.error("Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.");
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_service, loaded, scontato_visible } = this.state;
    const {
      id_negozio,
      nome_negozio,
      nome,
      descrizione,
      categoria,
      immagine,
      prezzo,
      prezzo_scontato
    } = selected_service || {};

    const initialValues = {
      id_negozio: id_negozio ?? null,
      nome_negozio: nome_negozio ?? null,
      nome: nome ?? '',
      descrizione: descrizione ?? '',
      categoria: categoria ?? '',
      immagine: immagine ?? null,
      prezzo: prezzo ?? 0,
      prezzo_scontato: scontato_visible ? (prezzo_scontato ?? 0) : null
    }
    return (
      <>
        <Wrapper>
          <PageHeader
            title="Servizi"
            description="In questa sezione ci sono tutti i servizi" />
          <Divider />
          <Row align='middle' justify='end' gutter={[15, 15]}>
            <Col>
              <Button
                size='large'
                onClick={this._clearFiltri}
              >
                Reset Filtri
            </Button>
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => this.setState({ drawer_visible: true })}
                type="primary"
                size='large'
              >
                Aggiungi servizio
              </Button>
            </Col>

          </Row>
          <Table
            bordered
            dataSource={this.state.servizi}
            columns={this._getColumns()}
            onChange={this._handleChange}
            size="small"
            scroll={{ x: 576, y: 400 }}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100", "200"] }}
          />

          {/*Drawer creazione/modifica servizio*/}
          <Drawer
            destroyOnClose
            placement="right"
            closable={true}
            onClose={() => this.setState({
              drawer_visible: false,
              scontato_visible: false,
              selected_service: null,
              selected_index: null,
              categorieDisabled: true
            })}
            visible={this.state.drawer_visible}
            width="100%"
            closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
          >
            <Row justify='start' align='middle' gutter={[15, 15]}>
              <Col xs={24}>
                <PageHeader
                  title={selected_service
                    ? "Modifica servizio"
                    : "Aggiungi servizio"}
                  description={selected_service
                    ? `Aggiorna i dettagli del servizio: ${selected_service.nome}`
                    : "Aggiungi un nuovo servizio."}
                />
              </Col>
            </Row>
            <Divider />
            <Formik
              initialValues={initialValues}
              validationSchema={selected_service ? validationSchemaAggiornaServizio : validationSchemaAggiungiServizio}
              onSubmit={this._submitForm}
            >
              {({ touched, errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => (
                <Form>
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Dati principali" />
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      {selected_service
                        ? <>
                          <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Negozio:</span>
                          <Statistic value={`${values.id_negozio} - ${values.nome_negozio}`} />
                        </>
                        : <>
                          <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Negozio</span>
                          <Select
                            onChange={async (value) => {
                              this.setState({ loaded: false });
                              setFieldValue("id_negozio", value)
                              setFieldValue("categoria", null)
                              let categorieDb;
                              try {
                                categorieDb = await getCategorieServiziNegozio(value);
                                this.setState({
                                  categorie: categorieDb.data[0].categorie_servizi
                                    ? categorieDb.data[0].categorie_servizi.split(',')
                                    : [],
                                  categorieDisabled: false
                                });
                              } catch (error) {
                                notification.destroy();
                                notification.error({
                                  message: 'Errore',
                                  description: 'Errore nell\'ottenimento delle categorie del negozio, contattare l\'assistenza'
                                });
                              }
                              this.setState({ loaded: true });
                            }}
                            size="default"
                            style={{ width: "100%" }}
                            onBlur={handleBlur("id_negozio")}
                            placeholder="Seleziona un negozio..."
                          >
                            {this.state.negozi.map(({ id, nome_negozio }) => (
                              <Option value={id}>{id} - {nome_negozio}</Option>
                            ))}
                          </Select>
                          <div className="input-error">{touched.id_negozio && errors.id_negozio}</div>
                        </>
                      }
                    </Col>
                    <Col xs={24} sm={24} md={9}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Nome servizio</span>
                      <Input
                        value={values.nome}
                        onChange={handleChange("nome")}
                        placeholder="Inserisci nome servizio..."
                        onBlur={handleBlur("nome")}
                      />
                      <div className="input-error">{touched.nome && errors.nome}</div>
                    </Col>
                    <Col xs={24} sm={24} md={9}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Categoria servizio</span>
                      <Select
                        onChange={(value) => { setFieldValue("categoria", value) }}
                        disabled={this.state.categorieDisabled}
                        size="default"
                        style={{ width: "100%" }}
                        value={values.categoria}
                        optionLabelProp="label"
                        onBlur={handleBlur("categoria")}
                        placeholder="Inserisci una categoria"
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "10px 0" }} />
                            <Row justify='center' align='middle' style={{ padding: '0 5px' }}>
                              <Col span={24} style={{ marginBottom: '5px' }}>
                                <Input placeholder="Inserisci nome nuova categoria..." style={{ flex: "auto" }} value={this.state.nuova_categoria} onChange={this.onNameChange} />
                              </Col>
                              <Col span={24}>
                                <Button
                                  style={{ width: '100%' }}
                                  type='primary'
                                  size='large'
                                  disabled={this.state.isAdding || this.state.nuova_categoria === ''}
                                  icon={<PlusOutlined />}
                                  onClick={this.state.nuova_categoria
                                    ? () => {
                                      this.setState({ isAdding: true });
                                      this.addItem(values.id_negozio);
                                    }
                                    : null}
                                >
                                  Aggiungi nuova
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      >
                        <Option key="" value="" label="Nessuna Categoria">-Nessuna Categoria-</Option>
                        {this.state.categorie ? this.state.categorie.map((item) => (<Option key={item} value={item} label={item}>
                          <Row justify="space-between" align="middle">

                            <Col span={20}>{item}</Col>
                            <Col span={2}><EditOutlined style={{ color: Settings.colors.grey }} onClick={() => {
                              this.setState({ categoria_edit: item });
                              Modal.confirm({
                                title: `Stai modificando la categoria "${item}"`,
                                icon: <InfoCircleOutlined />,
                                content: <Row justify="start" align="middle" gutter={[0, 15]}>
                                  <Col span={24}>Sei sicuro di voler modificare la categoria "{item}"? Facendolo verranno reimpostate le categorie dei prodotti che prima ne erano provvisti.
                                    </Col>
                                  <Col span={24}>
                                    <Input
                                      onChange={(value) => {
                                        handleChange("categoria_editing")
                                        this.setState({ categoria_edit: value.target.value })
                                      }}
                                      onBlur={handleBlur("categoria_editing")}
                                      value={values.categoria_editing} />
                                  </Col>
                                </Row>,
                                onOk: async () => {
                                  const { categorie, categoria_edit, servizi } = this.state;
                                  let categorie_negozio = [...categorie];
                                  let servizi_edit = [...servizi];
                                  const test = /^[A-zÀ-ú0-9]+(\s+[-a-zA-Z0-9-()]+)*$/gi;
                                  if (!test.test(categoria_edit)) {
                                    notification.destroy();
                                    notification.warn({
                                      message: "Attenzione!",
                                      description: "Puoi solo inserire lettere e numeri, senza spazi vuoti iniziali/finali"
                                    });
                                  }
                                  else if (categorie.includes(categoria_edit)) {
                                    notification.destroy();
                                    notification.warn({
                                      message: "Attenzione!",
                                      description: "Hai inserito una categoria già esistente, riprova"
                                    });
                                  }
                                  else {
                                    categorie_negozio[categorie_negozio.findIndex(i => i === item)] = categoria_edit.toLowerCase();
                                    try {
                                      await setCategorieServiziNegozio(id_negozio, categorie_negozio.join(','));
                                      await setCategoriaServizi(id_negozio,
                                        item, categoria_edit);
                                    }
                                    catch (err) {
                                      notification.destroy();
                                      notification.error({
                                        message: "Errore!",
                                        description: "Non è stato possibile completare la richiesta"
                                      });
                                    }
                                    servizi_edit
                                      .filter(i => i.categoria === item)
                                      .map(i => i.categoria = categoria_edit);
                                    setFieldValue("categoria", '')
                                    this.setState({
                                      categorie: categorie_negozio,
                                      categoria_edit: '',
                                      servizi: servizi_edit
                                    })
                                  }
                                },
                                onCancel: () => {
                                  this.setState({ categoria_edit: '' });
                                },
                                okText: 'Conferma',
                                cancelText: 'Annulla',
                              });
                            }} />
                            </Col>
                            <Col span={2}><DeleteOutlined style={{ color: Settings.colors.red }} onClick={() => Modal.confirm({
                              title: `Stai eliminando la categoria "${item}"`,
                              icon: <ExclamationCircleOutlined />,
                              content: `Sei sicuro di voler eliminare la categoria ${item}? Facendolo verranno eliminate le categorie dei servizi che prima ne erano provvisti.`,
                              okText: 'Conferma',
                              cancelText: 'Annulla',
                              onOk: async () => {
                                const { categorie, servizi } = this.state;
                                let categorie_negozio = categorie.filter(i => i !== item);
                                let servizi_edit = [...servizi];
                                try {
                                  await setCategorieServiziNegozio(id_negozio, categorie_negozio.join(','));
                                  await setCategoriaServizi(id_negozio, item, '');
                                }
                                catch (err) {
                                  notification.destroy();
                                  notification.error({
                                    message: "Errore!",
                                    description: "Non è stato possibile completare la richiesta"
                                  });
                                }
                                servizi_edit
                                  .filter(i => i.categoria === item)
                                  .map(i => i.categoria = '');
                                setFieldValue("categoria", '')
                                this.setState({
                                  categorie: categorie_negozio,
                                  servizi: servizi_edit
                                })
                              }
                            })} />
                            </Col>

                          </Row>
                        </Option>)) : ''}
                      </Select>
                      <div className="input-error">{touched.categoria && errors.categoria}</div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Immagine (opzionale)</span>
                      <div style={{ width: "100%" }}>
                        <ImageCropper
                          cropSize={{ width: 400, height: 300 }}
                          aspect={4 / 3}
                          image={
                            values.immagine ? values.immagine : null
                          }
                          onCropSuccess={blobUrl => setFieldValue("immagine", blobUrl)}
                          onChange={handleChange("immagine")}
                        />
                      </div>
                      <div className="input-error">{touched.immagine && errors.immagine}</div>
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                      <span style={{ color: Settings.colors.darkGrey }}>Descrizione (opzionale)</span>
                      <TextArea
                        value={values.descrizione}
                        onChange={handleChange("descrizione")}
                        placeholder="Inserisci una descrizione per il servizio"
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        onBlur={handleBlur("descrizione")}
                        style={{ height: 180 }}
                      />
                      <div className="input-error">{touched.descrizione && errors.descrizione}</div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row align='top' justify='start' gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Prezzi" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        <Space>
                          Prezzo
                                  <Popover
                            content={
                              <div className="black-popover">
                                Se inserisci '0' il servizio sarà GRATUITO
                            </div>
                            }
                          >
                            <QuestionCircleFilled />
                          </Popover>
                        </Space>
                      </span>
                      <Row justify='space-between' align='top' gutter={10}>
                        <Col span={23}>
                          <InputNumber
                            style={{ flex: "auto", width: "100%" }}
                            min={0}
                            max={99999}
                            step={0.01}
                            defaultValue={initialValues.prezzo ?? 0}
                            decimalSeparator=','
                            precision={2}
                            onChange={(value) => {
                              setFieldValue("prezzo", value);
                            }}
                            onBlur={handleBlur("prezzo")}
                          />
                        </Col>
                        <Col span={1}>
                          <span>€</span>
                        </Col>
                      </Row>
                      <div className="input-error">{touched.prezzo && errors.prezzo}</div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Checkbox
                        style={{ color: Settings.colors.darkGrey }}
                        defaultChecked={values.prezzo_scontato !== null}
                        onChange={(value) => {
                          const { checked } = value.target;
                          this.setState({
                            scontato_visible: checked
                          });
                          if (checked)
                            setFieldValue("prezzo_scontato", 0)
                          else
                            setFieldValue("prezzo_scontato", null)
                        }}>
                        <Space>
                          Prezzo Scontato (opzionale)
                      <Popover
                            content={
                              <div className="black-popover">
                                Se inserisci '0' il servizio sarà GRATUITO
                          </div>
                            }>
                            <QuestionCircleFilled />
                          </Popover>
                        </Space>
                      </Checkbox>
                      {
                        this.state.scontato_visible || values.prezzo_scontato !== null
                          ? <><Row justify='space-between' align='top' gutter={10}>
                            <Col span={23}>
                              <InputNumber
                                style={{ flex: "auto", width: "100%" }}
                                min={0}
                                max={99999}
                                step={0.01}
                                defaultValue={initialValues.prezzo_scontato}
                                decimalSeparator=','
                                precision={2}
                                onChange={(value) => { setFieldValue("prezzo_scontato", value) }}
                                onBlur={handleBlur("prezzo_scontato")}
                              />
                            </Col>
                            <Col span={1}>
                              <span>€</span>
                            </Col>
                          </Row>
                            <div className="input-error">{touched.prezzo_scontato && errors.prezzo_scontato}</div>
                          </>
                          : null
                      }
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Button
                        loading={isSubmitting}
                        size='large'
                        disabled={!dirty || isSubmitting}
                        icon={selected_service ? <EditOutlined /> : <PlusOutlined />}
                        block={true}
                        size="large"
                        onClick={() => {
                          if (!isValid)
                            notification.error({
                              message: 'Errore',
                              description: 'Ricontrolla i dati inseriti'
                            });
                          handleSubmit();
                        }}
                        type="primary"
                      >
                        {this.state.selected_service ? <span>Aggiorna</span> : <span>Aggiungi</span>}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Drawer>
          {/*End Drawer creazione/modifica servizio*/}
        </ Wrapper>
        <Loading loading={!loaded} />
      </>
    )
  }
  //#endregion
}

export default Servizi;
