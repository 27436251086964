import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React, { Component } from "react";
import * as Yup from "yup";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getUtenti } from "../Controllers/Utenti";
import moment from "moment";
import "moment/locale/it";
import locale from "antd/es/date-picker/locale/it_IT";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Settings from "../Config/Settings";
import { getNegozioFirebaseInfo } from "../Controllers/Negozi";
import {
  getAppuntamentiNegozio,
  eliminaCategoriaAppuntamenti,
  getAppuntamento,
  getCategorieAppuntamenti,
  postAppuntamento,
  updateAppuntamento,
  eliminaAppuntamento,
} from "../Controllers/Appuntamenti";
import Loading from "./Loading";
import PageHeader from "./PageHeader";
import { Form, Formik } from "formik";

import { TwitterPicker } from "react-color";

const { Option } = Select;
class DrawerAppuntamenti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appuntamenti: [],
      categorie_negozio: [],
      modify: false,
      utenti: [],
    };
  }

  onNameChange = (args) => {
    const { value } = args.target;
    this.setState({ nuova_categoria: value });
  };

  _getAppuntamentiNegozio = async () => {
    this.setState({ loaded: false });
    try {
      const utenteFirebase = await getNegozioFirebaseInfo(
        this.props.telefono_referente
      );
      const { data } = await getAppuntamentiNegozio(utenteFirebase.data.uid);
      const categorieData = await getCategorieAppuntamenti(
        utenteFirebase.data.uid
      );
      let categorie = [];
      for (let i = 0; i < categorieData.data.length; i++) {
        if (
          categorieData.data[i].categoria !== null &&
          categorieData.data[i].categoria !== "null"
        ) {
          let cat = JSON.parse(categorieData.data[i].categoria);
          let object = {
            titolo: cat.titolo,
            colore: cat.colore,
          };
          categorie.push(object);
        }
      }
      // let cat = [];
      // res.data.forEach((element) => {
      //   if (element.categoria) {
      //     cat.push({
      //       colore: JSON.parse(element.categoria).colore,
      //       titolo: JSON.parse(element.categoria).titolo,
      //     });
      //   }
      // });
      const utenti = await getUtenti();
      let users = [];
      for (let i = 0; i < utenti.data.length; i++) {
        const utenteRecord = {
          nome: utenti.data[i].nome + " " + utenti.data[i].cognome,
          uid: utenti.data[i].id_utenza_firebase,
        };
        users.push(utenteRecord);
      }
      this.setState({
        utenti: users,
        categorie_negozio: categorie,
        drawerAppuntamentiVisible: true,
        currentUtenteFirebase: utenteFirebase.data,
        appuntamenti: data.map(({ id, inizio, fine, titolo, categoria }) => ({
          id: id,
          start: moment(inizio, "DD/MM/YYYY HH:mm").toDate(),
          end: moment(fine, "DD/MM/YYYY HH:mm").toDate(),
          title: titolo,
          categoria: categoria,
        })),
      });
    } catch (error) {
      console.error(error);
      notification.destroy();
      notification.error({
        message: "Errore",
        description: error,
      });
    } finally {
      this.setState({ loaded: true });
    }
  };

  _aggiungiAppuntamento = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let categoriaObject = null;
      if (values.categoria) {
        for (let i = 0; i < this.state.categorie_negozio.length; i++) {
          if (values.categoria === this.state.categorie_negozio[i].titolo) {
            categoriaObject = this.state.categorie_negozio[i];
            break;
          }
        }
      }

      if (this.state.modify) {
        const record = {
          id: this.state.selected_appuntamento.id,
          titolo: values.titolo,
          inizio: moment(values.start, "DD/MM/YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          fine: moment(values.end, "DD/MM/YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          uidCliente: values.utente,
          uidNegozio: this.state.currentUtenteFirebase.uid,
          descrizione: values.descrizione,
          categoria: categoriaObject ? JSON.stringify(categoriaObject) : null,
        };
        await updateAppuntamento(record);
      } else {
        const record = {
          titolo: values.titolo,
          inizio: moment(values.start, "DD/MM/YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          fine: moment(values.end, "DD/MM/YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          uidNegozio: this.state.currentUtenteFirebase.uid,
          uidCliente: values.utente,
          descrizione: values.descrizione,
          categoria: categoriaObject ? JSON.stringify(categoriaObject) : null,
        };
        await postAppuntamento(record);
      }

      // const {data} = await postAppuntamento()
    } catch (error) {
      notification.destroy();
      notification.error({
        title: "Errore.",
        message: "C'è stato un errore nell'esecuzione dell'operazione",
      });
      console.log(error);
    } finally {
      this._loader();
      this.setState({
        modal_visible: false,
        selected_appuntamento: null,
        modify: false,
      });
      setSubmitting(false);
    }
  };

  _loader = async () => {
    this._getAppuntamentiNegozio();
  };

  componentDidMount() {
    this._loader();
  }

  _localizer = momentLocalizer(moment);

  eventStyleGetter = (event, start, end, isSelected) => {
    let style = {};
    let categoria;
    if (event.categoria !== null && event.categoria !== "null") {
      categoria = JSON.parse(event.categoria);
      style = {
        backgroundColor: categoria.colore,
      };
    } else {
      style = {
        backgroundColor: Settings.colors.grey,
      };
    }
    return { style: style };
  };

  render() {
    const { selected_appuntamento } = this.state;
    const {
      titolo,
      descrizione,
      inizio,
      fine,
      categoria,
      uid_utente_firebase,
    } = selected_appuntamento || {};

    const initialValues = {
      categoria: categoria ? JSON.parse(categoria).titolo : null,
      titolo: titolo ?? "",
      descrizione: descrizione ?? "",
      start: inizio ? moment(inizio, "DD/MM/YYYY HH:mm") : moment(),
      end: fine ? moment(fine, "DD/MM/YYYY HH:mm") : moment(),
      utente: uid_utente_firebase ?? "",
    };

    return (
      <>
        <Drawer
          destroyOnClose
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({ modify: false });
            this.props.closeDrawer();
          }}
          visible={this.props.drawerVisible}
          width="100%"
          closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
        >
          <Row>
            <Col span={24}>
              <PageHeader
                title="Appuntamenti Negozio"
                description={
                  this.state.currentUtenteFirebase
                    ? `${this.state.currentUtenteFirebase.uid}`
                    : null
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="end" style={{ marginBottom: "25px" }}>
            <Col>
              <Button
                size="large"
                icon={<PlusOutlined />}
                onClick={() => this.setState({ modal_visible: true })}
                type="primary"
              >
                Aggiungi appuntamento
              </Button>
              {/*Modal richiedi appuntamento*/}
              <Modal
                destroyOnClose
                footer={null}
                onCancel={() => {
                  this.setState({
                    modal_visible: false,
                    selected_appuntamento: null,
                    modify: false,
                  });
                }}
                transitionName="zoom"
                title="Aggiungi Appuntamento"
                visible={this.state.modal_visible}
              >
                <Row justify="space-between" align="middle" gutter={[15, 15]}>
                  <Col span={24}>
                    <Formik
                      validationSchema={Yup.object().shape({
                        titolo: Yup.string().required("Il titolo è richiesto"),
                        start: Yup.string()
                          .nullable()
                          .required("La data di inizio è richiesta"),
                        end: Yup.string()
                          .nullable()
                          .required("La data di fine è richiesta"),
                        descrizione: Yup.string().max(
                          500,
                          "descrizione > 500 caratteri"
                        ),
                      })}
                      initialValues={initialValues}
                      validateOnMount={false}
                      validateOnChange={true}
                      onSubmit={this._aggiungiAppuntamento}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form>
                          <Row
                            justify="center"
                            align="middle"
                            gutter={[15, 15]}
                          >
                            <Col span={24}>
                              <span
                                style={{
                                  marginBottom: 10,
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                Titolo
                              </span>
                              <Input
                                value={values.titolo}
                                onChange={handleChange("titolo")}
                                placeholder="Inserisci titolo appuntamento..."
                                onBlur={handleBlur("titolo")}
                              />
                              <div style={styles.inputError}>
                                {touched.titolo && errors.titolo}
                              </div>
                            </Col>
                            <Col span={24}>
                              <span
                                style={{
                                  marginBottom: 10,
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                Data Inizio e Fine
                              </span>
                              <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={(date) => date < moment()}
                                locale={locale}
                                style={{ width: "100%" }}
                                showTime={{ format: "HH:mm" }}
                                format="DD/MM/YYYY HH:mm"
                                defaultValue={[
                                  moment(values.start, "DD/MM/YYYY HH:mm"),
                                  moment(values.end, "DD/MM/YYYY HH:mm"),
                                ]}
                                onChange={(value, dateString) => {
                                  setFieldValue("start", dateString[0]);
                                  setFieldValue("end", dateString[1]);
                                }}
                              />
                              <span style={styles.inputError}>
                                {touched.start && errors.start}
                              </span>
                            </Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col span={24}>
                              <span
                                style={{
                                  marginBottom: 10,
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                Categoria Appuntamento
                              </span>
                              <Select
                                onChange={(value) => {
                                  setFieldValue("categoria", value);
                                }}
                                style={{ width: "100%" }}
                                value={values.categoria}
                                optionLabelProp="label"
                                onBlur={handleBlur("categoria")}
                                placeholder="Inserisci una categoria"
                                dropdownRender={(menu) => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "10px 0" }} />
                                    <Row
                                      justify="space-between"
                                      align="middle"
                                      style={{ padding: "0 5px" }}
                                    >
                                      <Col
                                        span={16}
                                        style={{ marginBottom: "5px" }}
                                      >
                                        <Input
                                          placeholder="Inserisci nome nuova categoria..."
                                          style={{ flex: "auto" }}
                                          value={this.state.nuova_categoria}
                                          onChange={this.onNameChange}
                                        />
                                      </Col>
                                      <Col span={7}>
                                        <Input
                                          style={{
                                            marginBottom: "5px",
                                            backgroundColor: this.state
                                              .nuova_categoria_color,
                                          }}
                                          value={
                                            this.state.nuova_categoria_color
                                          }
                                          onClick={() => {
                                            this.setState({
                                              pickerVisible: true,
                                            });
                                          }}
                                        ></Input>
                                      </Col>
                                      <Row align="middle" justify="end">
                                        <Col span={12}>
                                          {this.state.pickerVisible ? (
                                            <TwitterPicker
                                              onChangeComplete={(color) => {
                                                this.setState({
                                                  nuova_categoria_color:
                                                    color.hex,
                                                  pickerVisible: false,
                                                });
                                              }}
                                              triangle="top-right"
                                            />
                                          ) : null}
                                        </Col>
                                      </Row>

                                      <Col span={24}>
                                        <Button
                                          style={{ width: "100%" }}
                                          type="primary"
                                          disabled={
                                            this.state.isAdding ||
                                            this.state.nuova_categoria === ""
                                          }
                                          icon={<PlusOutlined />}
                                          onClick={
                                            this.state.nuova_categoria
                                              ? () => {
                                                  let categorie = this.state
                                                    .categorie_negozio;
                                                  let nuovaCat = {
                                                    titolo: this.state
                                                      .nuova_categoria,
                                                    colore: this.state
                                                      .nuova_categoria_color,
                                                  };
                                                  categorie.push(nuovaCat);
                                                  this.setState({
                                                    categorie_negozio: categorie,
                                                    categoriaSelezionata: nuovaCat,
                                                    nuova_categoria: "",
                                                  });
                                                  console.log(
                                                    this.state.categorie_negozio
                                                  );
                                                }
                                              : null
                                          }
                                        >
                                          Aggiungi nuova
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              >
                                <Select.Option
                                  value={null}
                                  label="Nessuna Categoria"
                                >
                                  -Nessuna Categoria-
                                </Select.Option>
                                {this.state.categorie_negozio
                                  ? this.state.categorie_negozio.map((item) =>
                                      item !== null && item !== "null" ? (
                                        <Select.Option
                                          key={item.titolo}
                                          value={item.titolo}
                                          label={item.titolo}
                                        >
                                          <Row
                                            justify="space-between"
                                            align="middle"
                                          >
                                            <Col span={16}>{item.titolo}</Col>
                                            <Col>
                                              <div
                                                style={{
                                                  width: "50px",
                                                  height: "20px",
                                                  backgroundColor: item.colore,
                                                  borderRadius: "14px",
                                                }}
                                              >
                                                {" "}
                                              </div>
                                            </Col>

                                            <Col>
                                              <DeleteOutlined
                                                style={{
                                                  color: Settings.colors.red,
                                                }}
                                                onClick={() =>
                                                  Modal.confirm({
                                                    title: `Stai eliminando la categoria "${item.titolo}"`,
                                                    icon: (
                                                      <ExclamationCircleOutlined />
                                                    ),
                                                    content: `Sei sicuro di voler eliminare la categoria ${item.titolo}? Facendolo verranno eliminate le categorie degli appuntamenti che prima ne erano provvisti.`,
                                                    okText: "Conferma",
                                                    cancelText: "Annulla",
                                                    onOk: async () => {
                                                      const {
                                                        categorie_negozio,
                                                        appuntamenti,
                                                      } = this.state;
                                                      let categorie = categorie_negozio.filter(
                                                        (i) => i !== item
                                                      );
                                                      let appuntamenti_edit = [
                                                        ...appuntamenti,
                                                      ];
                                                      try {
                                                        await eliminaCategoriaAppuntamenti(
                                                          this.state
                                                            .currentUtenteFirebase
                                                            .uid,
                                                          JSON.stringify(item)
                                                        );
                                                      } catch (err) {
                                                        notification.destroy();
                                                        notification.error({
                                                          message: "Errore!",
                                                          description:
                                                            "Non è stato possibile completare la richiesta",
                                                        });
                                                      }
                                                      appuntamenti_edit
                                                        .filter(
                                                          (i) =>
                                                            i.categoria === item
                                                        )
                                                        .map(
                                                          (i) =>
                                                            (i.categoria = "")
                                                        );
                                                      setFieldValue(
                                                        "categoria",
                                                        null
                                                      );
                                                      this.setState({
                                                        categorie_negozio: categorie,
                                                        appuntamenti: appuntamenti_edit,
                                                      });
                                                    },
                                                  })
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Select.Option>
                                      ) : null
                                    )
                                  : ""}
                              </Select>
                              <div style={styles.inputError}>
                                {touched.categoria && errors.categoria}
                              </div>
                            </Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col span={24}>
                              <span
                                style={{
                                  color: Settings.colors.darkGrey,
                                }}
                              >
                                Descrizione
                              </span>
                              <Input.TextArea
                                rows={6}
                                placeholder="Inserisci la descrizione dell'appuntamento..."
                                value={values.descrizione}
                                onChange={handleChange("descrizione")}
                                onBlur={handleBlur("descrizione")}
                                style={{ marginTop: "10px" }}
                              />
                              <span style={styles.inputError}>
                                {touched.descrizione && errors.descrizione}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <span style={{ color: Settings.colors.darkGrey }}>
                                Collega un cliente
                              </span>
                              <Select
                                onChange={(value) => {
                                  setFieldValue("utente", value);
                                  console.log("utente", value);
                                }}
                                style={{ width: "100%" }}
                                value={values.utente}
                                optionLabelProp="label"
                                onBlur={handleBlur("utente")}
                                placeholder="Seleziona un cliente"
                              >
                                <Option value={null} label="Nessun cliente">
                                  -Nessun Cliente-
                                </Option>
                                {this.state.utenti !== []
                                  ? this.state.utenti.map((item) => (
                                      <Option
                                        key={item.uid}
                                        value={item.uid}
                                        label={item.nome}
                                      >
                                        <span>{item.nome}</span>
                                      </Option>
                                    ))
                                  : null}
                              </Select>
                            </Col>
                          </Row>
                          <Divider />
                          <Row justify="space-around">
                            <Col span={24}>
                              <Button
                                block
                                size="large"
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={handleSubmit}
                                loading={isSubmitting}
                                disabled={!(isValid && dirty) || isSubmitting}
                              >
                                {isSubmitting ? "Attendi..." : "Invia"}
                              </Button>
                            </Col>
                            {this.state.modify ? (
                              <Col span={24} style={{ marginTop: "25px" }}>
                                <Button
                                  style={{
                                    backgroundColor: Settings.colors.white,
                                    color: Settings.colors.red,
                                  }}
                                  size="large"
                                  block={true}
                                  size="large"
                                  onClick={() => {
                                    Modal.confirm({
                                      title: `Stai eliminando l'appuntamento' "${this.state.selected_appuntamento.titolo}"`,
                                      icon: <ExclamationCircleOutlined />,
                                      content: `Sei sicuro di voler eliminare l'appuntamento "${this.state.selected_appuntamento.titolo}"?`,
                                      okText: "Conferma",
                                      cancelText: "Annulla",
                                      onOk: async () => {
                                        try {
                                          await eliminaAppuntamento(
                                            this.state.selected_appuntamento.id
                                          );
                                          notification.destroy();
                                          notification.success({
                                            message: "Perfetto!",
                                            description:
                                              "L'appuntamento è stato eliminato.",
                                          });
                                        } catch (err) {
                                          notification.destroy();
                                          notification.error({
                                            message: "Errore!",
                                            description:
                                              "Non è stato possibile completare la richiesta",
                                          });
                                        }

                                        this.setState({
                                          selected_appuntamento: null,
                                          modal_visible: false,
                                          modify: false,
                                        });
                                        this._loader();
                                      },
                                    });
                                  }}
                                >
                                  Elimina appuntamento
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Modal>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Calendar
                localizer={this._localizer}
                events={this.state.appuntamenti}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={this.eventStyleGetter}
                onSelectEvent={async (event) => {
                  this.setState({ loaded: false, modify: true });
                  try {
                    const appuntamento = await (await getAppuntamento(event.id))
                      .data;
                    this.setState({
                      selected_appuntamento: appuntamento,
                      modal_visible: true,
                    });
                  } catch (error) {
                    console.log(error);
                    notification.destroy();
                    notification.error({
                      message: "Errore",
                      description: error,
                    });
                  } finally {
                    this.setState({ loaded: true });
                  }
                }}
                style={{ height: 700 }}
                messages={{
                  next: "Avanti",
                  previous: "Indietro",
                  today: "Oggi",
                  month: "Mese",
                  week: "Settimana",
                  day: "Giorno",
                  allDay: "Tutto il giorno",
                  agenda: "Agenda",
                  date: "Data",
                  time: "Orario",
                  event: "Evento",
                  showMore: (target) => (
                    <span className="ml-2" role="presentation">
                      {" "}
                      + {target} altri...
                    </span>
                  ),
                }}
              />
            </Col>
          </Row>
        </Drawer>
        <Loading loading={!this.state.loaded} />
      </>
    );
  }
}

const styles = {
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

export default DrawerAppuntamenti;
