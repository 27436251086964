import React from "react";

//import antd
import { Row, Col, Button, Input, Modal, Drawer, Select, Divider, Upload, Table, Space, notification, message, Tag, Checkbox, Popover } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

//import components and styles
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import Label from "../Components/Label"

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaAggiungiComune, validationSchemaAggiornaComune } from "../Config/Validation";

// import Registrazione controller functions
import {
  getComuni,
  registraComune,
  aggiornaComune,
  eliminaComune,
  getComune,
  getComuneFirebaseInfo
} from "../Controllers/Comuni";
import ImageCropper from "../Components/ImageCropper";
import Loading from "../Components/Loading";
import { inviaConfermaMailComune, logout, resendEmailVerification, resetPasswordComune, updateEmailComune, updateUserEmail } from "../Controllers/Auth";

//Costanti
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const comuni_json = require('../Assets/comuni.json')

class Comuni extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,

      drawer_visible: false,
      cambia_mail_visible: false,
      new_mail: '',

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,

      selected_comune: null,
      selected_index: null,

      selected_citta_caps: [],

      comuni: []
    };
  }

  //#region Helpers Gestione Comuni
  _eliminaComune = (comune, indice) => {
    confirm({
      title: "Elimina comune",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare il comune: ${comune.nome}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo comune dal db
          const comune_eliminato = await eliminaComune(comune.id);
          //Rimuovo comune nello stato
          let comuni = [...this.state.comuni];
          comuni.splice(indice, 1);
          this.setState({ comuni: comuni });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato il comune di ${comune.nome} con successo`
          });
        } catch (error) {
          console.log("errore");
          notification.error({ message: "Errore", description: "Si è verificato un errore durante l'eliminazione del comune" });
        }
      },
    });
  };

  _submitForm = async (values, { setSubmitting }) => {
    const { selected_comune } = this.state;
    const comuni = [...this.state.comuni];

    try {
      let message = "Comune aggiornato";
      let description = "Il comune è stato correttamente aggiornato";

      const {
        abilitato,
        userMail,
        password,
        indirizzo,
        civico,
        provincia,
        cap,
        citta,
        logo,
        nome,
        note,
        partita_iva,
        slug,
        telefono,
        email,
        comuni_partecipanti
      } = values;

      let comuneRecord = {
        abilitato: abilitato,
        indirizzo: indirizzo,
        civico: civico,
        provincia: provincia,
        cap: cap,
        citta: citta,
        logo: logo,
        nome: nome,
        note: note ?? '',
        partita_iva: partita_iva,
        slug: slug,
        telefono: telefono.substring(0, 1) != '+' ? '+' + telefono : telefono,
        email: email ?? '',
        comuni_partecipanti: comuni_partecipanti ?? []
      }

      let comune;
      if (selected_comune) {
        const { id } = selected_comune;
        comune = await aggiornaComune(+id, comuneRecord);

        message = "Comune/Distretto/ecc... aggiornato!";
        description = `Il Comune/Distretto/ecc... ${nome} è stato correttamente aggiornato!`;

        const foundIndex = comuni.findIndex(x => x.id == id);
        comuni[foundIndex] = {
          id: id,
          ...comuneRecord,
          img_url: comune.data.logo ?? logo
        };

        this.setState({
          comuni: comuni
        });
      } else {

        comune = await registraComune({
          userMail: userMail,
          password: password,
          ...comuneRecord
        });

        comuni.push({
          id: comune.data.id,
          ...comuneRecord
        });
        this.setState({
          comuni: comuni
        });
        message = "Comune/Distretto/ecc... creato";
        description = `Il Comune/Distretto/ecc... ${nome} è stato correttamente creato`;
      }

      this.setState({
        drawer_visible: false,
        selected_index: null,
        selected_comune: null
      });

      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      if (e.code.includes("aborted")) {
        notification.warn({
          message: 'Si è verificato un errore interno',
          description: e.details.dettaglio
        });
        this.setState({ drawer_visible: false });
      }
      if (e.message.includes('phone')) {
        notification.error({
          message: 'Si è verificato un errore sul numero di telefono specificato',
          description: e.message.includes('exists') ? 'Il telefono inserito è già presente' : 'Il telefono inserito non è valido'
        });
      }
      if (e.message.includes('mail')) {
        notification.error({
          message: 'Si è verificato un errore sull\'indirizzo mail specificato',
          description: e.message.includes('in use') ? 'La mail inserita è già presente' : 'La mail inserita non è valida'
        });
      }
      if (e.message.includes('Duplicate entry')) {
        notification.error({
          message: 'Si è verificato un errore sullo slug specificato',
          description: 'Lo slug inserito è già presente, usane un altro'
        });
      }
    }
  };
  //#endregion

  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null
    })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
        render: (text, record) => <div><img style={{ width: '100%', padding: '15px' }} src={`${record.logo}`} /></div>,
        width: 150
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('nome comune', 'nome'),
        filteredValue: filteredInfo.nome || null,
        sortOrder: sortedInfo.columnKey === 'nome' && sortedInfo.order,
        width: 200
      },
      {
        title: "Slug",
        dataIndex: "slug",
        key: "slug",
        sorter: (a, b) => a.slug.localeCompare(b.slug),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('slug', 'slug'),
        filteredValue: filteredInfo.slug || null,
        sortOrder: sortedInfo.columnKey === 'slug' && sortedInfo.order,
        width: 200
      },
      {
        title: "Partita IVA",
        dataIndex: "partita_iva",
        key: "partita_iva",
        ...this.getColumnSearchProps('partita iva', 'partita_iva'),
        filteredValue: filteredInfo.partita_iva || null,
        width: 200
      },
      {
        title: "Telefono",
        dataIndex: "telefono",
        key: "telefono",
        ...this.getColumnSearchProps('telefono', 'telefono'),
        filteredValue: filteredInfo.telefono || null,
        width: 200
      },
      {
        title: "E-Mail Contatto",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('email', 'email'),
        filteredValue: filteredInfo.email || null,
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        width: 400
      },
      {
        title: "Città",
        dataIndex: "citta",
        key: "citta",
        sorter: (a, b) => a.citta.localeCompare(b.citta),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('città', 'citta'),
        filteredValue: filteredInfo.citta || null,
        sortOrder: sortedInfo.columnKey === 'citta' && sortedInfo.order,
        width: 200
      },
      {
        title: "Indirizzo",
        dataIndex: "indirizzo",
        key: "indirizzo",
        sorter: (a, b) => a.indirizzo.localeCompare(b.indirizzo),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('indirizzo', 'indirizzo'),
        filteredValue: filteredInfo.indirizzo || null,
        sortOrder: sortedInfo.columnKey === 'indirizzo' && sortedInfo.order,
        width: 300
      },
      {
        title: "Civico",
        dataIndex: "civico",
        key: "civico",
        ...this.getColumnSearchProps('civico', 'civico'),
        filteredValue: filteredInfo.civico || null,
        width: 120
      },
      {
        title: "Provincia",
        dataIndex: "provincia",
        key: "provincia",
        sorter: (a, b) => a.provincia.localeCompare(b.provincia),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('provincia', 'provincia'),
        filteredValue: filteredInfo.provincia || null,
        sortOrder: sortedInfo.columnKey === 'provincia' && sortedInfo.order,
        width: 200
      },
      {
        title: "CAP",
        dataIndex: "cap",
        key: "cap",
        ...this.getColumnSearchProps('cap', 'cap'),
        filteredValue: filteredInfo.cap || null,
        width: 100
      },
      {
        title: "Abilitato",
        dataIndex: "abilitato",
        key: "abilitato",
        render: value => value ? <Row justify='center' align='middle'><Col><Tag color="green">Sì</Tag></Col></Row> : <Row justify='center' align='middle'><Col><Tag color="red">No</Tag></Col></Row>,
        filters: [
          { text: 'Sì', value: 1 },
          { text: 'No', value: 0 },
        ],
        filteredValue: filteredInfo.abilitato || null,
        onFilter: (value, record) => record.abilitato == value,
        ellipsis: true,
        width: 100
      },
      {
        title: "",
        dataIndex: "settings",
        key: "settings",
        align: "right",
        width: 100,
        fixed: 'right',
        render: (text, record, index) => (
          <Row justify='space-around' align='center'>
            <DeleteOutlined
              onClick={async () => {
                this._eliminaComune(record, index);
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
            <EditOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({
                  loaded: false
                });
                const comune = comuni_json.find(i => i.nome == record.citta);
                const { data } = await getComune(record.id);
                const user = await getComuneFirebaseInfo(record.telefono);
                this.setState({
                  drawer_visible: true,
                  loaded: true,
                  selected_comune: { ...data, comuni_partecipanti: JSON.parse(data.comuni_partecipanti) },
                  selected_index: data.id,
                  selected_user: user.data,
                  selected_citta_caps:
                    comune
                      ? Array.isArray(comune.cap)
                        ? comune.cap
                        : [comune.cap]
                      : []
                })
              }}
            />
          </Row>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      searchText: ""
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  //#region Lifecylcle Componente
  _loader = async () => {
    try {
      let comuni = await getComuni();
      this.setState({
        comuni: comuni.data,
        loaded: true
      });
    } catch (error) {
      console.log(error);
      message.error("Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.");
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_comune, loaded, selected_user, cambia_mail_visible, new_mail } = this.state;

    const {
      nome,
      slug,
      partita_iva,
      logo,
      abilitato,
      provincia,
      citta,
      cap,
      indirizzo,
      civico,
      latitudine,
      longitudine,
      telefono,
      userMail,
      email,
      creazione,
      ultima_modifica,
      note,
      comuni_partecipanti,
    } = selected_comune || {};

    const initialValues = {
      nome: nome ?? '',
      slug: slug ?? '',
      partita_iva: partita_iva ?? '',
      logo: logo ?? null,
      abilitato: abilitato ?? false,
      provincia: provincia ?? '',
      citta: citta ?? null,
      cap: cap ?? null,
      indirizzo: indirizzo ?? '',
      civico: civico ?? '',
      latitudine: latitudine ?? '',
      longitudine: longitudine ?? '',
      telefono: telefono ?? '',
      userMail: userMail ?? '',
      email: email ?? '',
      creazione: creazione ?? '',
      ultima_modifica: ultima_modifica ?? '',
      note: note ?? '',
      comuni_partecipanti: comuni_partecipanti ?? [],
    };

    return (
      <>
        <Wrapper>
          <PageHeader
            title="Comuni"
            description="In questa sezione ci sono tutti i Comuni" />
          <Divider />
          <Row
            align='middle'
            justify='end'
            gutter={[15, 15]}
            style={{ marginBottom: "15px" }}>
            <Col>
              <Button
                onClick={this._clearFiltri}
                size='large'
              >
                Reset Filtri
              </Button>
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => this.setState({ drawer_visible: true })}
                type="primary"
                size='large'
              >
                Aggiungi comune
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={this.state.comuni}
            columns={this._getColumns()}
            onChange={this._handleChange}
            size="small"
            scroll={{ x: 576, y: 400 }}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100", "200"] }}
          />

          {/*Drawer creazione/modifica comune*/}
          <Drawer
            destroyOnClose
            placement="right"
            closable={true}
            onClose={() => this.setState({
              drawer_visible: false,
              selected_comune: null,
              selected_index: null,
              selected_user: null,
            })}
            visible={this.state.drawer_visible}
            width="100%"
            closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
          >
            <Row justify='start' align='middle' gutter={[15, 15]}>
              <Col xs={24}>
                <PageHeader
                  title={selected_comune
                    ? "Modifica comune"
                    : "Aggiungi comune"}
                  description={selected_comune
                    ? `Aggiorna i dettagli del comune: ${selected_comune.nome}`
                    : "Aggiungi un nuovo comune."}
                />
              </Col>
            </Row>
            <Divider />
            <Formik
              initialValues={initialValues}
              validationSchema={selected_comune ? validationSchemaAggiornaComune : validationSchemaAggiungiComune}
              onSubmit={this._submitForm}
            >
              {({ touched, errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => (
                <Form>
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Abilitazione" />
                    </Col>
                    <Col span={24}>
                      <Checkbox className="input" checked={values.abilitato} onBlur={handleBlur("abilitato")} onChange={handleChange("abilitato")}>
                        Abilitato
                      </Checkbox>
                    </Col>
                  </Row>

                  <Divider />

                  {selected_comune
                    ? (
                      <>
                        <Row justify='start' align='top' gutter={[15, 15]}>
                          <Col span={24}>
                            <Label titolo="Azioni account" />
                          </Col>
                        </Row>
                        <Row justify='start' align='top' gutter={[15, 15]}>
                          <Col xs={24} sm={24} md={12}>
                            <Row align='middle' justify='space-between'>
                              <Col>
                                <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>E-Mail</span>
                              </Col>
                              <Col>
                                <Tag
                                  style={{ margin: 0, cursor: 'pointer' }}
                                  color={selected_user.emailVerified ? 'green' : 'orange'}
                                  onClick={async () => {
                                    this.setState({ loaded: false });
                                    try {
                                      await inviaConfermaMailComune(selected_user.phoneNumber);
                                      notification.success({
                                        message: 'Mail di conferma inviata correttamente',
                                        description: 'Ora aspetta che il comune confermi la mail'
                                      })
                                    } catch (error) {
                                      notification.error({
                                        message: 'Errore nell\'invio della mail',
                                        description: 'Riprova più tardi'
                                      })
                                    }
                                    this.setState({ loaded: true });
                                  }}
                                >
                                  {selected_user.emailVerified ? 'Verificata' : 'Verifica Mail'}
                                </Tag>
                              </Col>
                            </Row>

                            <Input
                              disabled
                              value={selected_user.email}
                            />
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Telefono</span>
                            <PhoneInput
                              disabled
                              value={selected_user.phoneNumber}
                              country='it'
                              inputStyle={{ width: '100%' }}
                            />
                          </Col>
                          <Col span={24}>

                          </Col>
                          <Col span={24}>
                            <Row>
                              <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Custom Claims</span>
                            </Row>
                            <Row>
                              {selected_user.customClaims && <Popover content="Ruolo"><Tag color={selected_user.customClaims.comune ? "green" : "orange"}>{selected_user.customClaims.comune ? "Comune" : "Non è un comune"}</Tag></Popover>}
                              {selected_user.customClaims && <Popover content="ID comune associato"><Tag color="blue">{selected_user.customClaims.id_comune ?? "Id non presente"}</Tag></Popover>}
                            </Row>

                          </Col>
                        </Row>
                        <Divider />
                        <Row justify='start' align='top' gutter={[15, 15]}>
                          <Col xs={24} sm={12} md={12} >
                            <Button
                              size="large"
                              block
                              onClick={() => this.setState({ new_mail: selected_user.email, cambia_mail_visible: true })}
                              type="primary"
                              icon={<EditOutlined />}
                            >
                              Cambia E-Mail
                            </Button>
                            <Modal
                              title="Cambia E-Mail"
                              okText='Conferma'
                              cancelText='Annulla'
                              visible={cambia_mail_visible}
                              onOk={async () => {
                                try {
                                  this.setState({ loaded: false })
                                  await updateEmailComune(telefono, new_mail);
                                  await inviaConfermaMailComune(telefono);

                                  notification.success({
                                    message: 'Mail modificata correttamente',
                                    description: `Ora il comune può accedere al suo pannello con la nuova mail`
                                  })
                                  this.setState({ cambia_mail_visible: false, selected_user: { ...selected_user, email: new_mail, emailVerified: false } });

                                } catch (e) {
                                  notification.error({
                                    message: "Errore",
                                    description: "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
                                  });

                                }
                                this.setState({ loaded: true });

                              }}
                              onCancel={() => this.setState({ cambia_mail_visible: false })}
                            >
                              <Input
                                placeholder='Nuova Mail'
                                type='email'
                                defaultValue={new_mail}
                                onChange={(e) => this.setState({ new_mail: e.target.value })}
                              />
                            </Modal>
                          </Col>
                          <Col xs={24} sm={12} md={12} >
                            <Button
                              size="large"
                              block
                              onClick={async () => {
                                this.setState({ loaded: false });
                                try {
                                  await resetPasswordComune(selected_user.phoneNumber);
                                  notification.success({
                                    message: 'Mail di reset password inviata correttamente',
                                    description: 'Ora aspetta che il comune resetti la password'
                                  })
                                } catch (error) {
                                  notification.error({
                                    message: 'Errore nell\'invio della mail',
                                    description: 'Riprova più tardi'
                                  })
                                }
                                this.setState({ loaded: true });
                              }}
                              type="primary"
                              icon={<EditOutlined />}
                            >
                              Cambia Password
                            </Button>
                          </Col>
                        </Row>
                        <Divider />
                      </>
                    )
                    : (
                      <>
                        <Row justify='start' align='top' gutter={[15, 15]}>
                          <Col span={24}>
                            <Label titolo="Dati account" />
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>E-Mail</span>
                            <Input
                              value={values.userMail ?? ''}
                              onChange={handleChange("userMail")}
                              placeholder="E-Mail"
                              onBlur={handleBlur("userMail")}
                            />
                            <div className="input-error">{touched.userMail && errors.userMail}</div>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Telefono</span>
                            <PhoneInput
                              value={values.telefono}
                              placeholder="Telefono"
                              country='it'
                              enableSearch
                              countryCodeEditable={false}
                              searchPlaceholder='Cerca'
                              onChange={handleChange('telefono')}
                              onBlur={handleBlur('telefono')}
                              inputStyle={{ width: '100%' }}
                            />
                            <div className="input-error">{touched.telefono && errors.telefono}</div>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Password</span>
                            <Input.Password
                              onChange={handleChange("password")}
                              placeholder="Password"
                              onBlur={handleBlur("password")}
                            />
                            <div className="input-error">{touched.password && errors.password}</div>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Conferma Password</span>
                            <Input.Password
                              onChange={handleChange("conferma_password")}
                              placeholder="Conferma Password"
                              onBlur={handleBlur("conferma_password")}
                            />
                            <div className="input-error">{touched.conferma_password && errors.conferma_password}</div>
                          </Col>
                        </Row>
                        <Divider />

                      </>
                    )}
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col span={24}>
                      <Label titolo="Dati principali" />
                    </Col>
                    <Col span={24}>
                      <Space>
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Comuni partecipanti{" "}
                        </span>
                        <span>
                          ({values.comuni_partecipanti ? 20 - values.comuni_partecipanti.length : 20} comuni partecipanti rimanent
                          {20 - values.comuni_partecipanti.length === 1 ? "e" : "i"})
                        </span>
                      </Space>

                      <Select
                        mode="tags"
                        placeholder="Inserisci le parole chiave per far trovare il tuo negozio ai clienti più facilmente"
                        defaultValue={values.comuni_partecipanti}
                        onChange={(value) => {
                          console.log(value)
                          if (value.length <= 20) setFieldValue("comuni_partecipanti", value);
                        }}
                        value={values.comuni_partecipanti}
                        onBlur={handleBlur("comuni_partecipanti")}
                        style={{ width: "100%" }}
                      >
                        {values.comuni_partecipanti}
                      </Select>
                      <div className="input-error">
                        {touched.comuni_partecipanti && errors.comuni_partecipanti}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Immagine</span>
                      <div style={{ width: "100%" }}>
                        <ImageCropper
                          cropSize={{ width: 400, height: 300 }}
                          aspect={4 / 3}
                          image={
                            values.logo ? values.logo : ''
                          }
                          onCropSuccess={blobUrl => setFieldValue("logo", blobUrl)}
                          onChange={handleChange("logo")}
                        />
                      </div>
                      <div className="input-error">{touched.logo && errors.logo}</div>

                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Nome Comune</span>
                      <Input
                        value={values.nome}
                        onChange={handleChange("nome")}
                        placeholder="Nome Comune"
                        onBlur={handleBlur("nome")}
                      />
                      <div className="input-error">{touched.nome && errors.nome}</div>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Slug</span>
                      <Input
                        value={values.slug ?? ''}
                        onChange={handleChange("slug")}
                        placeholder="Slug"
                        onBlur={handleBlur("slug")}
                      />
                      <div className="input-error">{touched.slug && errors.slug}</div>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={18} md={20}>
                      <span className="reduce-hashcomuni_partecipanti" style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        Indirizzo Comune
                      </span>
                      <Input

                        value={values.indirizzo}
                        onChange={handleChange("indirizzo")}
                        placeholder="Indirizzo Comune/Distretto/ecc..."
                        onBlur={handleBlur("indirizzo")}
                      />
                      <div className="input-error">{touched.indirizzo && errors.indirizzo}</div>
                    </Col>
                    <Col xs={24} sm={6} md={4}>
                      <span className="reduce-hashcomuni_partecipanti" style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        Civico
                      </span>
                      <Input
                        value={values.civico}
                        onChange={handleChange("civico")}
                        placeholder="N° civico"
                        onBlur={handleBlur("civico")}
                      />
                      <div className="input-error">{touched.civico && errors.civico}</div>
                    </Col>
                    <Col xs={24} md={16}>
                      <span className="reduce-hashcomuni_partecipanti" style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        Città
                      </span>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={(value) => {
                          setFieldValue("citta", value);
                          let comune = comuni_json.find(i => i.nome == value);
                          setFieldValue("cap", comune && Array.isArray(comune.cap) ? comune.cap[0] : comune.cap);
                          this.setState({ selected_citta_caps: comune && Array.isArray(comune.cap) ? comune.cap : [comune.cap] })
                          setFieldValue("provincia", comune.provincia.nome);
                        }}
                        placeholder="Inserisci e seleziona la città"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("citta")}
                        value={values.citta}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {comuni_json.map((c) => (
                          <Option key={c.nome}>{c.nome}</Option>
                        ))}
                      </Select>
                      <div className="input-error">{touched.citta && errors.citta}</div>
                    </Col>
                    <Col xs={24} md={8}>
                      <span className="reduce-hashcomuni_partecipanti" style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        CAP
                      </span>
                      <Select
                        onChange={(value) => setFieldValue("cap", value)}
                        placeholder="Seleziona o inserisci il cap"
                        style={{ width: "100%" }}
                        onBlur={handleBlur("cap")}
                        value={values.cap}
                      >
                        {values.citta
                          ? this.state.selected_citta_caps.map((c) => (
                            <Option key={c}>{c}</Option>
                          ))
                          : null
                        }
                      </Select>
                      <div className="input-error">{touched.cap && errors.cap}</div>
                    </Col>
                    <Divider />
                    <Col xs={24} md={12}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>
                        <Space>
                          Partita IVA
                          <Popover
                            content={
                              <div className="black-popover">
                                Non inserire "IT", se la tua partita IVA ne è provvista. La partita iva è composta da 11 cifre.
                              </div>
                            }
                          >
                            <QuestionCircleFilled />
                          </Popover>
                        </Space>
                      </span>
                      <Input

                        value={values.partita_iva}
                        onChange={handleChange("partita_iva")}
                        placeholder="Partita iva"
                        onBlur={handleBlur("partita_iva")}
                      />
                      <div className="input-error">{touched.partita_iva && errors.partita_iva}</div>
                    </Col>
                    <Col xs={24} md={12}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>E-Mail di contatto (opzionale)</span>
                      <Input

                        value={values.email ?? ''}
                        onChange={handleChange("email")}
                        placeholder="E-Mail"
                        onBlur={handleBlur("email")}
                      />
                      <div className="input-error">{touched.email && errors.email}</div>
                    </Col>
                    <Col span={24}>
                      <span style={{ color: Settings.colors.darkGrey }}>Note (opzionale)</span>
                      <TextArea

                        value={values.note ?? ''}
                        onChange={handleChange("note")}
                        placeholder="Inserisci informazioni rilevanti riguardo il comune/distretto/ecc... "
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        onBlur={handleBlur("note")}
                        style={{ height: 180 }}
                      />
                      <div className="input-error">{touched.note && errors.note}</div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Button
                        loading={isSubmitting}
                        disabled={!dirty || isSubmitting}
                        icon={selected_comune ? <EditOutlined /> : <PlusOutlined />}
                        block={true}
                        size="large"
                        onClick={() => {
                          if (!isValid)
                            notification.error({
                              message: 'Errore',
                              description: 'Ricontrolla i dati inseriti'
                            });
                          handleSubmit();
                        }}
                        type="primary"
                      >
                        {this.state.selected_comune ? <span>Aggiorna</span> : <span>Aggiungi</span>}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Drawer>
        </ Wrapper>
        <Loading loading={!loaded} />
      </>
    )

  }
  //#endregion
}

export default Comuni;
