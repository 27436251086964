import React from 'react';
import Wrapper from '../Components/Wrapper';
import PageHeader from '../Components/PageHeader';
import { Divider } from 'antd';

class Chat extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
        <Wrapper>
            <PageHeader 
                    title='Chat' 
                    description='Chatta su Garzone.' />
            <Divider />                
        </Wrapper>
    ) 
  }

}

export default Chat