import { Auth, Functions } from "../Config/Fire";

export function login(email, password) {
  return Auth().signInWithEmailAndPassword(email, password);
}

export function checkAuthState(callback) {
  return Auth().onAuthStateChanged(callback);
}

export function requestResetPassword(email) {
  return Auth().sendPasswordResetEmail(email);
}

export function logout() {
  return Auth().signOut();
}

export function getClaims() {
  return Auth().currentUser.getIdTokenResult();
}

export function resendEmailVerification() {
  return Auth().currentUser.sendEmailVerification();
}

export function updateEmailComune(telefono_comune, nuova_mail) {
  const updateUserEmail = Functions.httpsCallable("admin-updateUserMail");
  return updateUserEmail({ telefono: telefono_comune, nuova_mail: nuova_mail });
}

export function updateEmailNegozio(telefono_negozio, nuova_mail) {
  const updateEmailNegozio = Functions.httpsCallable("admin-updateUserMail");
  return updateEmailNegozio({
    telefono: telefono_negozio,
    nuova_mail: nuova_mail,
  });
}

export async function resetPasswordComune(telefono_comune) {
  const resetPasswordComune = Functions.httpsCallable(
    "admin-resetUserPassword"
  );
  return resetPasswordComune(telefono_comune);
}

export async function resetPasswordNegozio(telefono_negozio) {
  const resetPasswordNegozio = Functions.httpsCallable(
    "admin-resetUserPassword"
  );
  return resetPasswordNegozio(telefono_negozio);
}

export async function inviaConfermaMailComune(telefono_comune) {
  const inviaConfermaMailComune = Functions.httpsCallable(
    "admin-inviaConfermaMail"
  );
  return inviaConfermaMailComune(telefono_comune);
}

export async function inviaConfermaMailNegozio(telefono_negozio) {
  const inviaConfermaMailNegozio = Functions.httpsCallable(
    "admin-inviaConfermaMail"
  );
  return inviaConfermaMailNegozio(telefono_negozio);
}

export async function addClaims() {
  const addClaims = Functions.httpsCallable("auth-addClaims");
  return addClaims();
}
