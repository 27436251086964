import React from "react";
import { Menu, Row } from "antd";
import {
  SettingOutlined,
  DashboardOutlined,
  UserOutlined,
  BankOutlined,
  ShopOutlined,
  ShoppingOutlined,
  BarsOutlined,
  CommentOutlined,
  NotificationOutlined,
  ShoppingCartOutlined,
  FlagOutlined
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";

const { SubMenu } = Menu;

class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: this.props.voices,
      navigation: this.props.navigation,
      selectedKey: ["1"],
    };
  }

  _getSelectedKey = () => {
    let path = this.props.location.pathname.toLowerCase();
    let dict = {
      "/dashboard": "1",
      "/account": "2",
      "/comuni": "3",
      "/negozi": "4",
      "/prodotti": "5",
      "/servizi": "6",
      "/categorie": "7",
      "/promozioni": "8",
      "/ordini": "9",
      "/resi": "10",
      "/comunicazioni": "11",
    };
    return dict[path];
  };

  handleClick = (e) => {
    this.props.history.push(e.item.props.route);
  };

  render() {
    let selectedKeys = [];
    selectedKeys.push(this._getSelectedKey());
    return (
      <div style={styles.mainCointainer}>
        <Row style={styles.fixedHeader} align="middle" justify="start">
          <img
            style={{ height: "45px" }}
            src={require("../Assets/logo-white.png")}
          />
        </Row>
        <Row align="middle" justify="start" style={styles.menuTitle}>
          MENU'
        </Row>
        <Menu
          onClick={this.handleClick}
          style={styles.menuContent}
          defaultSelectedKeys={selectedKeys}
          defaultOpenKeys={["sub2"]}
          mode="inline"
        >
          <Menu.Item key="1" icon={<DashboardOutlined />} route="/dashboard">
            Dashboard
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />} route="/account">
            Utenti
          </Menu.Item>
          <Menu.Item key="3" icon={<BankOutlined />} route="/comuni">
            Comuni
          </Menu.Item>
          <Menu.Item key="4" icon={<ShopOutlined />} route="/negozi">
            Negozi
          </Menu.Item>
          <Menu.Item key="5" icon={<ShoppingOutlined />} route="/prodotti">
            Prodotti
          </Menu.Item>
          <Menu.Item key="6" icon={<ShoppingOutlined />} route="/servizi">
            Servizi
          </Menu.Item>
          <Menu.Item key="7" icon={<BarsOutlined />} route="/categorie">
            Categorie
          </Menu.Item>
          <Menu.Item
            key="8"
            icon={<NotificationOutlined />}
            route="/promozioni"
          >
            Promozioni
          </Menu.Item>
          <SubMenu
            key="sub2"
            icon={<ShoppingCartOutlined />}
            title="Gestisci ordini">
            <Menu.Item icon={<ShoppingCartOutlined />} key="9" route="/ordini">
              Ordini
            </Menu.Item>
            <Menu.Item key="10" icon={<FlagOutlined />} route="/resi">
              Resi
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="11"
            icon={<NotificationOutlined />}
            route="/comunicazioni"
          >
            Comunicazioni
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

const HEADER_HEIGHT = "64px";

const styles = {
  mainCointainer: {
    backgroundColor: "#F2F3F4",
    height: "100%",
  },
  fixedHeader: {
    position: "sticky",
    height: HEADER_HEIGHT,
    width: "100%",
    backgroundColor: "#516375",
    padding: "0 20px",
    zIndex: 100,
  },
  menuContent: {
    width: 256,
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  menuTitle: {
    width: 256,
    padding: "15px",
    fontWeight: "bold",
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  contentHeaderMenu: {
    textDecoration: "none",
    color: "white",
    padding: 8,
    fontSize: "18px",
  },
};

export default withRouter(SidebarMenu);
