import React from "react";

import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import {
  Divider,
  Row,
  Col,
  Button,
  Table,
  Tag,
  Input,
  Space,
  Select,
  Modal,
  Typography,
  notification,
  Drawer,
  Image,
  List,
} from "antd";
import Highlighter from "react-highlight-words";
import PhoneInput from "react-phone-input-2";

import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  getUtenti,
  postIndirizzi,
  getUtenteFirebase,
  aggiornaUtente,
  requestResetPassword,
  updateUserEmail,
  resendEmailVerification,
  getUserChats,
  getMessaggi,
} from "../Controllers/Utenti";
import Settings from "../Config/Settings";
import * as Yup from "yup";

import ChatList from "../Components/ChatList";
import LoadingBlock from "../Components/LoadingBlock";

import { eliminaUtente } from "../Controllers/Utenti";
import Title from "antd/lib/skeleton/Title";
import Label from "../Components/Label";
import { Form, Formik } from "formik";

import placeholder from "../Assets/placeholder.jpg";

const comuni_json = require("../Assets/comuni.json");
const { Option } = Select;

//Costanti
const { confirm } = Modal;

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChatTitle: "",
      loadingMessages: false,
      drawerChatVisible: false,
      userChats: [],
      userMessages: [],
      drawer_visible: false,
      utenti: [],
      drawerIndirizziVisible: false,
      indirizziDrawer: null,
      uidDrawer: null,
      currentUtente: null,
      currentUtenteFirebase: null,
      utentiLoading: false,
      cambiaMailVisible: false,
      new_email: null,
      email_errors: null,
      new_email_conf: null,
      loadingMail: false,
      recuperaPasswordVisible: false,
      loadingDrawer: false,
    };
  }
  _getUtenti = async () => {
    this.setState({ utentiLoading: true });
    const utenti = await getUtenti();
    console.log("UTENTI", utenti);
    this.setState({ utenti: utenti.data, utentiLoading: false });
  };
  _getMessages = async (chat_uid, chat_title) => {
    this.setState({ loadingMessages: true, currentChatTitle: chat_title });
    const res = await getMessaggi(chat_uid);
    console.log("MESSAGGI", res);
    this.setState({ userMessages: res.data, loadingMessages: false });
  };
  _setChats = (chats) => {
    let chatsArray = [];
    for (const key in chats) {
      if (Object.hasOwnProperty.call(chats, key)) {
        chatsArray.push({ chat_uid: key, ...chats[key] });
      }
    }
    chatsArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return chatsArray;
  };
  componentDidMount() {
    this._getUtenti();
  }
  getUserChats = () => { };
  deleteIndirizzo = async (indirizzo, civico, citta, cap, uid) => {
    try {
      let nuoviIndirizzi = [];
      for (let i = 0; i < this.state.indirizziDrawer.length; i++) {
        if (
          this.state.indirizziDrawer[i].indirizzo !== indirizzo ||
          this.state.indirizziDrawer[i].civico !== civico ||
          this.state.indirizziDrawer[i].citta !== citta ||
          this.state.indirizziDrawer[i].cap !== cap
        ) {
          nuoviIndirizzi.push(this.state.indirizziDrawer[i]);
        }
      }
      let res = await postIndirizzi({
        id: uid,
        indirizzi: nuoviIndirizzi,
      });

      notification.destroy();
      notification.success({
        message: "Perfetto",
        description: "L'indirizzo selezionato è stato eliminato correttamente!",
      });
      this.setState({ indirizziDrawer: nuoviIndirizzi });
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Errore nell'eliminazione dell'indirizzo.",
      });
    }
  };
  _submitForm = async (values, { setSubmitting }) => {
    this.setState({ aggiornaLoading: true });
    try {
      const { nome, cognome, cellulare, citta } = values;
      const utenteRecord = {
        uid: this.state.currentUtente.id_utenza_firebase,
        nome: nome,
        cognome: cognome,
        telefono: `+${cellulare}`,
        citta: citta,
      };

      await aggiornaUtente(utenteRecord);
      window.location.reload();
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: "Dati account aggiornati correttamente",
      });
    } catch (error) {
      console.log(error);
      setSubmitting(false);

      let description =
        "Errore in fase di registrazione, contattare l'assistenza";
      if (error.toString().indexOf("phone") !== -1) {
        description =
          "Esiste già un account associato a questo numero di telefono";
      } else if (error.toString().indexOf("email") !== -1) {
        description =
          "Esiste già un account associato a questo indirizzo email";
      } else if (error.toString().match("TOO_SHORT")) {
        description = "Il numero di telefono è troppo corto";
      } else if (error.toString().match("Missing recipients")) {
        description = "L'indirizzo email inserito è inseistente";
      }

      notification.destroy();
      notification.error({
        message: "Errore",
        description: description,
      });
    } finally {
      this.setState({
        aggiornaLoading: false,
      });
    }
  };
  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null ? (
        text
      ) : this.state.searchedColumn === dataIndex &&
        this.state.filteredInfo[dataIndex] != null ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    console.log(filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      searchText: "",
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion
  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("id", "id"),
        filteredValue: filteredInfo.id || null,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        width: 100,
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nome", "nome"),
        filteredValue: filteredInfo.nome || null,
        sortOrder: sortedInfo.columnKey === "nome" && sortedInfo.order,
        width: 200,
      },
      {
        title: "Cognome",
        dataIndex: "cognome",
        key: "cognome",
        sorter: (a, b) => a.cognome.localeCompare(b.cognome),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("cognome", "cognome"),
        filteredValue: filteredInfo.cognome || null,
        sortOrder: sortedInfo.columnKey === "cognome" && sortedInfo.order,
        width: 200,
      },
      {
        title: "Città",
        dataIndex: "citta",
        key: "citta",
        sorter: (a, b) => a.citta.localeCompare(b.citta),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("citta", "citta"),
        filteredValue: filteredInfo.citta || null,
        sortOrder: sortedInfo.columnKey === "citta" && sortedInfo.order,
        width: 200,
      },
      {
        title: "UID Firebase",
        dataIndex: "id_utenza_firebase",
        key: "id_utenza_firebase",
        sorter: (a, b) =>
          a.id_utenza_firebase.localeCompare(b.id_utenza_firebase),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps(
          "id_utenza_firebase",
          "id_utenza_firebase"
        ),
        filteredValue: filteredInfo.id_utenza_firebase || null,
        sortOrder:
          sortedInfo.columnKey === "id_utenza_firebase" && sortedInfo.order,
        width: 350,
      },
      {
        title: "Privacy",
        dataIndex: "privacy_condizioni",
        key: "privacy_condizioni",
        render: (value) =>
          value ? (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="green">Sì</Tag>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="red">No</Tag>
              </Col>
            </Row>
          ),
        filters: [
          { text: "Sì", value: 1 },
          { text: "No", value: 0 },
        ],
        filteredValue: filteredInfo.privacy_condizioni || null,
        onFilter: (value, record) => record.privacy_condizioni === value,
        ellipsis: true,
        width: 130,
      },
      {
        title: "Provider",
        dataIndex: "provider_registrazione",
        key: "provider_registrazione",
        sorter: (a, b) =>
          a.provider_registrazione.localeCompare(b.provider_registrazione),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("cognome", "cognome"),
        filteredValue: filteredInfo.provider_registrazione || null,
        sortOrder:
          sortedInfo.columnKey === "provider_registrazione" && sortedInfo.order,
        width: 150,
      },

      {
        title: "Abilitato",
        dataIndex: "abilitato",
        key: "abilitato",
        fixed: "right",
        render: (value) =>
          value ? (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="green">Sì</Tag>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="red">No</Tag>
              </Col>
            </Row>
          ),
        filters: [
          { text: "Sì", value: 1 },
          { text: "No", value: 0 },
        ],
        filteredValue: filteredInfo.abilitato || null,
        onFilter: (value, record) => record.abilitato === value,
        ellipsis: true,
        width: 100,
      },
      {
        title: "Bloccato",
        dataIndex: "bloccato",
        key: "bloccato",
        fixed: "right",
        render: (value) =>
          value ? (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="green">Sì</Tag>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle">
              <Col>
                <Tag color="red">No</Tag>
              </Col>
            </Row>
          ),
        filters: [
          { text: "Sì", value: 1 },
          { text: "No", value: 0 },
        ],
        filteredValue: filteredInfo.bloccato || null,
        onFilter: (value, record) => record.bloccato === value,
        ellipsis: true,
        width: 100,
      },
      {
        title: "Azioni",
        dataIndex: "settings",
        key: "settings",
        align: "center",
        width: 100,
        fixed: "right",
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <DeleteOutlined
              onClick={async () => {
                this._eliminaUtente(record, index);
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
            <EditOutlined
              onClick={async () => {
                this.setState({ loadingDrawer: true });
                const utenteFirebase = await getUtenteFirebase(
                  record.id_utenza_firebase
                );
                this.setState({
                  drawerIndirizziVisible: true,
                  indirizziDrawer: JSON.parse(record.indirizzi),
                  currentUtente: record,
                  currentUtenteFirebase: utenteFirebase.data,
                  loadingDrawer: false,
                });
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
            <MessageOutlined
              onClick={async () => {
                this.setState({ loadingDrawer: true });

                const utenteFirebase = await getUtenteFirebase(
                  record.id_utenza_firebase
                );
                const res = await getUserChats(record.id_utenza_firebase);
                const chats = this._setChats(res.data);
                console.log("PROVA", res);
                this.setState({
                  drawerChatVisible: true,
                  currentUtente: record,
                  currentUtenteFirebase: utenteFirebase.data,
                  userChats: chats,
                  loadingDrawer: false,
                });
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
          </Row>
        ),
      },
    ];
  };

  _eliminaUtente = (utente, index) => {
    confirm({
      title: "Elimina utente",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare l'utente: ${utente.nome}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //rimozione da db
          await eliminaUtente({ uid: utente.id_utenza_firebase });

          //rimozione dallo stato
          let utenti = [...this.state.utenti];
          utenti.splice(index, 1);
          this.setState({ utenti: utenti });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato l'utente ${utente.nome} con successo`,
          });
        } catch (error) {
          console.log("errore");
          notification.error({
            title: "Errore",
            description:
              "Si è verificato un errore durante l'eliminazione dell'utente",
          });
        }
      },
    });
  };


  render() {
    console.log("dati", this.state.currentUtente);
    const { currentUtente, currentUtenteFirebase } = this.state;
    console.log("Firebase", currentUtenteFirebase);
    return (
      <Wrapper>
        {this.state.loadingDrawer ? (
          <LoadingBlock style={{ marginTop: "300px" }} />
        ) : (
          <>
            <PageHeader
              title="Utenti"
              description="In questa sezione ci sono tutti gli utenti"
            />
            <Divider />
            <Row
              align="middle"
              justify="end"
              gutter={[15, 15]}
              style={{ marginBottom: "15px" }}
            >
              <Col>
                <Button onClick={this._clearFiltri} size="large">
                  Reset Filtri
                </Button>
              </Col>
            </Row>
            <Table
              bordered
              dataSource={this.state.utenti}
              columns={this._getColumns()}
              onChange={this._handleChange}
              size="small"
              scroll={{ x: 576, y: 500 }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100", "200"],
              }}
              loading={this.state.utentiLoading}
            />
            {/* DRAWER MODIFICA UTENTE */}
            <Drawer
              visible={this.state.drawerIndirizziVisible}
              title={"Dati utente"}
              onClose={() => {
                this.setState({
                  drawerIndirizziVisible: false,
                  indirizziDrawer: [],
                });
              }}
              width={"100%"}
            >
              {this.state.currentUtente ? (
                <Row
                  justify="start"
                  align="top"
                  gutter={[15, 15]}
                  style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <PageHeader title="Modifica i dati dell'utente" />
                  </Col>
                  <Col span={24}>
                    <Label titolo="Azioni account" />
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          E-Mail
                          </span>
                      </Col>
                      {currentUtenteFirebase ? (
                        <Col>
                          <Tag
                            style={{ margin: 0, cursor: "pointer" }}
                            color={
                              currentUtenteFirebase.emailVerified
                                ? "green"
                                : "orange"
                            }
                            onClick={async () => {
                              try {
                                await resendEmailVerification(
                                  currentUtenteFirebase.uid
                                );
                                notification.success({
                                  message:
                                    "Mail di conferma inviata correttamente",
                                  description:
                                    "Ora aspetta che il negozio confermi la mail",
                                });
                              } catch (error) {
                                notification.error({
                                  message: "Errore nell'invio della mail",
                                  description: error,
                                });
                              }
                            }}
                          >
                            {currentUtenteFirebase.emailVerified
                              ? "Verificato"
                              : "Non verificato"}
                          </Tag>
                        </Col>
                      ) : null}
                    </Row>

                    <Input
                      disabled
                      value={
                        currentUtenteFirebase ? currentUtenteFirebase.email : ""
                      }
                    ></Input>
                    <Button
                      block
                      type="primary"
                      style={{ marginTop: "20px" }}
                      onClick={() => {
                        this.setState({ cambiaMailVisible: true });
                      }}
                    >
                      Cambia e-mail
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}>Password</span>
                    <Input disabled value="*************" />
                    <Button
                      block
                      type="primary"
                      style={{ marginTop: "20px" }}
                      onClick={() =>
                        this.setState({ recuperaPasswordVisible: true })
                      }
                    >
                      Cambia Password
                    </Button>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <span style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}>Custom claims</span>
                  </Col>
                  <Col>
                    <Tag color="green">
                      {currentUtenteFirebase ? (
                        <span>
                          {Object.keys(currentUtenteFirebase.customClaims)[0]}
                        </span>
                      ) : null}
                    </Tag>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <Label titolo="Informazioni anagrafiche" />
                  </Col>
                  <Col span={24}>
                    <Formik
                      initialErrors={{}}
                      validateOnMount={false}
                      validateOnChange={true}
                      initialValues={{
                        nome: currentUtente.nome,
                        cognome: currentUtente.cognome,
                        cellulare: currentUtenteFirebase.phoneNumber,
                        citta:
                          currentUtente.citta === '""'
                            ? null
                            : currentUtente.citta,
                      }}
                      validationSchema={Yup.object().shape({
                        nome: Yup.string()
                          .matches(
                            /^[A-Za-zÀ-ÿ]+$/i,
                            "Inserisci un nome valido"
                          )
                          .required("Inserisci un nome"),
                        cognome: Yup.string()
                          .required("Inserisci un cognome")
                          .matches(
                            /^[A-Za-zÀ-ÿ]+$/i,
                            "Inserisci un cognome valido"
                          ),
                        cellulare: Yup.string()
                          .strict(true)
                          .nullable()
                          .trim(
                            "Il campo non può avere spazi vuoti iniziali o finali"
                          )
                          .matches(
                            /[1-9]\d{4,14}$/i,
                            "Inserisci un numero di cellulare valido"
                          )
                          .required("Inserisci il tuo numero di cellulare"),
                        citta: Yup.string().required(
                          "Devi inserire una città di residenza."
                        ),
                      })}
                      onSubmit={this._submitForm}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form>
                          <Row
                            justify="start"
                            align="top"
                            gutter={[15, 15]}
                            style={{ color: Settings.colors.darkGrey }}
                          >
                            <Col md={6} sm={12} xs={24}>
                              <Row>Nome</Row>
                              <Row>
                                <Input
                                  placeholder="Inserisci nome"
                                  value={values.nome}
                                  onChange={handleChange("nome")}
                                  onBlur={handleBlur("nome")}
                                />
                              </Row>
                              <Row>
                                <span style={{ color: Settings.colors.red }}>
                                  {touched.nome && errors.nome}
                                </span>
                              </Row>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                              <Row>Cognome</Row>
                              <Row>
                                <Input
                                  placeholder="Inserisci cognome"
                                  value={values.cognome}
                                  onChange={handleChange("cognome")}
                                  onBlur={handleBlur("cognome")}
                                />
                              </Row>
                              <Row>
                                <span style={{ color: Settings.colors.red }}>
                                  {touched.cognome && errors.cognome}
                                </span>
                              </Row>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                              <Row>Telefono</Row>
                              <Row>
                                <Col span={24}>
                                  <PhoneInput
                                    value={values.cellulare}
                                    onChange={handleChange("cellulare")}
                                    onBlur={handleBlur("cellulare")}
                                    placeholder="Inserisci telefono"
                                    country="it"
                                    countryCodeEditable={false}
                                    inputStyle={{
                                      width: "100%",
                                      height: "31.19px",
                                    }}
                                  />
                                  <span style={{ color: Settings.colors.red }}>
                                    {touched.cellulare && errors.cellulare}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                              <span>Città di residenza</span>
                              <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(value) => {
                                  setFieldValue("citta", value);
                                  let comune = comuni_json.find(
                                    (i) => i.nome == value
                                  );
                                  setFieldValue(
                                    "cap",
                                    comune && Array.isArray(comune.cap)
                                      ? comune.cap[0]
                                      : comune.cap
                                  );

                                  setFieldValue(
                                    "provincia",
                                    comune.provincia.nome
                                  );
                                }}
                                size="default"
                                placeholder="Scegli la città"
                                style={{ width: "100%" }}
                                onBlur={handleBlur("citta")}
                                value={values.citta}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {comuni_json.map((c) => (
                                  <Option key={c.nome}>{c.nome}</Option>
                                ))}
                              </Select>
                              <span style={{ color: Settings.colors.red }}>
                                {touched.citta && errors.citta}
                              </span>
                            </Col>
                          </Row>
                          <Row justify="start" align="middle" style={{ marginTop: 15 }}>
                            <Col>
                              <Button
                                icon={<EditOutlined />}
                                loading={this.state.aggiornaLoading}
                                type="primary"
                                disabled={
                                  !(isValid && dirty) ||
                                  this.state.aggiornaLoading
                                }
                                onClick={handleSubmit}
                              >
                                {this.state.aggiornaLoading
                                  ? "Attendi..."
                                  : "Aggiorna"}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>

                  <Divider />
                  <Col span={24}>
                    <Label titolo="Indirizzi" />
                  </Col>
                  <Col span={12}>
                    <List
                      dataSource={this.state.indirizziDrawer}
                      renderItem={({ indirizzo, cap, citta, civico }) => {
                        return (
                          <List.Item
                            extra={
                              <Row gutter={15}>
                                <Col>
                                  <DeleteOutlined
                                    disabled
                                    onClick={() => {
                                      Modal.confirm({
                                        title: `Stai eliminando l'indirizzo "${indirizzo}"`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: `Sei sicuro di voler eliminare l'indirizzo ${indirizzo}?`,
                                        okText: "Conferma",
                                        cancelText: "Annulla",
                                        onOk: async () => {
                                          this.deleteIndirizzo(
                                            indirizzo,
                                            civico,
                                            citta,
                                            cap,
                                            this.state.uidDrawer
                                          );
                                        },
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            }
                          >
                            <List.Item.Meta
                              title={`${indirizzo} ${civico}, ${citta} ${cap}`}
                            />
                          </List.Item>
                        );
                      }}
                    ></List>
                  </Col>
                  {/* //MODALE CAMBIO EMAIL */}
                  <Modal
                    destroyOnClose
                    visible={this.state.cambiaMailVisible}
                    footer={null}
                    onCancel={() => {
                      this.setState({ cambiaMailVisible: false });
                    }}
                    title={
                      <Image
                        style={styles.modalLogo}
                        width="158px"
                        src={require("../Assets/logo-cyan.png")}
                      />
                    }
                  >
                    <Row justify="start" gutter={[0, 15]}>
                      <Col span={24}>
                        <Title level={3} style={styles.title}>
                          Cambia E-mail
                        </Title>
                      </Col>
                    </Row>
                    <Row justify="space-around" gutter={[0, 10]}>
                      <Col md={24} sm={24}>
                        <Row justify="center" align="middle" gutter={[15, 15]}>
                          <Col span={24}>
                            <Row
                              justify="center"
                              align="middle"
                              gutter={[15, 15]}
                            >
                              <Col span={24}>
                                <span
                                  style={{
                                    marginBottom: 10,
                                    color: Settings.colors.darkGrey,
                                  }}
                                >
                                  Nuova E-mail
                                </span>
                                <Input
                                  value={this.state.new_email}
                                  onChange={(value) =>
                                    this.setState({
                                      new_email: value.target.value,
                                    })
                                  }
                                  placeholder="Nuova E-mail"
                                />
                                <div style={styles.emailError}>
                                  {this.state.email_errors}
                                </div>
                              </Col>
                              <Col span={24}>
                                <span
                                  style={{
                                    marginBottom: 10,
                                    color: Settings.colors.darkGrey,
                                  }}
                                >
                                  Ripeti nuova E-mail
                                </span>
                                <Input
                                  value={this.state.new_email_conf}
                                  onChange={(value) =>
                                    this.setState({
                                      new_email_conf: value.target.value,
                                    })
                                  }
                                  placeholder="Ripeti nuova E-mail"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divider />
                        <Button
                          block
                          type="primary"
                          loading={this.state.loadingMail}
                          disabled={this.state.loadingMail}
                          onClick={async () => {
                            const { new_email, new_email_conf } = this.state;
                            //Validation
                            if (new_email !== "" && new_email !== null) {
                              if (new_email !== new_email_conf) {
                                this.setState({
                                  email_errors: "Le email non corrispondono",
                                });
                              } else {
                                this.setState({ loadingMail: true });
                                try {
                                  const user = await updateUserEmail({
                                    uid: this.state.currentUtente.id_utenza_firebase,
                                    nuova_mail: new_email,
                                  });
                                  await resendEmailVerification(this.state.currentUtente.id_utenza_firebase);

                                  this.setState({ cambiaMailVisible: false, drawerIndirizziVisible: false });
                                  notification.success({
                                    message: "E-mail aggiornata",
                                    description: `La mail dell'utente ${currentUtente.nome} ${currentUtente.cognome} è stata aggiornata`,
                                  });
                                } catch (e) {
                                  if (e.code == "auth/invalid-email") {
                                    this.setState({
                                      new_email: "",
                                      new_email_conf: "",
                                    });
                                    notification.error({
                                      message: "Email non valida",
                                      description:
                                        "L'email che hai inserito non è valida. Inseriscine un'altra.",
                                    });
                                  } else if (
                                    e.code == "auth/email-already-in-use"
                                  ) {
                                    this.setState({
                                      new_email: "",
                                      new_email_conf: "",
                                    });
                                    notification.error({
                                      message: "Email già in uso",
                                      description:
                                        "L'email inserita è già stata usata da un'altro utente. Inseriscine un'altra.",
                                    });
                                  } else {
                                    this.setState({ drawer_email: false });
                                    notification.error({
                                      message: "Errore",
                                      description:
                                        "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
                                    });
                                  }

                                  console.log("Error:", e);
                                } finally {
                                  this.setState({ loadingMail: false });
                                }
                              }
                            } else {
                              this.setState({
                                email_errors: "Inserisci un indirizzo email",
                              });
                            }
                          }}
                        >
                          {" "}
                          Cambia Email
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                  {/*MODAL CAMBIA PASSWORD*/}
                  <Modal
                    destroyOnClose
                    visible={this.state.recuperaPasswordVisible}
                    footer={null}
                    onCancel={() => {
                      this.setState({ recuperaPasswordVisible: false });
                    }}
                    title={
                      <Image
                        style={styles.modalLogo}
                        width="158px"
                        src={require("../Assets/logo-cyan.png")}
                      />
                    }
                  >
                    <Row justify="start" gutter={[0, 15]}>
                      <Col span={24}>
                        <Title level={3} style={styles.title}>
                          Cambia Password
                        </Title>
                      </Col>
                    </Row>
                    <Row justify="space-around" gutter={[0, 10]}>
                      <Col md={24} sm={24}>
                        <Button
                          block
                          type="primary"
                          loading={this.state.loadingPassword}
                          disabled={this.state.loadingPassword}
                          onClick={async () => {
                            try {
                              this.setState({ loadingPassword: true });
                              await requestResetPassword(
                                currentUtente.id_utenza_firebase
                              );
                              notification.destroy();
                              notification.success({
                                message: "Mail di recupero password inviata",
                                description:
                                  "La mail di recupero password è stata inviata all'utente",
                              });
                            } catch (error) {
                              notification.destroy();
                              notification.error({
                                message:
                                  "Mail di recupero password non inviata",
                                description: "Contatta l'assistenza",
                              });
                            }
                            this.setState({
                              countdownPasswordVisible: true,
                              loadingPassword: false,
                            });
                          }}
                        >
                          {" "}
                          Invia il link
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
              ) : null}
            </Drawer>
            {/* DRAWER CHAT */}
            <Drawer
              visible={this.state.drawerChatVisible}
              title={"Dati utente"}
              onClose={() => {
                this.setState({
                  drawerChatVisible: false,
                  currentUtente: null,
                  currentUtenteFirebase: null,
                  userChats: [],
                  userMessages: [],
                });
              }}
              width={"100%"}
            >
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "400px",
                    height: "100%",
                    overflowY: "scroll",
                    top: 0,
                    left: 0,
                    padding: "15px",
                    paddingTop: "0px",
                    zIndex: 9999,
                    backgroundColor: Settings.colors.white,
                    borderRight: "1px solid",
                    borderColor: Settings.colors.lightGrey,
                  }}
                  className="chatPanel"
                >
                  <ChatList
                    chats={this.state.userChats}
                    callBackGetMessage={this._getMessages}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "400px",
                    top: 0,
                    width: "calc(100% - 400px)",
                    height: "100%",
                    zIndex: 9999,
                  }}
                  className="chatList"
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "70px",
                      top: "64px",
                      left: 0,
                      borderBottom: "1px solid",
                      zIndex: 9999,
                      backgroundColor: Settings.colors.white,
                      borderColor: Settings.colors.lightGrey,
                    }}
                  >
                    {this.state.loadingMessages ? null : (
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{
                          height: "100%",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        <Col>
                          <Row justify="start" align="middle" gutter={15}>
                            <Col>
                              <img
                                height="40px"
                                width="53px"
                                style={styles.chat_image}
                                src={placeholder}
                              />
                            </Col>
                            <Col>
                              <Row>
                                <Typography.Title
                                  level={3}
                                  style={styles.chat_title}
                                >
                                  {this.state.currentChatTitle}
                                </Typography.Title>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "calc(100% - 203px)",
                      padding: "15px",
                      top: "133px",
                      bottom: "70px",
                      left: 0,
                      borderBottom: "1px solid",
                      overflowY: "scroll",
                      borderColor: Settings.colors.lightGrey,
                    }}
                    id="chat"
                    className="chatList"
                  >
                    {this.state.loadingMessages ? (
                      <LoadingBlock loading={true} />
                    ) : (
                      <List
                        locale={{ emptyText: "Nessun Messaggio" }}
                        dataSource={this.state.userMessages}
                        renderItem={({
                          firebase_db_chat_id,
                          from,
                          type,
                          message,
                          timestamp,
                        }) => (
                          <List.Item
                            style={{
                              textAlign:
                                this.state.currentUtente.id_utenza_firebase ===
                                  from
                                  ? "right"
                                  : "left",
                              border: "none",
                              padding: "4px 0px",
                            }}
                          >
                            <List.Item.Meta
                              title={
                                <span
                                  style={{
                                    color:
                                      this.state.currentUtente
                                        .id_utenza_firebase === from
                                        ? Settings.colors.white
                                        : Settings.colors.darkGrey,
                                    padding: "5px 10px",
                                    background:
                                      this.state.currentUtente
                                        .id_utenza_firebase === from
                                        ? Settings.colors.cyan
                                        : Settings.colors.lightGrey,
                                    borderRadius: "4px",
                                    maxWidth: "80%",
                                    display: "inline-block",
                                    textAlign: "left",
                                  }}
                                >
                                  {message}
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Drawer>{" "}
          </>
        )}
      </Wrapper>
    );
  }
}
const styles = {
  wrapper: {
    padding: "25px 15px",
  },
  menu_item: {
    borderBottom: "1px solid " + Settings.colors.lightGrey,
    padding: "10px 20px",
    color: Settings.colors.darkGrey,
  },
  menu_item_title: {
    fontSize: "18px",
    display: "block",
  },
  menu_item_subtitle: {
    fontSize: "14px",
    color: Settings.colors.red,
    display: "block",
  },
  title: {
    color: Settings.colors.darkGrey,
  },
  label: {
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};
export default Account;
