import Settings from "./Settings";

export function _chipResoStatusColorGetter(status) {
  switch (status.toLowerCase()) {
    case "in sospeso":
      return Settings.colors.yellow;
    case "rifiutato":
      return Settings.colors.red;
    case "accettato":
      return Settings.colors.cyan;
    default:
      return Settings.colors.darkGrey;
  }
}
