import React from 'react';
import { Row, Col, Button, Select, Typography } from 'antd';
import Settings from '../Config/Settings';

const { Title } = Typography;
const { Option } = Select;

class Homepage extends React.Component {
  render() {
    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img src={require('../Assets/logo-white.png')} style={styles.logoStyle} alt="logo" />
          </Col>
        </Row>

        <Row justify="center" align='middle' gutter={[15, 15]}>
          <Col span={24}>
            <Title level={4} style={styles.title}>Accedi al pannello di controllo di Garzone</Title>
          </Col>
          <Col xs={24} sm={18} md={12}>
            <Button
              block
              size='large'
              ghost
              onClick={() => this.props.history.push('/accedi')}
            >
              Accedi al pannello
						</Button>
          </Col>

        </Row>
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require('../Assets/background.png')})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '15px'
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35
  },
  title: {
    color: Settings.colors.white,
    textAlign: 'center'
  }
}

export default Homepage;
