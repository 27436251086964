import React from 'react';

// import router
import {
  Route,
  Redirect
} from 'react-router-dom'

class PrivateRoute extends React.Component {

  is_mounted = false

  constructor(props) {
    super(props)
    this.state = {
      not_found: false,
      loading: true
    }
  }

  componentDidMount() {
    this.is_mounted = true
    this._checkUser()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.computedMatch.params) !== JSON.stringify(this.props.computedMatch.params)) {
      this._checkUser()
    }
  }

  componentWillUnmount() {
    this.is_mounted = false
  }

  _checkUser = () => {
    this.setState({ loading: false })
  }

  render() {
    console.log('authed', this.props.authed)

    return this.props.notVerified ? (
			<Redirect to={'/conferma-mail'} />
		) : this.props.authed == true ? (
			<Route {...this.props} />
		) : this.props.location.pathname != '/conferma-mail' ? (
			<Redirect to={{ pathname: '/accedi', state: { from: this.props.location } }} />
		) : (
			<Route {...this.props} />
		);
  }

}

export default PrivateRoute;