import { Functions } from "../Config/Fire";

export function getUtenti() {
  const utenti = Functions.httpsCallable("admin-getUtenti");
  return utenti();
}
export async function eliminaUtente({ uid }) {
  const utente = Functions.httpsCallable("admin-eliminaUtente");
  return await utente({ uid });
}
export async function postIndirizzi({ id, indirizzi }) {
  const postIndirizzi = Functions.httpsCallable("utente-postIndirizzi");
  return await postIndirizzi({ id, indirizzi });
}

export async function getUtenteFirebase(uid) {
  const getUtenteFirebase = Functions.httpsCallable("admin-getUtenteFirebase");
  return await getUtenteFirebase(uid);
}
export async function aggiornaUtente(data) {
  const aggiornaUtente = Functions.httpsCallable("utente-aggiornaUtente");
  return await aggiornaUtente(data);
}
export async function updateUserMailchimp(vecchia_mail, nuova_mail) {
  const updateUserMailchimp = Functions.httpsCallable(
    "utente-updateUserMailchimp"
  );
  return await updateUserMailchimp(vecchia_mail, nuova_mail);
}
export function updateUserEmail({ uid, nuova_mail }) {
  const updateUserEmail = Functions.httpsCallable("admin-updateUtenteMail");
  return updateUserEmail({ uid, nuova_mail });
}
// export function updateUserPassword(password) {
//   return Auth().currentUser.updatePassword(password);
// }
// export async function resetPassword(code, password) {
//   let result = null;
//   await Auth()
//     .confirmPasswordReset(code, password)
//     .then(() => {
//       result = "success";
//     })
//     .catch((error) => {
//       result = error;
//     });
//   return result;
// }
// export async function checkCode(code) {
//   let result = null;
//   await Auth()
//     .verifyPasswordResetCode(code)
//     .then((data) => {
//       result = data;
//     })
//     .catch((error) => {
//       result = error;
//     });
//   return result;
// }

export function resendEmailVerification(data) {
  const resendEmailVerification = Functions.httpsCallable(
    "admin-inviaConfermaMailUtente"
  );
  return resendEmailVerification(data);
}

export function requestResetPassword(data) {
  const requestResetPassword = Functions.httpsCallable(
    "admin-resetUtentePassword"
  );
  return requestResetPassword(data);
}
export async function getUserChats(data) {
  const getUserChats = Functions.httpsCallable("admin-getUserChats");
  const res = await getUserChats(data);
  console.log("RESULT", res);
  return res;
}
export async function getMessaggi(data) {
  const getMessaggi = Functions.httpsCallable("admin-getChatMessages");
  return getMessaggi(data);
}
// export async function applyActionCode(code) {
//   let result = null;
//   await Auth()
//     .applyActionCode(code)
//     .then(() => {
//       result = "success";
//     })
//     .catch((error) => {
//       result = error;
//     });
//   return result;
// }
