import React from "react";
import Cropper from "react-easy-crop";
import { Drawer, Button, Row, Col, Slider } from "antd";
import {
  CloudUploadOutlined,
  RedoOutlined,
  UndoOutlined,
} from "@ant-design/icons";

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
      // layout
      drawer_visible: false,
      // file
      src: null,
      croppedImageUrl: null,
      crop: {
        x: 0,
        y: 0,
      },
      zoom: 1,
    };
  }

  _onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.setState({ src: reader.result, rotation: 360 });
        console.log(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  _onCropComplete = (croppedArea, croppedAreaPixels) => {
    this._makeClientCrop(croppedAreaPixels);
  };

  _makeClientCrop = async (crop) => {
    const imageOriginal = document.querySelector(".reactEasyCrop_Image");
    if (crop.width && crop.height) {
      const croppedImageUrl = await this._getCroppedImg(
        imageOriginal,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  _getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    canvas.width = this.props.cropSize.width;
    canvas.height = this.props.cropSize.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      this.props.cropSize.width,
      this.props.cropSize.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        // this.fileUrl = window.URL.createObjectURL(blob);
        // resolve(this.fileUrl);
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          let base64data = reader.result;
          console.log("Base64:", base64data);
          resolve(base64data);
        };
      }, "image/png");
    });
  };

  _onOk = () => {
    this.props.onCropSuccess(this.state.croppedImageUrl);
    console.log("State:", this.state);
    this.setState({ drawer_visible: false });
  };

  render() {
    return (
      <>
        {this.state.croppedImageUrl ? (
          <div style={{ textAlign: "center" }}>
            <img
              alt="Crop"
              style={{
                width: "100%",
                display: "block",
                marginBottom: "10px",
                border: "5px solid #f2f3f4",
                borderRadius: "6px",
              }}
              src={this.state.croppedImageUrl}
            />
            <Button
              block
              onClick={() => this.setState({ drawer_visible: true })}
            >
              Cambia immagine
            </Button>
          </div>
        ) : this.props.image ? (
          <div style={{ textAlign: "center" }}>
            <img
              alt="Crop"
              style={{
                zIndex: 1,
                width: "100%",
                display: "block",
                marginBottom: "10px",
                border: "5px solid #f2f3f4",
                borderRadius: "6px",
              }}
              src={this.props.image}
            />
            <Button
              block
              size="large"
              onClick={() => this.setState({ drawer_visible: true })}
            >
              Cambia immagine
            </Button>
          </div>
        ) : (
              <div style={{ textAlign: "center" }}>
                <Button
                  block
                  onClick={() => this.setState({ drawer_visible: true })}
                >
                  Carica immagine
            </Button>
              </div>
            )}
        <Drawer
          visible={this.state.drawer_visible}
          title={"Carica immagine"}
          onClose={() =>
            this.setState({
              src: null,
              drawer_visible: false,
              croppedImageUrl: null,
            })
          }
          width={"100%"}
          footerStyle={{ zIndex: "1000", backgroundColor: "#fff" }}
          footer={
            <>
              {this.state.src && (
                <Row align="top" gutter={[20, 5]}>
                  <Col md={6} sm={24} flex="auto">
                    {this.state.src && (
                      <label style={styles.file_input_change}>
                        <input
                          style={styles.file_input}
                          type="file"
                          accept="image/*"
                          onChange={this._onSelectFile}
                        />
                        <CloudUploadOutlined /> Cambia immagine
                      </label>
                    )}
                  </Col>

                  <Col md={12} sm={24} flex="auto" gutter={[10, 0]}>
                    <Col md={24} sm={24}>
                      <Slider
                        style={{ width: "100%" }}
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(zoom) => {
                          this.setState({ zoom: zoom });
                        }}
                      />
                    </Col>

                    <Row justify="space-around" align="middle" gutter={[10, 0]}>
                      <Col>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => {
                            const rot = this.state.rotation;
                            this.setState({ rotation: rot - 90 });
                          }}
                          icon={<UndoOutlined />}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => {
                            const rot = this.state.rotation;
                            this.setState({ rotation: rot + 90 });
                          }}
                          icon={<RedoOutlined />}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={24} flex="auto">
                    <Row justify="end">
                      <Button
                        onClick={() =>
                          this.setState({
                            src: null,
                            croppedImageUrl: null,
                          })
                        }
                        style={{ marginRight: 2, width: "100%" }}
                      >
                        Cancella
                      </Button>
                      <Button
                        onClick={this._onOk}
                        type="primary"
                        style={{
                          marginRight: 2,
                          marginTop: 10,
                          width: "100%",
                        }}
                      >
                        Ok
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          }
        >
          <div
            style={{
              position: "absolute",
              top: "1px",
              left: 0,
              bottom: "1px",
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "60%",
                position: "relative",

                top: "-5%",
              }}
            >
              {this.state.src ? (
                <>
                  <Cropper
                    style={{
                      height: "100%",
                      width: "100%",
                      zIndex: 1,
                      position: "relative",
                    }}
                    image={this.state.src}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    rotation={this.state.rotation}
                    aspect={this.props.aspect}
                    onCropChange={(crop) => this.setState({ crop: crop })}
                    onCropComplete={this._onCropComplete}
                    onZoomChange={(zoom) => this.setState({ zoom: zoom })}
                    onRotationChange={(rotation) =>
                      this.setState({ rotation: rotation })
                    }
                  />
                </>
              ) : (
                  <div style={{ textAlign: "center" }}>
                    <b style={{ fontSize: "18px" }}>Carica un'immagine</b>
                    <p style={{ color: "#ccc" }}>
                      Formati accettati "jpeg" o "png"
                    <br />
                    Dimensioni consigliate (
                    {this.props.cropSize.width +
                        "x" +
                        this.props.cropSize.height}
                    )
                  </p>
                    <br />
                    <label style={styles.file_input_label}>
                      <input
                        style={styles.file_input}
                        type="file"
                        accept="image/*"
                        onChange={this._onSelectFile}
                      />
                      <CloudUploadOutlined /> Carica
                  </label>
                  </div>
                )}
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

export default ImageCropper;

const styles = {
  file_input: {
    display: "none",
  },
  file_input_label: {
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    background: "#00bece",
    borderRadius: "6px",
    color: "white",
    textAlign: "center",
  },
  file_input_change: {
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    background: "#00bece",
    borderRadius: "6px",
    color: "white",
    width: "100%",
    textAlign: "center",
  },
};
