import { Functions } from '../Config/Fire';

export async function getPromozione(id) {
	const promozione = Functions.httpsCallable('admin-getPromozione');
	return promozione(id);
}

export async function getPromozioniWithNegozio() {
	const promozioni = Functions.httpsCallable('admin-getPromozioniWithNegozio');
	return promozioni();
}

export async function eliminaPromozione(promozione) {
	const promozione_eliminato = Functions.httpsCallable('admin-eliminaPromozione');
	return promozione_eliminato(promozione);
}

export async function creaPromozione(values) {
	const promozione = Functions.httpsCallable('admin-creaPromozione');
	return promozione(values);
}

export async function aggiornaPromozione(id_promozione, values) {
	const promozione = Functions.httpsCallable('admin-aggiornaPromozione');
	return promozione({ id: id_promozione, ...values });
}