
export default {
	colors: {
		cyan: "#00BECE",
		lightCyan: "rgba(0, 190, 206, 0.1)",
		darkGrey: "#516375",
		grey: "#C0C1C2",
		lightGrey: "#F2F3F4",
		red: "#CF230A",
		white: "#FFFFFF",
		black: "#000000",
		yellow: "#E6B82C",
		lightBlue: "#18E6BB",
		blue: "#0D9AE6",
		statusRed: "#CF2313",
	},
	get_google_api_key: () => {
		if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
			if (process.env.REACT_APP_STAGE === 'development') {
				// sono in dev nel localhost
				return process.env.REACT_APP_GOOGLEAPIKEY
			} else {
				// sono in prod nel localhost
				return process.env.REACT_APP_GOOGLEAPIKEY_CLOUD
			}
		} else {
			if (process.env.REACT_APP_STAGE === 'development') {
				// sono in dev in cloud
				return process.env.REACT_APP_GOOGLEAPIKEY
			} else {
				// sono in prod in cloud
				return process.env.REACT_APP_GOOGLEAPIKEY_CLOUD
			}
		}
	}
};


